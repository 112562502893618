
import { useState } from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from 'react-i18next';
import UserResetPassForm from "./UserResetPassForm";


const UserResetPassWidget = (props) => {
    const { t } = useTranslation();

    return(
        <Box>
            <UserResetPassForm />
        </Box>
    );
}

export default UserResetPassWidget;