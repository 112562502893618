import classes from "../../dashboard/Dashboard.module.css";
import { useTranslation } from "react-i18next";
import { Divider, FormControl, FormHelperText, IconButton, Input, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState, useEffect } from "react";
import MafiTableTickets from "../../../@mafi/MafiTableTickets";
import axios from "axios";
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { useNavigate } from "react-router-dom/dist";

const API_URL = process.env.REACT_APP_API_URL
// let dataTickets = [];
// let ticketsRows = [];
// let ticketsCountG = 0;
// let openTickets = [];
// let closedTickets = [];
// let myTickets = [];
// let dataUsers = [];
// let usersRows = [];
// let customerUsers = [];
// let staffUsers = [];
// let uniqueStaffUsers = [];

let dataOrg = [];
let orgRows = [];
let orgData = [];
let orgCount = 0;


  function createData(name, status, address, phone, orgParent) {
    return { name, status, address, phone, orgParent };
  }

  const readOrgData = async () => {
    return new Promise(function (resolve, reject) {
      fetch(API_URL + "organizations")
        .then((response) => response.json())
        .then((jsonData) => {
          dataOrg = jsonData;
          resolve();
        });
    });
  };
  
  readOrgData().then(function () {
    dataOrg.organizations.forEach((org) => {
      orgRows.push(
        createData(org.name, org.status, org.address, org.phone, org.orgParent)
      );
    });
    localStorage.setItem("organizations", JSON.stringify(dataOrg.organizations));
  });



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const stylesIco = {
  largeIcon: {
    width: 100,
    height: 100,
  },

};


const TicketMain = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [ticketsList, setTicketsList] = useState([]);
  const [OpenTickets, setOpenTickets] = useState([]);
  const [closedTickets, setClosedTickets] = useState([]);
  const [searchCustomerVal, setSearchCustomerVal] =  useState('');
  const [orgParent, setOrgParent] = useState('All');
  const [userOrg, setUserOrg] = useState({});
  const [totalOpen, setTotalOpen] = useState('');
  const [totalClose, setTotalClose] = useState('');
  const [totalMy, setTotalMy] = useState('');
  const [unFilterTicketResponse, setunFilterTicketResponse] = useState([]);
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("user"));

  if(userData.role == 'Customer'){
    orgData = orgRows.filter(function(item){
      return item.orgParent == userData.organization 
    });
  } else {
    orgData = orgRows;
  }
  orgCount = orgData.length;

  let usersOrg = {};
  useEffect(() => {
    let ticketsSave = [];
    const getTickets = async () => {

      const allUsers = await axios.get(`${API_URL}users`);

      for(let i = 0; i<allUsers.data.users.length; i++){
        let tempUserId = allUsers.data.users[i]._id;
        usersOrg[tempUserId] = allUsers.data.users[i].organization;
      }

      const response = await axios.get(
        `${API_URL}tickets/user/${userData._id}`,
        {
          params: {
            role: userData.role,
          },
        }
      );

      if (response.data.tickets) {

        response.data.tickets.forEach((ticket) => {
          const {
            _id: id,
            number,
            status,
            department,
            creator,
            // customerId: { _id: customerId } = {customerId: "" },
            // customerId: { name: customerName } = { customerName: "" },
            dueDate,
            closed,
            updated,
            created,
            pics,
            issue,
            title,
            description,
            staffId: { _id: staffId },
            staffId: { name: staffName },
            source,
            priority,
          } = ticket;

          const customerId = ticket.customerId ? ticket.customerId._id : "";
          const customerName = ticket.customerId
            ? ticket.customerId.name
            : "";

          const customerOrg = usersOrg[customerId];

          ticketsSave.push({
            id,
            number,
            status,
            department,
            creator,
            customerId,
            customerName,
            dueDate,
            closed,
            updated,
            created,
            pics,
            issue,
            title,
            description,
            staffId,
            staffName,
            source,
            priority,
            customerOrg,
          });
        });
      }

      setunFilterTicketResponse(ticketsSave);

        // if there are a nav history
        let navHistTicket = localStorage.getItem('navHistoryTicket');
        if(navHistTicket){
          navHistTicket = JSON.parse(navHistTicket);

          // filter by customer
          ticketsSave = ticketsSave.filter((ticket) => {
            return ticket.customerName.toLowerCase().includes(navHistTicket[0].customerSearch);
          });
          setSearchCustomerVal(navHistTicket[0].customerSearch);

          if(navHistTicket[0].orgSearch !== "All"){
            ticketsSave = ticketsSave.filter((ticket)=>{
              return ticket.customerOrg === navHistTicket[0].orgSearch;
            });
          }
          setOrgParent(navHistTicket[0].orgSearch);

        }

      const tickesOpen = ticketsSave.filter((ticket) => {
        return ticket.status === "Open";
      });

      const tickesClosed = ticketsSave.filter((ticket) => {
        return ticket.status === "Closed";
      });

      setOpenTickets(tickesOpen);
      setTotalOpen(t("Open") + " " + tickesOpen.length)

      setClosedTickets(tickesClosed);
      setTotalClose(t("Closed") + " " + tickesClosed.length)

      setTicketsList(ticketsSave);
      setTotalMy(t("my-tickets") + " " + ticketsSave.length)
    };

    getTickets();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCustomerSearch = (event) => {
    setSearchCustomerVal(event.target.value);
    let tickesOpen;
    let tickesClosed;

    const navHistory = [{
      currentPage: 'Ticket',
      orgSearch: orgParent,
      customerSearch: event.target.value
    }];

    localStorage.setItem('navHistoryTicket', JSON.stringify(navHistory));

    const tickesOpenIni = unFilterTicketResponse.filter((ticket) => {
      return ticket.status === "Open";
    });

    const tickesClosedIni = unFilterTicketResponse.filter((ticket) => {
      return ticket.status === "Closed";
    });

    tickesOpen = tickesOpenIni.filter((ticket) => {
      return ticket.customerName.toLowerCase().includes(event.target.value);
    });

    tickesClosed = tickesClosedIni.filter((ticket) => {
      return ticket.customerName.toLowerCase().includes(event.target.value);
    });

    if(orgParent !== 'All'){
      tickesOpen = tickesOpen.filter((ticket) => {
        return ticket.customerOrg === orgParent;
      });
  
      tickesClosed = tickesClosed.filter((ticket) => {
        return ticket.customerOrg === orgParent;
      });
    }

    setOpenTickets(tickesOpen);
    setTotalOpen(t("Open") + " " + tickesOpen.length)
    setClosedTickets(tickesClosed);
    setTotalClose(t("Closed") + " " + tickesClosed.length)

  };

  const handleOrgSearch = (event) => {
    setOrgParent(event.target.value);
    let tickesOpen;
    let tickesClosed;

    const navHistory = [{
      currentPage: 'Ticket',
      orgSearch: event.target.value,
      customerSearch: searchCustomerVal
    }];

    localStorage.setItem('navHistoryTicket', JSON.stringify(navHistory));

    const tickesOpenIni = unFilterTicketResponse.filter((ticket) => {
      return ticket.status === "Open";
    });

    const tickesClosedIni = unFilterTicketResponse.filter((ticket) => {
      return ticket.status === "Closed";
    });

    if(event.target.value != 'All'){
      tickesOpen = tickesOpenIni.filter((ticket) => {
        return ticket.customerOrg == event.target.value;
      });
  
      tickesClosed = tickesClosedIni.filter((ticket) => {
        return ticket.customerOrg == event.target.value;
      });

      if(searchCustomerVal != ''){
        tickesOpen = tickesOpen.filter((ticket) => {
          return ticket.customerName.toLowerCase().includes(searchCustomerVal);
        });
    
        tickesClosed = tickesClosed.filter((ticket) => {
          return ticket.customerName.toLowerCase().includes(searchCustomerVal);
        });
      }

      setClosedTickets(tickesClosed);
      setTotalClose(t("Closed") + " " + tickesClosed.length)
      setOpenTickets(tickesOpen);
      setTotalOpen(t("Open") + " " + tickesOpen.length)
    } else {
      setClosedTickets(tickesClosedIni);
      setTotalClose(t("Closed") + " " + tickesClosedIni.length)
      setOpenTickets(tickesOpenIni);
      setTotalOpen(t("Open") + " " + tickesOpenIni.length)
      
    }

};

const handleClearSearch = () =>{
  localStorage.removeItem('navHistoryTicket');
  window.location = "/Tickets";
};

  return (
    <div className={classes.statisticsBox}>
      <Box sx={{ width: "100%" }} className="mt20">
          <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" divider={<Divider orientation="vertical" flexItem />} >
              <div className="flex sm:space-x-24 my-6" >
              <TextField
                      id="name"
                      label={t('From')}
                      className="flex-auto"
                      onChange={(e) =>handleCustomerSearch(e)}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      variant="outlined"
                      required
                      value={searchCustomerVal}
                  />
              </div>
              <div className="flex sm:space-x-24 mb-6">
                <FormControl>
                    <FormHelperText>{t("org-parent")}:</FormHelperText>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={orgParent}
                        label="Ticket Source"
                        onChange={handleOrgSearch}
                    >
                      <MenuItem value='All'>All</MenuItem>
                        {orgData.map((row) => (
                            <MenuItem value={row.name}>{row.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className="flex sm:space-x-24 mb-6">
              <IconButton 
                iconStyle={stylesIco.largeIcon}
                onClick={handleClearSearch}
              >
                  <SearchOffIcon label="Clear Search" />
                  <Typography sx={{marginLeft: '20px'}}>{t("Clear Search")}</Typography>
              </IconButton>
            </div>
          </Stack>    

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={totalOpen} className="saltexColor" {...a11yProps(0)} />
            <Tab
              label={totalMy}
              className="saltexColor"
              {...a11yProps(1)}
            />
            <Tab
              label={totalClose}
              className="saltexColor"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <MafiTableTickets ticketData={OpenTickets} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MafiTableTickets ticketData={ticketsList} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MafiTableTickets ticketData={closedTickets} />
        </TabPanel>
      </Box>
    </div>
  );
};

export default TicketMain;
