import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

let userIdAct = window.location.search.replace("?", "");
const API_URL = process.env.REACT_APP_API_URL;

// console.log("User Id", userIdAct);

let userObj = {
  status: "Active",
};

if (userIdAct) {
  try {
    const response = fetch(API_URL + "users/activate/" + userIdAct, {
      method: "PATCH",
      headers: {
        "Content-Type": "Application/json",
      },
      body: JSON.stringify(userObj),
    });
  } catch (err) {
    console.log(err);
  }
}

const UsersHeaderNewActivation = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const routeChange = () => {
    let path = `/`;
    navigate(path);
  };

  return (
    <Box
      sx={{
        backgroundImage: "url('/assets/bg_newUser.png')",
        p: 7,
        borderRadius: "10px",
      }}
    >
      <Stack
        sx={{ width: "99%" }}
        direction="row"
        spacing={2}
        justifyContent="space-between"
      >
        <Box>
          <Typography class="text-slate-700 text-xl">
            {t("WelcomeMsg")}
          </Typography>
          <Typography class="text-slate-700 text-xl mt-2">
            {t("user-activation")}
          </Typography>
          <Typography
            class="text-slate-700 text-md mt-2"
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "block" },
            }}
          >
            {t("subtitle-user-activation")}
          </Typography>
          <Typography
            class="text-slate-700 text-md mt-2"
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "block" },
            }}
          >
            {t("user-help")}{" "}
            <a href="http://www.saltexgroup.com">Saltex Group</a>
          </Typography>
        </Box>

        <Box>
          <img
            width="200px"
            src="/assets/logos/logo_company_dark.png"
            alt="logo"
          />
        </Box>
      </Stack>
      <div className="flex sm:space-x-24 mt-8 mb-8">
        <Button
          variant="outlined"
          color="success"
          className=" w-1/2 mt-16"
          aria-label="Add"
          sx={{ width: "100%" }}
          onClick={routeChange}
        >
          {t("go-dashboard")}
        </Button>
      </div>
    </Box>
  );
};

export default UsersHeaderNewActivation;
