import { Box, Fab, Modal, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Add as AddIcon } from "@mui/icons-material";
import styled from "@emotion/styled";
import BugReportRoundedIcon from "@mui/icons-material/BugReportRounded";
import TicketsAddForm from "../tickets/widgets/TicketsAddForm";

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const AddTooltip = () => {
  const [open, setOpen] = useState(false);
  const [openTask, setOpenTask] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const userRole = user.role;
  return (
    <>
      {userRole !== "Technician" && (
        <Tooltip
          onClick={(e) => setOpen(true)}
          title="Add Ticket"
          sx={{
            position: "fixed",
            bottom: 20,
            left: { xs: "calc(55%)", md: 30 },
          }}
        >
          <Fab color="warning" aria-label="add">
            <BugReportRoundedIcon />
          </Fab>
        </Tooltip>
      )}
      <StyledModal
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box width={600} height={750} class="bg-slate-50/75 p-2 rounded-lg">
          <div class="-ml-8">
            <TicketsAddForm displayCancel={"none"} />
          </div>
        </Box>
      </StyledModal>
    </>
  );
};

export default AddTooltip;
