import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography, TablePagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: grey[600],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const UserList = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [orgBg, setOrgBg] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleEdit = (data) => {
    const user = JSON.parse(data);
    navigate(`/user/edit`, {
      state: {
        id: user.id,
        name: user.name,
        department: user.department,
        organization: user.organization,
        address: user.address,
        city: user.city,
        country: user.country,
        role: user.role,
        email: user.email,
        status: user.status,
        phone: user.phone,
        mobile: user.mobile,
      },
    });
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - props.filteredUsers.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div class="w-full px-16">
      <Typography class="text-xs font-bold mt-4 text-left">
        {t("Users-list")} - Total: {props.usersCount}
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ borderRadius: "10px", marginTop: "20px" }}
      >
        <Table aria-label="customized table" class="w-full">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("User")}</StyledTableCell>
              <StyledTableCell align="right">
                {t("Organization")}
              </StyledTableCell>
              <StyledTableCell align="right">{t("Role")}</StyledTableCell>
              <StyledTableCell align="right">{t("Email")}</StyledTableCell>
              <StyledTableCell align="right">{t("City")}</StyledTableCell>
              <StyledTableCell align="right">{t("Country")}</StyledTableCell>
              <StyledTableCell align="right">{t("Status")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? props.filteredUsers.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
              : props.filteredUsers
            ).map((row) => {
              const {
                id,
                name,
                department,
                organization,
                role,
                email,
                status,
                phone,
                mobile,
                address,
                city,
                country
              } = row;
              return (
                <StyledTableRow key={id} >
                  <StyledTableCell component="th" scope="row">
                    <Button
                      onClick={() => {
                        handleEdit(
                          JSON.stringify({
                            id,
                            name,
                            department,
                            organization,
                            role,
                            email,
                            status,
                            phone,
                            mobile,
                            address,
                            city,
                            country
                          })
                        );
                      }}
                      className="saltexColor"
                    >
                      {name}
                    </Button>
                  </StyledTableCell>

                  {
                    organization != ''
                      ? <StyledTableCell align="right" >
                        {organization}
                      </StyledTableCell>
                      : <StyledTableCell align="right" sx={{ backgroundColor: 'pink' }} >
                        {organization}
                      </StyledTableCell>
                  }

                  <StyledTableCell align="right">{role}</StyledTableCell>
                  <StyledTableCell align="right">{email}</StyledTableCell>
                  <StyledTableCell align="right">{city}</StyledTableCell>
                  <StyledTableCell align="right">{country}</StyledTableCell>
                  {
                    status != 'Active'
                      ? <StyledTableCell align="right" sx={{ backgroundColor: 'pink' }}>{status}</StyledTableCell>
                      : <StyledTableCell align="right">{status}</StyledTableCell>
                  }
                </StyledTableRow>
              );
            })}
            {emptyRows > 0 && (
              <tr style={{ height: 34 * emptyRows }}>
                <td colSpan={3} />
              </tr>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={props.filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          slotProps={{
            select: {
              "aria-label": t("Rows-per-page"),
            },
            actions: {
              showFirstButton: true,
              showLastButton: true,
            },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("Rows-per-page")}
        />
      </TableContainer>
    </div>
  );
};

export default UserList;
