import { Box } from "@mui/material";
import React from "react";
import TicketsHeader from "./TicketsHeader";
import TicketsMain from "./widgets/TicketsMain";
import TicketsNew from "./widgets/TicketsNew";

const Tickets = () => {
  const userData = JSON.parse(localStorage.getItem("user"));
  return (
    <Box
      paddingTop={2}
      sx={{
        flex: { sx: 4, sm: 4, md: 4, lg: 5 },
        backgroundColor: "white",
        p: { sx: 1, sm: 1, md: 1, lg: 2 },
      }}
    >
      <TicketsHeader />
      {userData.role !== "Technician" && <TicketsNew />}
      <TicketsMain />
    </Box>
  );
};

export default Tickets;
