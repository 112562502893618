import { useState } from "react";
import { Typography, Button, TablePagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: grey[600],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const OrganizationList = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleEdit = (data) => {
    const organization = JSON.parse(data)
    navigate(`/Organization/edit`, {
      state: {
        id: organization.id,
        name: organization.name,
        orgParent: organization.orgParent,
        phone: organization.phone,
        address: organization.address,
        status: organization.status,
      },
    });
  };

  // pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div class="w-full px-16">
      <Typography class="text-xs font-bold mt-4 text-left">
        {t("organizations-list")} - Total: {props.organizationsCount}
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ borderRadius: "10px", marginTop: "20px" }}
      >
        <Table aria-label="customized table" class="w-full">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("Organization")}</StyledTableCell>
              <StyledTableCell align="right">{t("org-parent")}</StyledTableCell>
              <StyledTableCell align="right">{t("Phone")}</StyledTableCell>
              <StyledTableCell align="right">{t("Address")}</StyledTableCell>
              <StyledTableCell align="right">{t("Status")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? props.filteredOrganizations.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
              : props.filteredOrganizations
            ).map((row, index) => {
              const { id, name, orgParent, phone, address, status } = row;
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    <Button
                      onClick={() => {
                        handleEdit(
                          JSON.stringify({
                            id,
                            name,
                            orgParent,
                            phone,
                            address,
                            status,
                          })
                        );
                      }}
                      className="saltexColor"
                    >
                      {row.name}
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell align="right">{orgParent === '0' ? 'Principal' : orgParent}</StyledTableCell>
                  <StyledTableCell align="right">{phone}</StyledTableCell>
                  <StyledTableCell align="right">{address}</StyledTableCell>
                  <StyledTableCell align="right">{status}</StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={props.filteredOrganizations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          slotProps={{
            select: {
              "aria-label": t("Rows-per-page"),
            },
            actions: {
              showFirstButton: true,
              showLastButton: true,
            },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("Rows-per-page")}
        />
      </TableContainer>
      <Button
        variant="outlined"
        color="error"
        className="w-full"
        aria-label="Add"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          navigate('/Dashboard',);
        }}
        size="large"
        sx={{
          marginTop: 2
        }}
      >
        {t("go-dashboard")}
      </Button>
    </div>
  );
};

export default OrganizationList;
