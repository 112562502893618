import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import OrganizationHeader from "./OrganizationHeader";
import OrganizationNew from "./widgets/OrganizationNew";
import OrganizationList from "./widgets/OrganizationList";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL
let dataOrg = [];
let orgRows = [];
let orgCountG = 0;

// const user = JSON.parse(localStorage.getItem("user"));

// console.log(user.organization);

function createData(name, status, address, phone, orgParent) {
  return { name, status, address, phone, orgParent };
}

const readOrgData = async () => {
  return new Promise(function (resolve, reject) {
    fetch(API_URL + "organizations")
      .then((response) => response.json())
      .then((jsonData) => {
        dataOrg = jsonData;
        resolve();
      });
  });
};

readOrgData().then(function () {
  dataOrg.organizations.forEach((org) => {
    orgRows.push(
      createData(org.name, org.status, org.address, org.phone, org.orgParent)
    );
    orgCountG++;
  });
  localStorage.setItem("organizations", JSON.stringify(dataOrg.organizations));
});

const Organizations = () => {
  const [orgData, setOrgData] = useState(orgRows);
  const [orgCount, setOrgCount] = useState(orgCountG);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const getOrganizations = async () => {
      const response = await axios.get(`${API_URL}organizations`, {
        params: {
          userOrganization: user.organization,
        },
      });

      setOrgData(response.data.organizations)
      setOrgCount(response.data.organizations.length)
    };

    getOrganizations();
  }, []);

  const addOganizationListHandler = (organization) => {
    setOrgData((prevOrganization) => {
      let contTemp = prevOrganization.length + 1;
      setOrgCount(contTemp);
      return [organization, ...prevOrganization];
    });
    localStorage.setItem("organizations", JSON.stringify(orgData));
  };

  return (
    <Box
      paddingTop={2}
      sx={{
        flex: { sx: 4, sm: 4, md: 4, lg: 5 },
        backgroundColor: "white",
        p: { sx: 1, sm: 1, md: 1, lg: 2 },
      }}
    >
      <OrganizationHeader />
      <OrganizationNew
        addOganizationListHandler={addOganizationListHandler}
        orgData={orgData}
      />
      <OrganizationList
        filteredOrganizations={orgData}
        organizationsCount={orgCount}
      />
    </Box>
  );
};

export default Organizations;
