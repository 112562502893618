import { Box, Paper, Stack, ThemeProvider, createTheme } from "@mui/material";
import React, { useState } from "react";
import UsersNew from "../main/users/UsersNew";
import styled from "@emotion/styled";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#E2E8F0',
    // ...theme.typography.body2,
    // padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
  }));

const SaltexNewUsers = () => {
    const [mode, setMode] = useState("light");
    const darkTheme = createTheme({
      palette:{
        mode: mode
      }
    });
    return (
      <ThemeProvider theme={darkTheme}>
      <Box sx={{mt: 10, width: '100%', borderRadius: '15px'}}>
        {/* <NavBar /> */}
        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" >
          {/* <PPNavigation /> */}
          <Item></Item>
          <UsersNew />
          <Item></Item>
          {/* <RightBar /> */}
        </Stack>
        {/* <AddTooltip /> */}
      </Box>
      </ThemeProvider>
    );
}

export default SaltexNewUsers;