import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { useTranslation } from 'react-i18next';


const UsersHeader = () => {
    const { t } = useTranslation();
    return (
        <Stack sx={{ width: '99%' }} direction="row" spacing={2} justifyContent="space-between">
            <Box>
                <Typography class="text-slate-500 text-xl">{t('User')}s</Typography>
                <Typography class="text-slate-500 text-sm" sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>{t('subtitle-user')}</Typography>
            </Box>

            <Box>
                <img width='200px' src="/assets/logos/logo_company_dark.png" alt="logo" />
            </Box>

        </Stack>
    );
}

export default UsersHeader;