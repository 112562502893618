import { useEffect, useState } from "react";
import { Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import axios from "axios";

import classes from "../Dashboard.module.css";

const DashTicketsByDay = ({ticketsByDay}) => {
  // const [ticketsByDay, setTicketsByDay] = useState([]);
  const { t } = useTranslation();
  const theme = useTheme();
  const user = JSON.parse(localStorage.getItem("user"));

  let daysNumber = 30;
  let today = new Date();
  let last30Days = new Date(new Date().setDate(today.getDate() - daysNumber));
  let listDays = [];
  let listDaysFormat = [];
  let listValues = [];
  const formatMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  

  for (let index = 0; index <= daysNumber; index++) {
    let day = new Date(
      new Date().setDate(today.getDate() - (daysNumber - index))
    );
    // listDays.push(day.toISOString().slice(0, 10));

    listDays.push(
      `${day.getFullYear()}-${
        day.getMonth() < 10 ? "0" + (day.getMonth() + 1) : day.getMonth() + 1
      }-${day.getDate() < 10 ? "0" + day.getDate() : day.getDate()}`
    );
   
    listDaysFormat.push(
      `${formatMonths[day.getMonth()]} ${
        day.getDate() < 10 ? "0" + day.getDate() : day.getDate()
      }`
    );
  }


  listDays.forEach((day) => {
    let data = ticketsByDay.filter((ticket) => {
      return ticket._id === day;
    });

    if (data.length > 0) {
      // console.log(data[0].count)
      listValues.push(data[0].count);
    } else {
      listValues.push(0);
    }
  });

  const chartOptions = {
    options: {
      chart: {
        animations: {
          enabled: true,
        },
        fontFamily: "inherit",
        foreColor: "inherit",
        height: "100%",
        type: "area",
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      colors: [theme.palette.primary.light, theme.palette.primary.light],
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: [theme.palette.primary.dark, theme.palette.primary.light],
        opacity: 0.5,
      },
      grid: {
        show: false,
        padding: {
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      legend: {
        show: true,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      tooltip: {
        followCursor: true,
        theme: "dark",
        x: {
          format: "MMM dd, yyyy",
        },
      },
      xaxis: {
        // categories: [
        //   "Jul 20",
        //   "Jul 21",
        //   "Jul 21",
        //   "Jul 22",
        //   "Jul 23",
        //   "Jul 24",
        //   "Jul 25",
        //   "Jul 26",
        //   "Jul 27",
        // ],
        categories: listDaysFormat,
        labels: {
          offsetY: 0,
          rotate: 0,
          style: {
            colors: "#FFF",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#FFF",
          },
        },
      },
    },
    series: [
      {
        name: "# Tickets",
        // data: [0, 0, 0, 0, 0, 0, 0, 0],
        data: listValues,
      },
    ],
  };

  return (
    <Paper className={classes.ticketsByDayChart}>
      <Typography className={classes.ticketsByDayChartTitle}>
        {t("tickets-by-month")}
      </Typography>
      <Typography className={classes.ticketsByDayChartChip}>
        {t("last-30-days")}
      </Typography>

      <div class="h-96">
        <ReactApexChart
          className="w-full h-full"
          options={chartOptions.options}
          series={chartOptions.series}
          type={chartOptions.options.chart.type}
          height={chartOptions.options.chart.height}
        />
      </div>
    </Paper>
  );
};

export default DashTicketsByDay;
