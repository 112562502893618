import * as React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { Button, Divider, Fab, FormControl, FormHelperText, MenuItem, Modal, Select, Stack, TextField } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom/dist";
import { useTranslation } from "react-i18next";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import styled from "@emotion/styled";
import TasksAddForm from "../main/tasks/widgets/TasksAddForm";
import axios from "axios";
import SearchOffIcon from '@mui/icons-material/SearchOff';

let ticketId = window.location.search.replace("?", "");

const rows = [];

const API_URL = process.env.REACT_APP_API_URL;
const APP_URL = process.env.REACT_APP_FRONTEND_HOST;
let ticketTasks = [];
let allTask = [];

const emptyRow = {
  Tasks: {
    closed: "",
    created: "",
    department: "",
    objetives: "",
    staffName: "",
    ticketNum: "",
    title: "",
    updated: "",
    _id: "",
  },
};

const getTasksbyTicketId = async (ticketId) => {
  return new Promise(function (resolve, reject) {
    fetch(API_URL + "tasks/" + ticketId)
      .then((response) => response.json())
      .then((jsonData) => {
        ticketTasks = jsonData;
        if (ticketTasks.Tasks.length == 0) ticketTasks = emptyRow;
        localStorage.setItem("totalTask", ticketTasks.length);
        // rows = ticketTasks;
        resolve();
      });
  });
};

// const getTasksByStaffId = async () => {
//   return new Promise(function (resolve, reject) {
//     fetch(API_URL + "tasks/staff/" + user._id)
//       .then((response) => response.json())
//       .then((jsonData) => {
//         allTask = jsonData;
//         if (allTask.Tasks.length == 0) allTask = emptyRow;
//         // rows = allTask;
//         resolve();
//       });
//   });
// };

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { t } = useTranslation();
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "task",
      numeric: false,
      disablePadding: true,
      label: t("Task"),
    },
    {
      id: "ticket",
      numeric: false,
      disablePadding: true,
      label: "Ticket",
    },
    {
      id: "lastUpdate",
      numeric: true,
      disablePadding: false,
      label: t("last-updated"),
    },
    {
      id: "title",
      numeric: true,
      disablePadding: false,
      label: t("Title"),
    },
    {
      id: "department",
      numeric: true,
      disablePadding: false,
      label: t("Department"),
    },
    {
      id: "assignedTo",
      numeric: true,
      disablePadding: false,
      label: t("Assigned-to"),
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { t } = useTranslation();
  const { numSelected } = props.numSelected;
  const [openTask, setOpenTask] = React.useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const StyledModal = styled(Modal)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  return (
    <Toolbar
      sx={{
        pl: { sm: 0 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t("Tasks")}
          </Typography>
          {props.ticketId && (
            <>
              {/* add task button */}
              {user.role != "Customer" && (
                <Fab
                  color="primary"
                  variant="extended"
                  sx={{ mr: 2 }}
                  onClick={(e) => setOpenTask(true)}
                  disabled={props.isClosed}
                >
                  <AddCircleOutlineRoundedIcon sx={{ mr: 1 }} />
                  {t("Task")}
                </Fab>
              )}
              <StyledModal
                open={openTask}
                onClose={(e) => setOpenTask(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  width={600}
                  height={750}
                  class="bg-slate-50/75 p-2 rounded-lg"
                >
                  <div class="-ml-8">
                    <>
                      <TasksAddForm
                        ticketNum={props.ticketNum}
                        ticketId={props.ticketId}
                      />
                    </>
                  </div>
                </Box>
              </StyledModal>
            </>
          )}
        </>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

let countIni = 0;
let dataOrg = [];
let orgRows = [];
let orgData = [];

function createData(name, status, address, phone, orgParent) {
  return { name, status, address, phone, orgParent };
}

const readOrgData = async () => {
  return new Promise(function(resolve, reject){
    fetch(API_URL + "organizations")
      .then((response)=> response.json())
      .then((jsonData) =>{
        dataOrg = jsonData;
        resolve();
      });
  });
};

readOrgData().then(function(){
  dataOrg.organizations.forEach(org => {
    orgRows.push(
      createData(org.name, org.status, org.address, org.phone, org.orgParent)
    )
  });
})

const handleClearSearch = () =>{
  localStorage.removeItem('navHistoryTask');
  window.location = "/Tasks";
};

const stylesIco = {
  largeIcon: {
    width: 100,
    height: 100,
  },

};

const MafiTableTasks = (props) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("lastUpdate");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [taskRows, setTaskRows] = React.useState(rows);
  const [tasksRows, setTasksRows] = React.useState([]);
  const [tasksRowsBk, setTasksRowsBk] = React.useState([]);
  const [orgParent, setOrgParent] = React.useState("All");
  const [searchTitleVal, setSearchTitleVal] = React.useState('')
  const [searchTechVal, setSearchTechVal] = React.useState("All")
  const [searchTechLabelVal, setSearchTechLabelVal] = React.useState('')
  const [unfilterTaskResponse, setunFilterTaskResponse] = React.useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  let location = useLocation();
  let ticketId = location.search.replace("?", "");
  const user = JSON.parse(localStorage.getItem("user"));
  const [staffUsers, setStaffUsers] = React.useState([]);

  useEffect(() => {
    const getTasks = async () => {
      let response;
      let uniqueStaffUsers = [];

      if (props.section === "main") {
        if (user.role === "Admin") {
          response = await axios.get(`${API_URL}tasks`);
        } else {
          response = await axios.get(`${API_URL}tasks/staff/${user._id}`);
        }
      } else {
        if (user.role === "Admin" || user.role === "Customer") {
          response = await axios.get(`${API_URL}tasks/${ticketId}`);
        } else {
          response = await axios.get(`${API_URL}tasks/staff/${user._id}`);
        }
      }

      const responseUsers = await axios.get(`${API_URL}users`);
      let users = responseUsers.data.users.filter((user) => {
        return (
          user.role === "Admin" || user.role === "Technician"
        );
      });

      let staffSupport = users.filter((staff) => {
        return !(
          staff.name === "Support" && staff.organization === "Saltex Group"
        );
      });

      staffSupport.forEach(staff => {
        uniqueStaffUsers.push({ id: staff.id, label: staff.name })
      })

      setStaffUsers(uniqueStaffUsers)

      if (response.data.Tasks.length > 0) {
        setunFilterTaskResponse(response.data.Tasks);

        // if there are a nav history
        let navHistTask = localStorage.getItem('navHistoryTask');
        if(navHistTask){
          navHistTask = JSON.parse(navHistTask);

          // filter by title
          response.data.Tasks = response.data.Tasks.filter((task) => {
            return task.title.toLowerCase().includes(navHistTask[0].titleSearch)
          });
          setSearchTitleVal(navHistTask[0].titleSearch)

          // filter by tech ID
          if(navHistTask[0].techSearch !== "All"){
            response.data.Tasks = response.data.Tasks.filter((task)=>{
              return task.staffId.toLowerCase() === navHistTask[0].techSearch;
            });
          }
          setSearchTechVal(navHistTask[0].techSearch);
        }

        if (props.deptId === 1) {
          const openTasks = response.data.Tasks.filter((task) => {
            return task.status === "Open";
          });
          setTasksRows(openTasks);
          setTasksRowsBk(openTasks)
        }

        // closed tasks
        if (props.deptId === 3) {
          const closedTasks = response.data.Tasks.filter((task) => {
            return task.status === "Closed";
          });
          setTasksRows(closedTasks);
          setTasksRowsBk(closedTasks)
        }

        if (props.deptId === 2) {
          setTasksRows(response.data.Tasks);
          setTasksRowsBk(response.data.Tasks)
        }

        if (!props.deptId) {
          const data = response.data.Tasks.filter((task) => {
            return task.ticketId == ticketId;
          });

          setTasksRows(data);
          setTasksRowsBk(data)
        }
        
      }
    };

    getTasks();
  }, [ticketId]);

  ticketId = window.location.search.replace("?", "");


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tasksRows.length) : 0;

  const handleTitleSearch = (event) => {
    setSearchTitleVal(event.target.value);
    let filteredTasks;
    let filteredTasksIni;

    const navHistory = [{
      currentPage: 'Task',
      titleSearch: event.target.value,
      techSearch: searchTechVal,
      deptId: props.deptId
    }];

    localStorage.setItem('navHistoryTask', JSON.stringify(navHistory));

    if (unfilterTaskResponse.length > 0) {
      if (props.deptId === 1) {
        const openTasks = unfilterTaskResponse.filter((task) => {
          return task.status === "Open";
        });
        filteredTasksIni = openTasks;
      }

      // closed tasks
      if (props.deptId === 3) {
        const closedTasks = unfilterTaskResponse.filter((task) => {
          return task.status === "Closed";
        });
        filteredTasksIni = closedTasks;
      }


        filteredTasks = filteredTasksIni.filter((task)=>{
          return task.title.toLowerCase().includes(event.target.value)
        });

        if(searchTechVal !== "All"){
          filteredTasks = filteredTasks.filter((task)=>{
            return task.staffId.toLowerCase() === searchTechVal;
          });
        } 
    
        setTasksRows(filteredTasks)
      }
    }

  const handleTechSearch = (event) => {
    setSearchTechVal(event.target.value);

    let filteredTasks;
    let filteredTasksIni;

    const navHistory = [{
      currentPage: 'Task',
      titleSearch: searchTitleVal,
      techSearch: event.target.value,
      deptId: props.deptId
    }];

    localStorage.setItem('navHistoryTask', JSON.stringify(navHistory));

    if (unfilterTaskResponse.length > 0) {
      if (props.deptId === 1) {
        const openTasks = unfilterTaskResponse.filter((task) => {
          return task.status === "Open";
        });
        filteredTasksIni = openTasks;
      }

      // closed tasks
      if (props.deptId === 3) {
        const closedTasks = unfilterTaskResponse.filter((task) => {
          return task.status === "Closed";
        });
        filteredTasksIni = closedTasks;
      }

      if(event.target.value !== "All"){
        filteredTasks = filteredTasksIni.filter((task)=>{
          return task.staffId.toLowerCase() === event.target.value
        });
      } else {
        filteredTasks = filteredTasksIni;
      }
        
        if(searchTitleVal !== ""){
          filteredTasks = filteredTasks.filter((task)=>{
            return task.title.toLowerCase().includes(searchTitleVal)
          });
        }

        setTasksRows(filteredTasks)
      }    


  }
  
  return (
    <>
    {user.role != "Customer" && (
      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" divider={<Divider orientation="vertical" flexItem />} >
              <div className="flex sm:space-x-24 my-6" >
              <TextField
                      id="name"
                      label={t('Title')}
                      className="flex-auto"
                      onChange={(e) =>handleTitleSearch(e)}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      variant="outlined"
                      value={searchTitleVal}
                      required
                  />
              </div>
              <div className="flex sm:space-x-24 mb-6">
              {user.role !== "Customer" && (
                <FormControl>
                    <FormHelperText>{t("Technician")}:</FormHelperText>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={searchTechVal}
                        label="Ticket Source"
                        onChange={handleTechSearch}
                        disabled={false}
                    >
                      <MenuItem value='All'>All</MenuItem>
                        {staffUsers.map((row) => (
                            <MenuItem value={row.id}>{row.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
              )}
            </div>
            <div className="flex sm:space-x-24 mb-6">
              <IconButton 
                iconStyle={stylesIco.largeIcon}
                onClick={handleClearSearch}
              >
                  <SearchOffIcon label="Clear Search" />
                  <Typography sx={{marginLeft: '20px'}}>{t("Clear Search")}</Typography>
              </IconButton>
            </div>
          </Stack>
    )}
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, paddingX: 2 }}>
        {/* <div>{props.children}</div> */}
        <EnhancedTableToolbar
          numSelected={selected.length}
          ticketId={props.ticketId}
          ticketNum={props.ticketNum}
          isClosed={props.isClosed}
        >
          {props.children}
        </EnhancedTableToolbar>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />

            <TableBody>
              {stableSort(tasksRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <>
                      {user.role !== "Customer" && (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              // onClick={(event) => handleClick(event, row.name)}
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              key={row.name}
                            />
                          </TableCell> */}
                          {row.status != "Open" ? (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              <Button
                                className="saltexColor"
                                onClick={() => {
                                  localStorage.removeItem("pageFrom");
                                  localStorage.setItem("pageFrom", "tasks");
                                  if (row.status !== "Closed") {
                                    navigate("/tasksDetails/?" + row._id);
                                    // window.open(APP_URL + 'tasksDetails/?' + row.id, '_blank');
                                  } else {
                                    navigate(`/closedTask/${row._id}/${row.ticketId}`);
                                    // window.open(APP_URL + `closedTask/${row._id}/${row.ticketId}`, '_blank');
                                  }
                                }}
                              >
                                {row._id.slice(15, -1)}
                              </Button>
                            </TableCell>
                          ) : (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={{ backgroundColor: "pink" }}
                            >
                              <Button
                                className="saltexColor"
                                onClick={() => {
                                  localStorage.removeItem("pageFrom");
                                  localStorage.setItem("pageFrom", "tasks");
                                  if (row.status !== "Closed") {
                                    navigate("/tasksDetails/?" + row._id);
                                    // window.open(APP_URL + 'tasksDetails/?' + row.id, '_blank');
                                  } else {
                                    navigate(`/closedTask/${row._id}/${row.ticketId}`);
                                    // window.open(APP_URL + `closedTask/${row._id}/${row.ticketId}`, '_blank');
                                  }
                                }}
                              >
                                {row._id.slice(15, -1)}
                              </Button>
                            </TableCell>
                          )}

                          <TableCell align="right">
                            <Button
                              className="saltexColor"
                              onClick={() => {
                                localStorage.removeItem("pageFrom");
                                localStorage.setItem("pageFrom", "tasks");
                                navigate("/ticketsDetails/?" + row.ticketId);
                                // window.open(APP_URL + 'ticketsDetails/?' + row.ticketId, '_blank');
                              }}
                            >
                              {row.ticketNum}
                            </Button>
                          </TableCell>
                          <TableCell align="right">
                            {row.updated.split(".")[0].replace("T", " ")}
                          </TableCell>
                          <TableCell align="right">{row.title}</TableCell>
                          <TableCell align="right">{row.department}</TableCell>
                          <TableCell align="right">{row.staffName}</TableCell>
                        </TableRow>
                      )}
                      {user.role === "Customer" && (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              onClick={(event) => handleClick(event, row.name)}
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              key={row.name}
                            />
                          </TableCell>
                          {row.status != "Open" ? (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              <Button
                                className="saltexColor"
                                onClick={() => {
                                  localStorage.removeItem("pageFrom");
                                  localStorage.setItem("pageFrom", "tasks");
                                  navigate(`/closedTask/${row._id}`);
                                }}
                              >
                                {row._id.slice(15, -1)}
                              </Button>
                            </TableCell>
                          ) : (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={{ backgroundColor: "pink" }}
                            >
                              <Button
                                className="saltexColor"
                                disabled={row.status}
                              >
                                {row._id.slice(15, -1)}
                              </Button>
                            </TableCell>
                          )}

                          <TableCell align="right">
                            <Button
                              className="saltexColor"
                              onClick={() => {
                                localStorage.removeItem("pageFrom");
                                localStorage.setItem("pageFrom", "tasks");
                                navigate("/ticketsDetails/?" + row.ticketId);
                              }}
                            >
                              {row.ticketNum}
                            </Button>
                          </TableCell>
                          <TableCell align="right">
                            {row.updated.split(".")[0].replace("T", " ")}
                          </TableCell>
                          <TableCell align="right">{row.title}</TableCell>
                          <TableCell align="right">{row.department}</TableCell>
                          <TableCell align="right">{row.staffName}</TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tasksRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("Rows-per-page")}
        />
      </Paper>
    </Box>
    </>
  );
};

export default MafiTableTasks;
