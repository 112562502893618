import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next';

let taskData = "";
taskData = {
    ticketId: '3654781',
}

let visitNo = 1;


const TasksDetailWidget = (props) => {
    const { t } = useTranslation();
    return(
        <Box class="mt-4">
            <Stack direction="row" spacing={2} justifyContent="space-between">
                <Typography>
                    {t('Ticket')} : {props.ticketNum}
                </Typography>
                <Typography>
                    {t('Visit')} : {visitNo}
                </Typography>
            </Stack>
            <Typography>
                    {t('Task')} : {props.taskTitle}
            </Typography>
            <Typography>
                    {t('Objetive')} : {props.taskObjetive}
            </Typography>
        </Box>
    )
};

export default TasksDetailWidget;