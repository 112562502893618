import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

import SettingsIcon from "@mui/icons-material/Settings";
import LocationOffRoundedIcon from "@mui/icons-material/LocationOffRounded";
import RoomRoundedIcon from "@mui/icons-material/RoomRounded";
import TaskCheckIn from "./stepper/TaskCheckIn";
import TaskExecute from "./stepper/TaskExecute";
import TaskCheckOut from "./stepper/TaskCheckOut";
import { useLocation } from "react-router-dom";



export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [location, setLocation] = React.useState({ lat: "", long: "" });
  const [task, setTask] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const [sign, setSign] = React.useState(null);
  const [signTech, setSignTech] = React.useState(null);
  const [checkIn, setCheckIn] = React.useState("");
  const [checkOut, setCheckOut] = React.useState("");
  const [taskType, setTaskType] = React.useState('');


  let locationPath = useLocation();
  const API_URL = process.env.REACT_APP_API_URL;
  // const API_URL = 'http://localhost:5000/api/'

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getCurrentTime = () => {
    const hour = new Date().getHours().toString();
    const minutes = new Date().getMinutes().toString();
    const seconds = new Date().getSeconds().toString();

    const currentTime = `${hour.length === 1 ? "0" + hour : hour}:${
      minutes.length === 1 ? "0" + minutes : minutes
    }:${seconds.length === 1 ? "0" + seconds : seconds}`;

    return currentTime;
  };

  const handleCheckIn = (lat, long) => {
    setLocation({ lat: lat, long: long });

    handleNext();
  };

  const handleExecuteContinue = (task, description, files) => {
    setTask(task);
    setDescription(description);
    setFiles(files);

    handleNext();
  };

  const handleCheckOut = (sign, signTech, taskType, checkIn, checkOut) => {
    setSign(sign);
    setSignTech(signTech);
    setCheckIn(checkIn);
    setCheckOut(checkOut);
    setTaskType(taskType);
    handleNext();
    handleUpdate(sign, signTech, taskType, checkIn, checkOut);
  };

  const handleUpdate = async (sign, signTech, taskType, checkIn, checkOut) => {

    let formdata = new FormData();
    formdata.append("id", locationPath.search.slice(1));
    formdata.append("latitude", location.lat);
    formdata.append("longitude", location.long);
    formdata.append("task", task);
    files.forEach((file, index) => {
      formdata.append("image" + index, file);
    });
    formdata.append("signs", sign);
    formdata.append("signsTech", signTech);
    formdata.append("observation", description);
    formdata.append("checkIn", checkIn);
    formdata.append("checkOut", checkOut);
    formdata.append("taskType", taskType);

    console.log(formdata);

    const response = await fetch(`${API_URL}tasks`, {
      method: "PATCH",
      body: formdata,
    });
  };

  const steps = [
    {
      label: "CheckIn",
      description: `Saltex uses your device GPS to track your current location. We store your location information from you or your devices.`,
      component: <TaskCheckIn onCheckIn={handleCheckIn} />,
    },
    {
      label: "Execute",
      description:
        "An ad group contains one or more ads which target a shared set of keywords.",
      component: (
        <TaskExecute
          onExecuteBack={handleBack}
          onExecuteContinue={handleExecuteContinue}
        />
      ),
    },
    {
      label: "CheckOut",
      description: `Once you sign this task, you accept all the content of this task`,
      component: (
        <TaskCheckOut onExecuteBack={handleBack} onCheckOut={handleCheckOut} />
      ),
    },
  ];

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <RoomRoundedIcon />,
      2: <SettingsIcon />,
      3: <LocationOffRoundedIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              {step.component}
              {/* <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === 0
                      ? "Check In"
                      : index === steps.length - 1
                      ? "CheckOut"
                      : "Continue"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box> */}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
        </Paper>
      )}
    </Box>
  );
}
