import { Box } from "@mui/material";
import React from "react";
import ReportsHeader from "./ReportsHeaderTasks";
import ReportsMainTasks from "./widgets/ReportsMainTasks";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const ReportsTasks = () => {
  return (
    <Box
      paddingTop={2}
      sx={{
        flex: { sx: 4, sm: 4, md: 4, lg: 5 },
        backgroundColor: "white",
        p: { sx: 1, sm: 1, md: 1, lg: 2 },
      }}
    >
      <ReportsHeader />
      <QueryClientProvider client={queryClient}>
        <ReportsMainTasks />
      </QueryClientProvider>
    </Box>
  );
};

export default ReportsTasks;
