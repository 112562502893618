import styled from "@emotion/styled";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MafiDragAndDrop from "../../../../@mafi/MafiDragAndDrop";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const TaskExecute = ({ onExecuteBack, onExecuteContinue }) => {
  const [tStatus, setTStatus] = useState("Working");
  const [taskObservation, setTaskObservation] = useState("");
  const [files, setFiles] = useState([]);
  const [archives, setArchives] = useState([]);
  const { t } = useTranslation();


  const handleTStatusChange = (event) => {
    const userTIssueTitle = event.target.value;
    /// console.log(userNameText);
    setTStatus(userTIssueTitle);
  };

  const handleTaskObservations = (event) => {
    setTaskObservation(event.target.value);
  };

  const handleCheckIn = () => {
    onExecuteContinue(tStatus, taskObservation, files)
  };

  const handleFiles = (data) => {
    setFiles(data);
  }

  const handleBack = () => {
    setArchives([])
    onExecuteBack()
  }

  const handleRemove = (data, file) =>{
    files.splice(files.indexOf(file), 1);
    const newFiles = [...files]   
    setFiles(newFiles)
    // setArchives([])
    setArchives(data)
  }


  return (
    <>
      <Box sx={{ width: "93%", m: 5 }}>
        <Item>
          {/* <form onSubmit={handleSubmitTaskExecute}> */}
          <div>
            <div className="flex sm:space-x-24 mb-6">
              <FormControl class="w-1/2">
                <FormHelperText>Status:</FormHelperText>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={tStatus}
                  label="Status"
                  onChange={handleTStatusChange}
                  fullWidth
                >
                  <MenuItem value="Working">Working</MenuItem>
                  <MenuItem value="Done">Done</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex sm:space-x-24 my-6">
              <TextField
                id="outlined-multiline-static"
                label={t("Task-Observation")}
                value={taskObservation}
                multiline
                rows={4}
                defaultValue=" "
                fullWidth
                required
                onChange={(e) => handleTaskObservations(e)}
              />
            </div>
            <div className="flex sm:space-x-24 my-6">
              <MafiDragAndDrop onDropdown={handleFiles} onSetFiles={archives} onRemove={handleRemove} />
            </div>
          </div>
          {/* </form>             */}
        </Item>
      </Box>
      <div>
        <Button
          variant="contained"
          onClick={handleCheckIn}
          sx={{ mt: 1, mr: 1 }}
          disabled={!taskObservation}
        >
          Continue
        </Button>
        {/* <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
          Back
        </Button> */}
      </div>
    </>
  );
};

export default TaskExecute;
