import { Box, Stack, ThemeProvider, createTheme } from "@mui/material";
import React, { useState } from "react";
import NavBar from "../main/navbar/Navbar";
import RightBar from "../main/rightbar/RightBar";
import AddTooltip from "../main/addTooltip/AddTooltip";
import PPNavigation from "../main/ppNavigation/PPNavigation";
import Users from "../main/users/Users";

const SaltexUsers = ({ openTickets }) => {
  const [mode, setMode] = useState("light");

  const user = JSON.parse(localStorage.getItem("user"));
  const userRole = user?.role;

  if (userRole != "Admin") {
    window.location = "/";
  }

  const darkTheme = createTheme({
    palette: {
      mode: mode,
    },
  });
  return (
    <ThemeProvider theme={darkTheme}>
      <Box>
        <NavBar totalOpenTickets={openTickets} />
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <PPNavigation />
          <Users />
          <RightBar />
        </Stack>
        <AddTooltip />
      </Box>
    </ThemeProvider>
  );
};

export default SaltexUsers;
