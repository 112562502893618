import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import OrganizationHeader from "./OrganizationHeader";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const OrganizationEdit = ({ state }) => {
  const [organizationStatus, setOrganizationStatus] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [organizationAddress, setOrganizationAddress] = useState("");
  const [organizationPhone, setOrganizationPhone] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [organizationParent, setOrganizationParent] = useState("");
  const [alert, setAlert] = useState({
    message: "",
    severity: "",
    isActive: false,
  });
  const refOrganizationPhone = useRef();
  const { t } = useTranslation();

  let organizations = JSON.parse(localStorage.getItem("organizations"));
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const setOrganizationData = () => {
      setOrganizationStatus(state.status);
      setOrganizationName(state.name);
      setOrganizationAddress(state.address);
      setOrganizationPhone(state.phone);
      setOrganizationId(state.id);
      setOrganizationParent(state.orgParent);
    };

    setOrganizationData();
  }, [state]);

  const handleChange = (e) => {
    setOrganizationStatus(e.target.value);
  };

  const handleSubmit = async () => {
    const data = {
      id: organizationId,
      name: organizationName,
      status: organizationStatus,
      address: organizationAddress,
      phone: organizationPhone,
      orgParent: organizationParent,
      updatedBy: user._id,
    };

    try {
      const response = await axios.patch(`${API_URL}organizations/edit`, data);

      setAlert({
        message: "Organization updated successfully",
        severity: "success",
        isActive: true,
      });
    } catch (error) {
      setAlert({
        message: error.response.data.message,
        severity: "error",
        isActive: true,
      });
    }
  };

  const handleChangePhone = (e) => {
    setOrganizationPhone(e.target.value);
  };

  const handleChangeName = (e) => {
    setOrganizationName(e.target.value);
  };

  const handleChageAddress = (e) => {
    setOrganizationAddress(e.target.value);
  };

  const handleOrganization = (e) => {
    setOrganizationParent(e.target.value);
  };

  return (
    <Box
      paddingTop={2}
      sx={{
        flex: { sx: 4, sm: 4, md: 4, lg: 5 },
        backgroundColor: "white",
        p: { sx: 1, sm: 1, md: 1, lg: 2 },
      }}
    >
      <OrganizationHeader />
      {alert.isActive && (
        <Alert severity={alert.severity}>{t(alert.message)}</Alert>
      )}
      <Box className="bg-slate-50 p-2 mt-4 rounded-lg">
        <Typography
          variant="h5"
          className="py-2 text-blue-900"
          sx={{ fontSize: "1.25rem", fontWeight: "bold" }}
        >
          Edit Organization
        </Typography>
        <Paper
          sx={{
            p: 2,
            margin: "auto",
            display: "flex",
            flexWrap: "wrap",
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "#1A2027" : "#fff",
          }}
        >
          <Grid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
            <Grid item xs={6} paddingBottom={2}>
              <TextField
                fullWidth
                id="outlined-password-input"
                label="Name"
                type="text"
                value={organizationName}
                onChange={handleChangeName}
              />
            </Grid>
            <Grid item xs={6} paddingBottom={2}>
              <TextField
                fullWidth
                id="outlined-password-inpu"
                label="Address"
                type="text"
                value={organizationAddress}
                onChange={handleChageAddress}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-password-inp"
                label="Phone"
                type="text"
                value={organizationPhone}
                onChange={handleChangePhone}
                autoComplete="current-password"
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel id="active">Status</FormLabel>
              <RadioGroup
                row
                aria-labelledby="controlled-radio-buttons-status"
                name="controlled-radio-buttons-group"
                value={organizationStatus}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Active"
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  value="Inactive"
                  control={<Radio />}
                  label="Inactive"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={6} paddingBottom={2}>
              <FormControl fullWidth>
                <FormHelperText>{t("organization-parent")}:</FormHelperText>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={organizationParent}
                  label="Ticket Source"
                  onChange={handleOrganization}
                  disabled={organizationParent === '0'}
                >
                  {organizations.map((row, index) => (
                    <MenuItem key={index} value={row.name}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingBottom={2}
          paddingTop={2}
        >
          <Button
            variant="outlined"
            color="success"
            style={{ width: "200px" }}
            onClick={handleSubmit}
          >
            {t("edit")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OrganizationEdit;
