import { Box } from "@mui/material";
import React from "react";
import ReportsHeader from "./ReportsHeader";
import ReportsMain from "./widgets/ReportsMain";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const Reports = () => {
  return (
    <Box
      paddingTop={2}
      sx={{
        flex: { sx: 4, sm: 4, md: 4, lg: 5 },
        backgroundColor: "white",
        p: { sx: 1, sm: 1, md: 1, lg: 2 },
      }}
    >
      <ReportsHeader />
      <QueryClientProvider client={queryClient}>
        <ReportsMain />
      </QueryClientProvider>
    </Box>
  );
};

export default Reports;
