import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PasswordIcon from "@mui/icons-material/Password";
import FaceIcon from "@mui/icons-material/Face";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import HomeIcon from '@mui/icons-material/Home';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import BusinessIcon from '@mui/icons-material/Business';
import axios from "axios";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const API_URL = process.env.REACT_APP_API_URL
let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// let orgData = JSON.parse(localStorage.getItem("organizations"));

const UserAddForm = (props) => {
  const [message, setMessage] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");
  const [userStatus, setuserStatus] = React.useState("Active");
  const [userPhone, setUserPhone] = React.useState("");
  const [userMPhone, setUserMPhone] = React.useState("");
  const [orgData, setOrgData] = React.useState([]);
  const [userOrganization, setUserOrganization] = React.useState("Saltex Group");
  const [userDepartment, setUserDepartment] = React.useState("Support");
  const [userRole, setUserRole] = React.useState("Admin");
  const [userPasswd1, setUserPasswd1] = React.useState("");
  const [userPasswd2, setUserPasswd2] = React.useState("");
  const [messagePasswd, setMessagePasswd] = React.useState("");
  const [depDisabled, setDepDisabled] = React.useState(false);
  const [roleDisabled, setRoleDisabled] = React.useState(false);
  const [userAddress, setUserAddress] = React.useState("");
  const [userCity, setUserCity] = React.useState("");
  const [userCountry, setUserCountry] = React.useState("");
  const { t } = useTranslation();
  const navigate = useNavigate()

  useEffect(() => {
    const getOrganization = async () => {
      const response = await axios.get(`${API_URL}organizations`);
      setOrgData(response.data.organizations);
    };
    getOrganization();
  }, []);

  const handleSubmitUser = async (e) => {
    e.preventDefault();
    if (userPasswd1 !== userPasswd2) {
      setMessagePasswd(
        <Alert severity="error">{t("Passwords should be the same")}</Alert>
      );
      setTimeout(function () {
        setMessagePasswd("");
      }, 5000);
      setUserPasswd1("");
      setUserPasswd2("");
    } else {
      const userData = {
        department: userDepartment,
        organization: userOrganization,
        role: userRole,
        name: userName,
        passwd: userPasswd1,
        email: userEmail.toLowerCase(),
        address: userAddress,
        city: userCity,
        country: userCountry,
        phone: userPhone,
        mobile: userMPhone,
        status: userStatus,
        timezone: timezone,
      };
      // console.log(JSON.stringify({ userData }));
      try {
        const response = await fetch(API_URL + "users/signup", {
          method: "Post",
          headers: {
            "Content-Type": "Application/json",
          },
          body: JSON.stringify(userData),
        });

        const responseData = await response.json();

        setUserName("");
        setUserPasswd1("");
        setUserPasswd2("");
        setUserEmail("");
        setUserAddress("");
        setUserMPhone("");
        setUserPhone("");
        setUserCity("");
        setUserCountry("");
        setuserStatus("Active");
        // props.addUsersListHandler(userData);
        // navigate("/users")
        setMessage(
          <Alert severity="success">{t("User created successfully")}</Alert>
        );

        setTimeout(() => {
          navigate(0);
        }, 2000);

        setTimeout(function () {
          setMessage("");
          hiddeExpPopUp();
          navigate(0);
        }, 5000);
      } catch (err) {
        setMessage(
          <Alert severity="error">
            {t("Some error occurred, Contact Tech Support")}
          </Alert>
        );
        setTimeout(function () {
          setMessage("");
        }, 5000);
      }
    }
  };

  const hiddeExpPopUp = () => {
    props.onCalcelSaveExp();
  };

  const handleUserName = (event) => {
    const userName = event.target.value;
    /// console.log(userNameText);
    setUserName(userName);
  };

  const handleUserEmail = (event) => {
    const userEmail = event.target.value;
    /// console.log(userNameText);
    setUserEmail(userEmail);
  };

  const handleUserPhone = (event) => {
    const userPhone = event.target.value;
    /// console.log(userNameText);
    setUserPhone(userPhone);
  };

  const handleUserMPhone = (event) => {
    const userMPhone = event.target.value;
    /// console.log(userNameText);
    setUserMPhone(userMPhone);
  };

  const handleUserOrganization = (event) => {
    const userOrganization = event.target.value;
    if (userOrganization !== "Saltex Group") {
      setUserRole("Customer");
      setUserDepartment("Is Customer");
      setDepDisabled(true);
      setRoleDisabled(true);
    } else {
      setUserRole("Technician");
      setUserDepartment("Support");
      setDepDisabled(false);
      setRoleDisabled(false);
    }
    /// console.log(userNameText);
    setUserOrganization(userOrganization);
  };

  const handleUserDepartment = (event) => {
    const userDepartment = event.target.value;
    if (userDepartment === "Is Customer") {
      setUserRole("Customer");
    } else {
      setUserRole("Technician");
    }
    /// console.log(userNameText);
    setUserDepartment(userDepartment);
  };

  const handleUserRole = (event) => {
    const userRole = event.target.value;
    if (userRole === "Customer") {
      setUserDepartment("Is Customer");
    } else {
      setUserDepartment("Support");
    }
    /// console.log(userNameText);
    setUserRole(userRole);
  };

  const handleUserStatus = (event) => {
    const userStatus = event.target.value;
    /// console.log(userNameText);
    setuserStatus(userStatus);
  };

  const handleUserPasswd1 = (event) => {
    const userPasswd1 = event.target.value;
    // console.log(groupNameText);
    setUserPasswd1(userPasswd1);
  };

  const handleUserPasswd2 = (event) => {
    const userPasswd2 = event.target.value;
    // console.log(groupNameText);
    setUserPasswd2(userPasswd2);
  };

  const handleUserAddress = (event) => {
    setUserAddress(event.target.value);
  };

  const handleUserCountry = (event) => {
    setUserCountry(event.target.value);
  };

  const handleUserCity = (event) => {
    setUserCity(event.target.value);
  };

  return (
    <Box sx={{ width: "93%", m: 5 }}>
      {message} {messagePasswd}
      <Item>
        <form onSubmit={handleSubmitUser}>
          <Typography className="text-lg font-bold leading-none">
            {t("New-User")}
          </Typography>
          <div>
            <div className="flex sm:space-x-24 my-6">
              <FaceIcon />
              <TextField
                id="name"
                label={t("Name")}
                className="flex-auto"
                onChange={(e) => handleUserName(e)}
                value={userName}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
                fullWidth
              />
            </div>
            <div className="flex sm:space-x-24 pt-4 mb-4">
              <PasswordIcon />
              <TextField
                id="userPasswd1"
                label={t("Password")}
                className="flex-auto"
                type="password"
                onChange={(e) => handleUserPasswd1(e)}
                value={userPasswd1}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                inputProps={{ minLength: 8 }}
                required
                fullWidth
              />
              <PasswordIcon />
              <TextField
                id="userPasswd2"
                label={t("Confirm-Password")}
                className="flex-auto"
                type="password"
                onChange={(e) => handleUserPasswd2(e)}
                value={userPasswd2}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                inputProps={{ minLength: 8 }}
                required
                fullWidth
              />
            </div>
            <div className="flex sm:space-x-24 pt-4 mb-4">
              <HomeIcon />
              <TextField
                id="Address"
                label={t("Address")}
                className="flex-auto"
                onChange={(e) => handleUserAddress(e)}
                value={userAddress}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
              />

              <TextField
                id="City"
                label={t("City")}
                className="flex-auto"
                onChange={(e) => handleUserCity(e)}
                value={userCity}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
              />

              <TextField
                id="Country"
                label={t("Country")}
                className="flex-auto"
                onChange={(e) => handleUserCountry(e)}
                value={userCountry}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
              />
            </div>

            <div className="flex sm:space-x-24 pt-4 mb-4">
            <AlternateEmailIcon />
              <TextField
                id="Email"
                type="email"
                label={t("Email")}
                className="flex-auto"
                onChange={(e) => handleUserEmail(e)}
                value={userEmail}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
              />
            </div>

            <div className="flex sm:space-x-24 pt-6 mb-6">
              <PhoneInTalkIcon />
              <TextField
                id="Phone"
                label={t("Phone")}
                className="flex-auto"
                onChange={(e) => handleUserPhone(e)}
                value={userPhone}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
              />

              <TextField
                id="mobilePhone"
                label={t("Mobile-Phone")}
                className="flex-auto"
                onChange={(e) => handleUserMPhone(e)}
                value={userMPhone}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
              />

              <FormControl sx={{ mt: -3 }}>
                <FormHelperText>{t("Organization")}:</FormHelperText>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userOrganization}
                  label="Ticket Source"
                  onChange={handleUserOrganization}
                  sx={{
                    width: 280,
                  }}
                >
                  {orgData.map((row) => (
                    <MenuItem value={row.name}>{row.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="flex sm:space-x-24 mb-6">
            <BusinessIcon className="mt-8" />
              <FormControl>
                <FormHelperText>{t("Department")}:</FormHelperText>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userDepartment}
                  label={t("Department")}
                  onChange={handleUserDepartment}
                  disabled={depDisabled}
                  sx={{
                    width: 280,
                  }}
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Support">Support</MenuItem>
                  <MenuItem value="Is Customer">Is Custumer</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <FormHelperText>{t("Role")}:</FormHelperText>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userRole}
                  label={t("Role")}
                  onChange={handleUserRole}
                  disabled={roleDisabled}
                  sx={{
                    width: 280,
                  }}
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Technician">Technician</MenuItem>
                  <MenuItem value="Customer">Custumer</MenuItem>
                </Select>
              </FormControl>

              <FormControl>
                <FormHelperText>Status:</FormHelperText>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userStatus}
                  label="Ticket Source"
                  onChange={handleUserStatus}
                  sx={{
                    width: 280,
                  }}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex sm:space-x-24 mb-16">
              <Button
                variant="outlined"
                color="error"
                className=" w-1/2 mt-16"
                aria-label="Add"
                type="button"
                onClick={hiddeExpPopUp}
                size="large"
              >
                {t("Cancel")}
              </Button>
              <Button
                variant="outlined"
                color="success"
                className=" w-1/2 mt-16"
                aria-label="Add"
                type="submit"
                size="large"
              >
                {t("Add")} {t("User")}
              </Button>
            </div>
          </div>
        </form>
      </Item>
    </Box>
  );
};

export default UserAddForm;
