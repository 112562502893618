/* eslint-disable no-extend-native */
import * as React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import {
  Alert,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MafiDragAndDrop from "../../../@mafi/MafiDragAndDrop";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
let dataUsers = [];
let usersRows = [];
let customerUsers = [];
let staffUsers = [];
let uniqueStaffUsers = [];
let dataTicket = [];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function createData(
  id,
  name,
  department,
  organization,
  role,
  phone,
  email,
  mobile,
  status,
  tickets,
  ticketsCreated,
  tasks
) {
  return {
    id,
    name,
    department,
    organization,
    role,
    phone,
    email,
    mobile,
    status,
    tickets,
    ticketsCreated,
    tasks,
  };
}

function createDataCustomer(id, name) {
  return {
    label: name,
    id: id,
  };
}

const getTicketById = async (ticketId) => {
  return new Promise(function (resolve, reject) {
    fetch(API_URL + "tickets/" + ticketId)
      .then((response) => response.json())
      .then((jsonData) => {
        dataTicket = jsonData;
        resolve();
      });
  });
};

// const readUsersData = async () => {
//   return new Promise(function (resolve, reject) {
//     fetch(API_URL + "users")
//       .then((response) => response.json())
//       .then((jsonData) => {
//         dataUsers = jsonData;
//         resolve();
//       });
//   });
// };

// readUsersData().then(function () {
//   getTicketById().then(function () {
//     dataUsers.users.forEach((user) => {
//       usersRows.push(
//         createData(
//           user.id,
//           user.name,
//           user.department,
//           user.organization,
//           user.role,
//           user.phone,
//           user.email,
//           user.mobile,
//           user.status,
//           user.tickets,
//           user.ticketsCreated,
//           user.tasks
//         )
//       );
//     });

//     // usersRows.forEach((user) => {
//     //   if (user.role == "Customer" && user.status == "Active") {
//     //     customerUsers.push(createDataCustomer(user.id, user.name));
//     //   }
//     //   if (
//     //     (user.role == "Admin" || user.role == "Technician") &&
//     //     user.status == "Active"
//     //   ) {
//     //     staffUsers.push(createDataCustomer(user.id, user.name));
//     //   }
//     // });

//     // uniqueStaffUsers = [...new Map(staffUsers.map((m) => [m.id, m])).values()];
//     // setTechList(staffUsers);
//     // console.log(staffUsers);
//   });
// });

const TasksAddForm = (props) => {
  const { t } = useTranslation();
  const [message, setMessage] = React.useState("");
  const [messagePasswd, setMessagePasswd] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const [tDepartment, setTDepartment] = React.useState("Support");
  const [tAgent, setTAgent] = React.useState("0");
  const [tAgentName, setTAgentName] = React.useState("0");
  const [taskTitle, setTaskTitle] = React.useState("");
  const [taskDesc, setTaskDesc] = React.useState("");
  const [ticket, setTicket] = React.useState("");
  const [messageCustomer, setMessageCustomer] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const [archives, setArchives] = React.useState([]);
  const [staffUsers, setStaffUsers] = React.useState([]);
  const location = useLocation()

  useEffect(() => {
    let uniqueStaffUsers = []
    const getUsers = async () => {
      const response = await axios.get(`${API_URL}users`);


      let users = response.data.users.filter((user) => {
        return (
          user.role === "Admin" || user.role === "Technician"
        );
      });

      let staffSupport = users.filter((staff) => {
        return !(
          staff.name === "Support" && staff.organization === "Saltex Group"
        );
      });

      staffSupport.forEach(staff => {
        uniqueStaffUsers.push({ id: staff.id, label: staff.name })
      })

      setStaffUsers(uniqueStaffUsers)


    };
    getUsers();

  }, [])


  const handleSubmitTicket = async (e) => {
    e.preventDefault();
    if (tAgent == "0") {
      setMessageCustomer(
        <Alert severity="error">{t("Please-Select-A-Technician")}</Alert>
      );
      setTimeout(function () {
        setMessageCustomer("");
      }, 5000);
    } else {
      let date = new Date();
      var current_time =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      let current_date =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        current_time;
      let ticketNum = localStorage.getItem("ticketNum");

      // const taskData = {
      //   ticketId: props.ticketId,
      //   ticketNum: ticketNum,
      //   department: tDepartment,
      //   staffId: tAgent,
      //   staffName: tAgentName,
      //   status: "Open",
      //   created: current_date,
      //   closed: "",
      //   updated: current_date,
      //   title: taskTitle,
      //   objetives: taskDesc,
      //   pics: "",
      // };
      // console.log(JSON.stringify({ taskData }));

      let formdata = new FormData();
      formdata.append("ticketId", props.ticketId);
      formdata.append("ticketNum", props.ticketNum);
      formdata.append("department", tDepartment);
      formdata.append("staffId", tAgent);
      formdata.append("staffName", tAgentName);
      formdata.append("status", "Open");
      files.forEach((file, index) => {
        formdata.append("image" + index, file);
      });
      formdata.append("created", current_date);
      formdata.append("closed", "");
      formdata.append("updated", current_date);
      formdata.append("title", taskTitle);
      formdata.append("objetives", taskDesc);

      try {
        const response = await fetch(API_URL + "tasks", {
          method: "POST",
          // headers: {
          //   "Content-Type": "multipart/form-data",
          // },
          body: formdata,
        });

        const responseData = await response.json();
        // console.log(responseData);

        setMessageCustomer(
          <Alert severity="success">{t("Task-Created")}</Alert>
        );
        setTimeout(function () {
          setMessageCustomer("");
          window.location.replace("/tasksDetails/?" + responseData.Task._id);
        }, 2000);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleTicket = (event) => {
    const userTicket = event.target.value;
    /// console.log(userNameText);
    setTicket(userTicket);
  };

  const handleTaskDesc = (event) => {
    const userTaskDesc = event.target.value;
    /// console.log(userNameText);
    setTaskDesc(userTaskDesc);
  };

  const handleTaskTitle = (event) => {
    const userTIssueTitle = event.target.value;
    /// console.log(userNameText);
    setTaskTitle(userTIssueTitle);
  };

  Array.prototype.remove = function () {
    var what,
      a = arguments,
      L = a.length,
      ax;
    while (L && this.length) {
      what = a[--L];
      while ((ax = this.indexOf(what)) !== -1) {
        this.splice(ax, 1);
      }
    }
    return this;
  };

  const hiddeExpPopUp = () => {
    props.onCalcelSaveExp();
  };

  const handleTDepartmentChange = (event) => {
    setTDepartment(event.target.value);
  };

  const handleTAgentChange = (event, name) => {
    setTAgent(event.target.value);
    setTAgentName(name.props.id);
  };

  const handleFiles = (data) => {
    setFiles(data);
  };

  const handleRemove = (data, file) =>{
    files.splice(files.indexOf(file), 1);
    const newFiles = [...files]   
    setFiles(newFiles)
    // setArchives([])
    setArchives(data)
  }

  return (
    <Box sx={{ width: "93%", m: 5 }}>
      {message} {messageCustomer}
      <Item>
        <form onSubmit={handleSubmitTicket}>
          <Typography variant="h5" class="text-xl pb-4 text-blue-900 font-bold">
            {t("New-Task")}
          </Typography>
          <div>
            <Stack
              spacing={4}
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <div className="flex sm:space-x-24 mb-6">
                <FormControl class="w-1/2">
                  <FormHelperText>Department:</FormHelperText>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={tDepartment}
                    label="Department"
                    onChange={handleTDepartmentChange}
                    sx={{ width: "300px" }}
                  >
                    <MenuItem value="Support">Support</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="flex sm:space-x-24 mb-16">
                <FormControl>
                  <FormHelperText>Assign To: *</FormHelperText>
                  <Select
                    width="max-content"
                    minWidth="100%"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={tAgent}
                    label="Select an Agent"
                    onChange={handleTAgentChange}
                    required
                    sx={{ width: "300px" }}
                  >
                    <MenuItem value="0">Select an Agent</MenuItem>
                    {staffUsers.map((staff) => (
                      <MenuItem id={staff.label} value={staff.id}>
                        {staff.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Stack>

            <div className="flex sm:space-x-24 my-6">
              <TextField
                id="tTaskTitle"
                label={t("task-title")}
                className="flex-auto"
                onChange={(e) => handleTaskTitle(e)}
                value={taskTitle}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
                fullWidth
              />
            </div>
            <div className="flex sm:space-x-24 my-6">
              <TextField
                id="outlined-multiline-static"
                label={t("task-description")}
                value={taskDesc}
                multiline
                rows={4}
                defaultValue=" "
                fullWidth
                required
                onChange={(e) => handleTaskDesc(e)}
              />
            </div>
            <div className="flex sm:space-x-24 my-6">
              <MafiDragAndDrop onDropdown={handleFiles} onSetFiles={archives} onRemove={handleRemove}/>
            </div>

            <div className="flex sm:space-x-24 mb-2 ">
              {props.ticket ? (
                ""
              ) : (
                <Button
                  variant="outlined"
                  color="success"
                  className=" w-1/2 mt-16"
                  aria-label="Add"
                  type="submit"
                  size="large"
                  fullWidth
                >
                  {t("Add-Task")}
                </Button>
              )}
            </div>
          </div>
        </form>
      </Item>
    </Box>
  );
};

export default TasksAddForm;
