import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Divider,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  ThemeProvider,
  createTheme,
  Typography,
  Link,
  Fab,
  Avatar,
  styled,
} from "@mui/material";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import Paper from "@mui/material/Paper";
import NavBar from "../main/navbar/Navbar";
import RightBar from "../main/rightbar/RightBar";
import AddTooltip from "../main/addTooltip/AddTooltip";
import PPNavigation from "../main/ppNavigation/PPNavigation";
import MafiImageGallery from "../@mafi/MafiImageGallery";
import GoogleMapReact from "google-map-react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Icon from "@mui/material/Icon";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import { Font } from "@react-pdf/renderer";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

const API_URL = process.env.REACT_APP_API_URL;
const HOST = process.env.REACT_APP_HOST;
const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
const APP_FRONTEND_HOST = process.env.REACT_APP_FRONTEND_HOST;
let taskNumber = 0;
let customerUsers = [];
let staffUsers = [];

const user = JSON.parse(localStorage.getItem("user"));
const appUserName = user?.name;
const appUserOrg = user?.organization;
const appUserImageProfile = null;

const AnyReactComponent = ({ text }) => (
  <>
    <div
      style={{
        color: "white",
        backgroundColor: "rgba(0,0,165,0.7)",
        padding: "10px 10px",
        display: "inline-flex",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "100%",
        transform: "translate(-50%, -50%)",
      }}
    >
      {/* {text} */}

      <GpsFixedIcon
        sx={{ color: "#ffa500", fontSize: 32, fontWeight: "bold" }}
        htmlColor="secondary"
      />
    </div>
  </>
);

const Item = styled(Typography)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  fontWeight: 'bold'
}));

function createDataCustomer(id, name, email, address, phone, organization,profileImage) {
  return {
    label: name,
    id: id,
    email: email,
    address: address,
    phone: phone,
    organization: organization,
    profileImage: profileImage
  };
}

function createData(
  id,
  address,
  name,
  department,
  organization,
  role,
  phone,
  email,
  mobile,
  status,
  tickets,
  ticketsCreated,
  tasks,
  profileImage
) {
  return {
    id,
    address,
    name,
    department,
    organization,
    role,
    phone,
    email,
    mobile,
    status,
    tickets,
    ticketsCreated,
    tasks,
    profileImage,
  };
}

function secondsToHms(d) {
  if(d===0){
      return '0 hours,'
  }
  d = Number(d)/1000;
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay + sDisplay; 
}

const SaltexClosedTask = ({ openTickets }) => {
  const [mode, setMode] = useState("light");
  const [taskDetail, setTaskDetail] = useState({});
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [ticketData, setTicketData] = useState([]);
  const [dataUsers, setdataUsers] = useState([]);
  const [uniqueStaffUsers, setUniqueStaffUsers] = useState([]);
  const [numReports, setNumReports] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [totalTimeOnSite, setTotalTimeOnSite] = useState(0);
  const [totalTimeRemote, setTotalTimeRemote] = useState(0);
  const [taskAddress, setTaskAddress] = useState('');
  const [techProfileImg, setTechProfileImg] = useState(null);
  const { t } = useTranslation();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Task Report: ' + taskNumber + ' | Ticket: ' + taskDetail.ticketNum
  });

  const darkTheme = createTheme({
    palette: {
      mode: mode,
    },
  });
  let { id, ticketId } = useParams();

  useEffect(() => {
    let usersRows = [];
    let dataUsers = [];
    let ticketDataTemp = {};
    const getTaskDataById = async () => {
      try {
        const response = await axios.get(`${API_URL}tasks/details/${id}`);

        // Parse dates to America/New_York
        let dateTempOpen = Date.parse(response.data.Tasks[0].created);
        dateTempOpen = new Date(dateTempOpen);
        response.data.Tasks[0].created = dateTempOpen.toLocaleString("en-US", {timeZone: "America/New_York"});

        let dateTempClosed = Date.parse(response.data.Tasks[0].closed);
        dateTempClosed = new Date(dateTempClosed);
        response.data.Tasks[0].closed = dateTempClosed.toLocaleString("en-US", {timeZone: "America/New_York"});

        setNumReports(response.data.Tasks[0].report.length);

        let totalSecondsOnSite = 0;
        let totalSecondsRemote = 0;
        let totalSeconds = 0

        response.data.Tasks[0].report.map((report)=>{
            let iniTemp = new Date(report.checkIn);
            let endTemp = new Date(report.checkOut);

            if(report.taskType === 'Remote'){
              totalSecondsRemote = totalSecondsRemote + (Date.parse(endTemp) - Date.parse(iniTemp));
              totalSeconds = totalSeconds + + (Date.parse(endTemp) - Date.parse(iniTemp));
            } else {
              totalSecondsOnSite = totalSecondsOnSite + (Date.parse(endTemp) - Date.parse(iniTemp));
              totalSeconds = totalSeconds + + (Date.parse(endTemp) - Date.parse(iniTemp));
            }
            
          })

        setTotalTime(secondsToHms(totalSeconds));
        setTotalTimeOnSite(secondsToHms(totalSecondsOnSite));
        setTotalTimeRemote(secondsToHms(totalSecondsRemote));

        setTaskDetail(response.data.Tasks[0]);
        taskNumber = response.data.Tasks[0]._id.slice(15,-1);
        if (
          response.data.Tasks[0].report[0]?.location.lat &&
          response.data.Tasks[0].report[0]?.location.long
        ) {
          setLatitude(+response.data.Tasks[0].report[0].location.lat);
          setLongitude(+response.data.Tasks[0].report[0].location.long);
          
          const responseDir = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=` + response.data.Tasks[0].report[0].location.lat + `,` + response.data.Tasks[0].report[0].location.long + `&key=` + GOOGLE_MAP_KEY);
          setTaskAddress(responseDir.data.results[0].formatted_address);
        }

        const responseTicket = await axios.get(`${API_URL}tickets/${ticketId}`);
        ticketDataTemp = responseTicket.data.ticket;

        const responseUsers = await axios.get(`${API_URL}users`);

        dataUsers = responseUsers.data.users;
        dataUsers.forEach((user) => {
          usersRows.push(
            createData(
              user.id,
              user?.address,
              user.name,
              user.department,
              user.organization,
              user.role,
              user.phone,
              user.email,
              user.mobile,
              user.status,
              user.tickets,
              user.ticketsCreated,
              user.tasks,
              user.profileImage
            )
          );
        });

        usersRows.forEach((user) => {
          if (user.role == "Customer" && user.status == "Active") {
            customerUsers.push(
              createDataCustomer(
                user.id,
                user.name,
                user.email,
                user.address,
                user.phone,
                user.organization,
                user.profileImage
              )
            );
          }
          if (
            (user.role === "Admin" || user.role === "Technician") &&
            user.status === "Active"
          ) {
            staffUsers.push(createDataCustomer(user.id,
              user.name,
              user.email,
              user.address,
              user.phone,
              user.organization,
              user.profileImage));
          }
        });

        setUniqueStaffUsers([
          ...new Map(staffUsers.map((m) => [m.id, m])).values(),
        ]);

        let technician = staffUsers.find(
          (staff) => staff.id === response.data.Tasks[0].staffId
        );
        if (technician == undefined){
          technician = {label:'Inactive'}
        } else {
          setTechProfileImg(technician.profileImage);
        }

        let customer = customerUsers.find(
          (customer) => customer.id == ticketDataTemp.customerId
        );

        setTicketData({
          ticketNum: ticketDataTemp.number,
          ticketId: ticketDataTemp.id,
          ticketTitle: ticketDataTemp.title,
          ticketDescription: ticketDataTemp.description,
          ticketStatus: ticketDataTemp.status,
          ticketPriority: ticketDataTemp.priority,
          ticketDepartment: ticketDataTemp.department,
          ticketUser: customer.label,
          ticketUserEmail: customer.email,
          ticketUserAddress: customer.address,
          ticketUserPhone: customer.phone,
          ticketUserOrg: customer.organization,
          ticketSource: ticketDataTemp.source,
          ticketIssue: ticketDataTemp.issue,
          ticketAsignTo: technician.label,
          ticketAsignToId: ticketDataTemp.staffId,
          ticketTechnician: technician
        });





      } catch (error) {
        console.log(error);
      }
    };

    getTaskDataById();
  }, [id, ticketId]);

  let galleryImages = [];
  let pdfFiles = [];

  const defaultProps = {
    center: {
      lat: latitude,
      lng: longitude,
    },
    zoom: 15,
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box class="bg-slate-200">
        <NavBar totalOpenTickets={openTickets} />
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <PPNavigation />
          <Box
            paddingTop={2}
            sx={{
              flex: { sx: 4, sm: 4, md: 4, lg: 5 },
              backgroundColor: "white",
              p: { sx: 1, sm: 1, md: 1, lg: 2 },
            }}
          >
            <Box class="mb-6">
            <Stack direction="row" spacing={2} justifyContent="space-between" >
              <Typography variant="h5"
                class="text-xl pt-4 text-blue-900 font-bold">
                {t("Completed Task")}
              </Typography>
              <Typography>
              <Fab
                      color="error"
                      title={t("download-pdf")}
                      onClick={handlePrint}
                    >
                      <PictureAsPdf />
                    </Fab>
              </Typography>
            </Stack>
            </Box>

            <Box ref={componentRef} sx={{borderColor: 'grey.300', border: 1, padding:'20px'}}>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Typography variant="h5"
                  class="text-xl pt-4 text-blue-900 font-bold">
                  {t("Job Description")}
                </Typography>
                <Typography>
                  <img width='200px' src="/assets/logos/logo_company_dark.png" alt="logo" />
                </Typography>
              </Stack>
              <Box class="mt-4">
                <Stack direction="row" spacing={2} justifyContent="space-between">
                <Stack direction="row" spacing={2} justifyContent="space-between">
                  <Typography>
                    <Avatar
                      sx={{ width: 100, height: 100, margin: "auto" }}
                      src={
                        techProfileImg
                          ? `${HOST}${techProfileImg}?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`
                          : "https://images.pexels.com/photos/3862607/pexels-photo-3862607.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      }
                    />
                    </Typography>
                    <Stack>
                      <Item>
                        {taskDetail.staffName}
                      </Item>
                      <Item>
                        {taskDetail.created + " | America/New_York"}
                      </Item>
                    </Stack>
                  </Stack>
                    <Typography variant="h5"
                      class="text-xl pt-8 text-blue-900 font-bold">
                      {t("Ticket")} : {taskDetail.ticketNum}
                    </Typography>
                    <Typography variant="h5"
                      class="text-xl pt-8 text-blue-900 font-bold">
                      {t("Task")} : {taskNumber}
                    </Typography>
                </Stack>
                </Box>
                <Box sx={{justifyContent: 'center', display: 'flex', paddingLeft: '120px'}}>
                  <Item>

                      <img src="/assets/img_task_report.png" width="85%" alt="logo" />
                  </Item>

                </Box>
              <Box class="bg-slate-50 p-2 mt-4 rounded-lg">
                <Typography
                  variant="h5"
                  class="text-xl pt-4 text-blue-900 font-bold"
                >
                  {taskDetail.title}
                </Typography>
              </Box>
              <Divider />
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                className="mt-4"
              >
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>
                          {t("User")}
                        </TableCell>
                        <TableCell align="right">{ticketData.ticketUser}</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>
                          {t("Status")}
                        </TableCell>
                        <TableCell align="right">{taskDetail.status}</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>
                          {t("Staff Name")}
                        </TableCell>
                        <TableCell align="right">
                          {taskDetail.staffName}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>
                          {t("Department")}
                        </TableCell>
                        <TableCell align="right">
                          {taskDetail.department}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>
                          {t("Total Hours")}
                        </TableCell>
                        <TableCell align="right">
                        <p><b>Total Remote:</b> {totalTimeRemote}</p>
                          <p><b>Total OnSite:</b> {totalTimeOnSite}</p>
                          <p><b>Total:</b> {totalTime}</p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>
                          {t("Organization")}
                        </TableCell>
                        <TableCell align="right">{ticketData.ticketUserOrg}</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>
                          {t("Created")}
                        </TableCell>
                        <TableCell align="right">{taskDetail.created}  | America/New_York</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>
                          {t("Closed")}
                        </TableCell>
                        <TableCell align="right">
                          {taskDetail.closed
                            ? taskDetail.closed
                            : taskDetail.updated}  | America/New_York
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>
                          {t("Total Visits")}
                        </TableCell>
                        <TableCell align="right">
                        {numReports}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>
                          {t("Objetives")}
                        </TableCell>
                        <TableCell align="right">
                          {taskDetail.objetives}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
          
                {taskDetail.report
                  ? taskDetail.report.forEach((register) => {
                    if (register.images.length > 0) {
                      register.images.forEach((url) => {
                        let imgExt = url.slice(-3);
                        if(imgExt == "pdf"){
                          pdfFiles.push( {"url" : `${APP_FRONTEND_HOST}${url}` , "name" : url.slice(7)});
                        } else {
                          galleryImages.push({
                            img: `${APP_FRONTEND_HOST}${url}?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`,
                          });
                        }
                      });
                    }
                  })
                  : ""}
                {galleryImages.length > 0 && (
                  <Box class="bg-slate-50 p-2 mt-4 rounded-lg">
                    <Typography
                      variant="h5"
                      class="text-xl pt-4 text-blue-900 font-bold"
                    >
                      {t("Task Images")}
                    </Typography>
                    <Typography
                      class="text-slate-500 mb-4 text-sm"
                      sx={{
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "none",
                          lg: "block",
                        },
                      }}
                    >
                      {t("Task Images Visits")}
                    </Typography>
                    <MafiImageGallery galleryImages={galleryImages} />
                  </Box>
                )}

              {pdfFiles.length > 0 && (
                  <Box class="bg-slate-50 p-2 mt-4 rounded-lg">
                    <Typography
                      variant="h5"
                      class="text-xl pt-4 text-blue-900 font-bold"
                    >
                      {t("PDF Files")}
                    </Typography>
                    <Typography
                      class="text-slate-500 mb-4 text-sm"
                      sx={{
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "none",
                          lg: "block",
                        },
                      }}
                    >
                      {t("PDF File Visits")}
                    </Typography>
                    {pdfFiles.map((pdfF)=>{
                        return <Link  
                          href={pdfF.url}
                          underline="none" 
                          target="blank"> {pdfF.name}</Link>
                    })}
                    
                  </Box>
                )}

                {taskDetail.report && (
                  <>
                    <Box class="bg-slate-50 p-2 mt-4 rounded-lg">
                      <Typography
                        variant="h5"
                        class="text-xl pt-4 text-blue-900 font-bold"
                      >
                        Details Visits
                      </Typography>
                    </Box>
                    <Divider />
                    <TableContainer component={Paper} className="mt-4">
                      <Table aria-label="simple table">
                        <TableBody>
                          {taskDetail.report.map((register, index) => {
                            // Parse dates to America/New_York
                            let dateTempOpenVisit = Date.parse(register.createdAt);
                            dateTempOpenVisit = new Date(dateTempOpenVisit);
                            register.createdAt = dateTempOpenVisit.toLocaleString("en-US", {timeZone: "America/New_York"});

                            let dateCheckInTemp = new Date(register.checkIn);
                            dateCheckInTemp = new Date(dateCheckInTemp);
                            register.checkIn = dateCheckInTemp.toLocaleString("en-US", {timeZone: "America/New_York"});

                            let dateCheckIOutTemp = new Date(register.checkOut);
                            dateCheckIOutTemp = new Date(dateCheckIOutTemp);
                            register.checkOut = dateCheckIOutTemp.toLocaleString("en-US", {timeZone: "America/New_York"});


                            return (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                key={index}
                              >
                                <TableCell component="th" scope="row">
                                  <p className="text-sm pt-4 text-blue-900 font-bold">
                                    Visit No. {index + 1}
                                  </p><br></br>
                                 <b> {t("Created")}</b> {`: ${register.createdAt}`}
                                  <p>
                                  <b>{t("Report")} </b>{`: ${register.observation}`}
                                  </p>
                                  <p>
                                  <b>{t("Type")} </b>{`: ${register.taskType}`}
                                  </p>
                                </TableCell>
                                <TableCell align="right">
                                <br></br>
                                <p>
                                    <b>{t("Checkin")}</b> {`: ${register.checkIn}`}
                                  </p>
                                  <p>
                                    <b>{t("CheckOut")} </b> {`: ${register.checkOut}`}
                                  </p>
                              </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {taskDetail.report[0]?.sign && (
                      <>
                        <Box class="bg-slate-50 p-2 mt-4 rounded-lg">
                          <Typography
                            variant="h5"
                            class="text-xl pt-4 text-blue-900 font-bold"
                          >
                            Signature
                          </Typography>
                        </Box>
                        <Divider />
                        <Box class="bg-slate-50 p-2 mt-4 rounded-lg">
                          <Stack direction={'row'} spacing={2}>
                            <Box>
                            <Typography sx={{fontWeight: 'bold'}}>Customer</Typography>
                              <Card sx={{ maxWidth: 345 }}>
                                <CardActionArea>
                                  <Typography></Typography>
                                  <CardMedia
                                    component="img"
                                    height="140"
                                    image={`${APP_FRONTEND_HOST}${taskDetail.report[0].sign}`}
                                    alt="signature"
                                  />
                                </CardActionArea>
                              </Card>
                            </Box>
                            <Box>
                            <Typography sx={{fontWeight: 'bold'}}>Technician</Typography>
                              <Card sx={{ maxWidth: 345 }}>
                                <CardActionArea>
                                  <CardMedia
                                    component="img"
                                    height="140"
                                    image={`${APP_FRONTEND_HOST}${taskDetail.report[0].signTech}`}
                                    alt="signature"
                                  />
                                </CardActionArea>
                              </Card>
                            </Box>
                          </Stack>
                        </Box>
                      </>
                    )}
                  </>
                )}
                {taskDetail.report &&
                  taskDetail.report.length > 0 &&
                  taskDetail.report[0].location.lat && (
                    <>
                      <Box class="bg-slate-50 p-2 mt-4 rounded-lg">
                        <Typography
                          variant="h5"
                          class="text-xl pt-4 text-blue-900 font-bold"
                        >
                          Location
                        </Typography>
                      </Box>
                      <Divider />
                      <Box>
                        <div
                          style={{
                            padding: "10px 10px",
                            background: "white",
                            // position: "relative",
                            // top: "150px",
                            // bottom: "0",
                            // zIndex: "1000",
                            // width: "200px",
                            alignContent: "center",
                          }}
                        ><b>{taskAddress}</b></div>
                        <div style={{ height: "50vh", width: "100%" }}>
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: GOOGLE_MAP_KEY,
                            }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                          >
                            <AnyReactComponent
                              lat={latitude}
                              lng={longitude}
                              text="Customer Location"
                            />
                          </GoogleMapReact>
                        </div>
                      </Box>
                    </>
                  )}
            </Box>
          </Box>
          <RightBar />
        </Stack>
        <AddTooltip />
      </Box>
    </ThemeProvider>
  );
};

export default SaltexClosedTask;
