import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import Header from "../Tables/Header/Header";
import Items from "../Tables/Items/Items";

const DocumentGroup = ({ rows, startDate, endDate, tableHeader, title }) => {
  rows = rows ? rows : [];
  const styles = StyleSheet.create({
    text: {
      flexWrap: "wrap",
      textAlign: "justify",
      marginLeft: 12,
      marginRight: 12,
      lineHeight: 1.5,
      // fontFamily: "Times-Roman",
    },
    block: {
      marginBottom: 10,
    },
    textListItem: {
      textAlign: "justify",
      marginTop: 10,
      marginLeft: 12,
      marginRight: 12,
      lineHeight: 1.5,
    },

    pageNumbers: {
      position: "absolute",
      bottom: 40,
      left: 0,
      right: 0,
      textAlign: "center",
      fontSize: 12,
      color: "grey",
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderTopWidth: 0,
      marginLeft: 10,
      marginRight: 10,
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableCol: {
      width: "25%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColInt: {
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCell: {
      margin: "auto",
      marginTop: 5,
      fontSize: 10,
    },
    tableCellNested: {
      margin: "auto",
      fontSize: 10,
      width: "80%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCellNestedIndex: {
      margin: "auto",
      fontSize: 10,
      width: "20%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    image: {
      //   marginVertical: 10,
      //   marginHorizontal: 50,
      width: "30%",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  const tableColumns = {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
  };



  return (
    <Document>
      <Page
        style={{
          //   fontSize: 12,
          paddingTop: 35,
          paddingBottom: 65,
          paddingHorizontal: 35,
        }}
        wrap
      >
        <View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <Image
                style={styles.image}
                src="/assets/logos/logo_company_dark.png"
              />
            </View>
            <View style={{ flex: 1, textAlign: "right" }}>
              <Text style={{ fontSize: 12 }}>
                Date: {new Date().toISOString().split("T")[0]}
              </Text>
              <Text style={{ fontSize: 12 }}>
                Report from: {startDate.toISOString().split("T")[0]} to:{" "}
                {endDate.toISOString().split("T")[0]}
              </Text>
            </View>
          </View>
          {/* <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={{ flex: 1, textAlign: "right" }}>Left</Text>
            <Text style={{ flex: 1 }}>Right</Text>
          </View> */}
        </View>
        <View
          wrap
          // style={{ flexDirection: "row", justifyContent: "space-between" }}
        >
          <Text
            style={{
              fontFamily: "Helvetica-Bold",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            {title}
          </Text>
        </View>
        <View wrap style={styles.table}>
          <Header header={tableHeader}  tableCol={tableColumns}/>
          {rows.map((row) => {
            let ticketsOpened = 0;
            let ticketsClosed = 0;
            const {
              // _id: { name, _id: id },
              totalCount,
              status,
            } = row;

            if (status.length === 2) {
              ticketsOpened =
                status[0].description === "Open"
                  ? status[0].count
                  : status[1].count;
              ticketsClosed =
                status[1].description === "Closed"
                  ? status[1].count
                  : status[0].count;
            }

            if (status.length === 1) {
              ticketsOpened =
                status[0].description === "Open" ? status[0].count : 0;
              ticketsClosed =
                status[0].description === "Closed" ? status[0].count : 0;
            }

            return (
              <Items
                tableCol={tableColumns}
                items={[row._id?.name, ticketsOpened, ticketsClosed, totalCount]}
                key={row._id?._id}
              />
            );
          })}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default DocumentGroup;
