import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export const fetchUsers = async ({
  signal,
  startDate,
  endDate,
  customer,
  technician,
}) => {
  
  startDate = startDate.subtract(1, "day");
  let url = `${API_URL}reports/range/${startDate}/${endDate}`;

  if (customer && technician) {
    url = `${API_URL}reports/range/${startDate}/${endDate}?technician=${technician}&customer=${customer}`;
  }

  if (customer && !technician) {
    url = `${API_URL}reports/range/${startDate}/${endDate}?customer=${customer}`;
  }

  if (!customer && technician) {
    url = `${API_URL}reports/range/${startDate}/${endDate}?technician=${technician}`;
  }
  const res = await fetch(url, { signal: signal, technician: customer });
  // const res = await fetch(`http://localhost:5000/api/reports/range/2023-07-06/2023-08-30?customer=123`)
  const { tickets } = await res.json();
  
  return tickets;
};

export const getUserByRole = async ({ role }) => {
  const response = await axios.get(`${API_URL}reports/users`, {
    params: { role: role },
  });

  const {
    data: { data },
  } = response;

  return data;
};

export const getTechnicianTickets = async ({ startDate, endDate }) => {
  const response = await axios.get(
    `${API_URL}reports/group/${startDate}/${endDate}`
  );

  
  const {
    data: { data },
  } = response;

  return data;
};

export const getCustomerTickets = async ({ startDate, endDate }) => {
  const response = await axios.get(
    `${API_URL}reports/group-customers/${startDate}/${endDate}`
  );

  const {
    data: { data },
  } = response;

  return data;
};

export const getTasksByTickets = async ({ ticket }) => {
  const response = await axios.get(`${API_URL}tasks/${ticket}`);

  const {
    data: { Tasks },
  } = response;

  return Tasks;
};

export const getTaskByDate = async ({
  startDate,
  endDate,
  technician,
  signal,
}) => {
 
  let url = `${API_URL}reports/range-tasks/${startDate}/${endDate}`;

  if (technician) {
    url = `${API_URL}reports/range-tasks/${startDate}/${endDate}?technician=${technician}`;
  }
  const response = await axios.get(url);

 

  const {
    data: { tasks },
  } = response;

  
  return tasks;
};
