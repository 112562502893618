import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { visuallyHidden } from "@mui/utils";
import { Button, Stack, Fab } from "@mui/material";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import FileDownload from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom/dist";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { tableCellClasses } from "@mui/material/TableCell";
import { grey } from "@mui/material/colors";
import { useQuery } from "react-query";
import { fetchUsers, getTasksByTickets } from "../utils/fetchEvents";
import ReportFilter from "../main/reports/widgets/ReportsFilter";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import DocumentPDF from "../main/reports/Downloads/PDF/Document";
import DocumentTicket from "../main/reports/Downloads/PDF/DocumentTicket";
import { PDFViewer, Font } from "@react-pdf/renderer";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

// function createData(name, lastUpdate, subject, from, priority, assignedTo) {
//   return { name, lastUpdate, subject, from, priority, assignedTo };
// }

Font.registerHyphenationCallback((word) => {
  // Return entire word as unique part
  return [word];
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: grey[600],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function EnhancedTableHead(props) {
  const { t } = useTranslation();
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "ticket",
      numeric: false,
      disablePadding: false,
      label: "Ticket",
    },
    {
      id: "subject",
      numeric: true,
      disablePadding: false,
      label: t("Title"),
    },
    {
      id: "created",
      numeric: true,
      disablePadding: false,
      label: t("Created"),
    },
    {
      id: "dueDate",
      numeric: true,
      disablePadding: false,
      label: t("Due-Date"),
    },

    {
      id: "from",
      numeric: true,
      disablePadding: false,
      label: t("From"),
    },
    // {
    //   id: "priority",
    //   numeric: true,
    //   disablePadding: false,
    //   label: "Priority",
    // },
    {
      id: "assignedTo",
      numeric: true,
      disablePadding: false,
      label: t("Assigned-to"),
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Status",
    },
  ];

  return (
    <>
      <TableHead>
        <TableRow>
          {/* <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              // onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell> */}
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Tickets
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const MafiTableReports = (props) => {
  const [startDate, setStartDate] = React.useState(
    dayjs(new Date()).subtract(7, "days")
  );
  const [endDate, setEndDate] = React.useState(dayjs(new Date()));
  const [data2, setData] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("lastUpdate");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isSearch, setIsSearch] = React.useState(false);
  const [customer, setCustomer] = React.useState("");
  const [technician, setTechnician] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openTicket, setOpenTicket] = React.useState(false);
  const [ticketId, setTicketId] = React.useState("");
  const [ticketInfo, setTicketInfo] = React.useState({});
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onFilterChange = React.useCallback((filterModel) => {
    // Here you save the data you need from the filter model
    setStartDate(filterModel);
    setIsSearch(true);
  }, []);

  let { data, status, isLoading } = useQuery({
    queryKey: [
      "events",
      {
        startDate: startDate,
        endDate: endDate,
        customer: customer,
        technician: technician,
      },
    ],
    queryFn: ({ signal }) =>
      fetchUsers({
        signal,
        startDate: startDate,
        endDate: endDate,
        customer: customer,
        technician: technician,
      }),
    initialData: [],
  });

  let { data: dataTasks } = useQuery({
    queryKey: ["tasks", { ticket: ticketId }],
    queryFn: () => getTasksByTickets({ ticket: ticketId }),
    initialData: [],
    retry: 0,
  });

  let dataCsvReport = [];
  const headerCsvReport = [
    { label: "Ticket", key: "ticket" },
    { label: "Title", key: "title" },
    { label: "Created", key: "created" },
    { label: "Due Date", key: "dueDate" },
    { label: "From", key: "from" },
    { label: "Assigned To", key: "assigned" },
    { label: "Status", key: "status" },
  ];

  data.forEach((row) => {
    dataCsvReport.push({
      ticket: row.number,
      title: row.title,
      created: row.created,
      dueDate: row.dueDate,
      from: row.customerId?.name,
      assigned: row.staffId?.name,
      status: row.status,
    });
  });

  const rows = props.ticketData;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStartDate = (event) => {
    setStartDate(event);
  };

  const handleCustomerFilter = (customer) => {
    setCustomer(customer);
  };

  const handleTechnicianFilter = (technician) => {
    setTechnician(technician);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenTicket = () => {
    setOpenTicket(true);
  };

  const handleCloseTicket = () => {
    setOpenTicket(false);
  };

  const handleTasksByTicket = ({ ticket, tickedInfo }) => {
    setTicketId(ticket);
    setTicketInfo(tickedInfo);
    handleOpenTicket();
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <div style={{ paddingBottom: "10px" }}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ marginRight: "10px" }}
                label="Start Date"
                value={startDate}
                onChange={onFilterChange}
                format="LL"
              />

              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(endDate) => setEndDate(endDate)}
                minDate={dayjs(startDate)}
                format="LL"
              />
            </LocalizationProvider>
          </Box>
          <Box>
            <Stack direction="row" spacing={2}>
              <Fab color="error" title={t("download-pdf")} onClick={handleOpen}>
                <PictureAsPdf />
              </Fab>
              <Fab color="success" title={t("download-csv")}>
                <CSVLink
                  data={dataCsvReport}
                  headers={headerCsvReport}
                  filename={"tickets-general-report.csv"}
                >
                  <FileDownload />
                </CSVLink>
              </Fab>
            </Stack>
          </Box>
        </Stack>
        {/* <Button variant="outlined" onClick={handleOpen}>
          Open full-screen dialog
        </Button> */}
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative", backgroundColor: "#0e4089" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Preview
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                Close
              </Button>
            </Toolbar>
          </AppBar>
          {/* <List>
          <ListItem button>
            <ListItemText primary="Phone ringtone" secondary="Titania" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Default notification ringtone"
              secondary="Tethys"
            />
          </ListItem>
        </List> */}
          <Box sx={{ width: "100%" }}>
            <PDFViewer
              showToolbar={true}
              style={{
                position: "absolute",
                border: 0,
                height: "100%",
                width: "100%",
              }}
              height="600"
            >
              <DocumentPDF
                rows={data}
                startDate={startDate}
                endDate={endDate}
              />
            </PDFViewer>
          </Box>
        </Dialog>

        <Dialog
          fullScreen
          open={openTicket}
          onClose={handleCloseTicket}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative", backgroundColor: "#0e4089" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseTicket}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Preview
              </Typography>
              <Button autoFocus color="inherit" onClick={handleCloseTicket}>
                Close
              </Button>
            </Toolbar>
          </AppBar>
          <Box sx={{ width: "100%" }}>
            <PDFViewer
              showToolbar={true}
              style={{
                position: "absolute",
                border: 0,
                height: "100%",
                width: "100%",
              }}
              height="600"
            >
              <DocumentTicket tasks={dataTasks} infoTicket={ticketInfo} />
            </PDFViewer>
          </Box>
        </Dialog>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={8} md={6}>
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={onFilterChange}
              format="LL"
            />

            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(endDate) => setEndDate(endDate)}
              minDate={dayjs(startDate)}
              format="LL"
            />
          </LocalizationProvider> */}
        </Grid>
        <Grid item xs={4} md={6}>
          <ReportFilter
            onFilter={handleCustomerFilter}
            onFilterTechnician={handleTechnicianFilter}
          />
        </Grid>
      </Grid>

      {!isLoading && (
        <>
          {/* <Paper sx={{ width: "100%", mb: 2 }}> */}
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer
            component={Paper}
            sx={{ borderRadius: "10px", marginTop: "20px" }}
          >
            <Table aria-label="customized table" class="w-full">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {stableSort(data ? data : data2, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        hover
                        // onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell> */}
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="1px"
                        >
                          <Button
                            className="saltexColor"
                            onClick={() =>
                              handleTasksByTicket({
                                ticket: row.id,
                                tickedInfo: {
                                  number: row.number,
                                  title: row.title,
                                  created: row.created,
                                  customer: row.customerId.name,
                                  status: row.status,
                                  technician: row.staffId.name,
                                },
                              })
                            }
                          >
                            {row.number}
                          </Button>
                        </TableCell>
                        <StyledTableCell align="right">
                          {row.title}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.created.split("T")[0]}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.dueDate.split("T")[0]}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.customerId?.name}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.staffId?.name}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.status}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("Rows-per-page")}
            />
          </TableContainer>

          {/* </Paper> */}
        </>
      )}
    </Box>
  );
};

export default MafiTableReports;
