import { useState } from "react";
import {
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";


// Font.registerHyphenationCallback((word) => {
//   // Return entire word as unique part
//   return [word];
// });

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: grey[600],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


const handleEdit = () => { };

const MafiTableGroup = ({ technicians, header, startDate, endDate }) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - technicians.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ borderRadius: "10px", marginTop: "20px" }}
      >
        <Table aria-label="customized table" class="w-full">
          <TableHead>
            <TableRow>
              {header.map((field) => (
                <StyledTableCell align={field.align} key={field.id}>
                  {t(field.name)}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? technicians.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
              : technicians
            ).map((row) => {
              let ticketsOpened = 0;
              let ticketsClosed = 0;
              const {
                // _id: { name, _id: id },
                totalCount,
                status,
              } = row;

              if (status.length === 2) {
                ticketsOpened =
                  status[0].description === "Open"
                    ? status[0].count
                    : status[1].count;
                ticketsClosed =
                  status[1].description === "Closed"
                    ? status[1].count
                    : status[0].count;
              }

              if (status.length === 1) {
                ticketsOpened =
                  status[0].description === "Open" ? status[0].count : 0;
                ticketsClosed =
                  status[0].description === "Closed" ? status[0].count : 0;
              }
              return (
                <StyledTableRow key={row._id?._id}>
                  {/* {
                    organization != ''
                      ? <StyledTableCell align="right" >
                        {organization}
                      </StyledTableCell>
                      : <StyledTableCell align="right" sx={{ backgroundColor: 'pink' }} >
                        {organization}
                      </StyledTableCell>
                  } */}

                  <StyledTableCell align="left">{row._id?.name}</StyledTableCell>
                  <StyledTableCell align="right">
                    {ticketsOpened}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {ticketsClosed}
                  </StyledTableCell>
                  <StyledTableCell align="right">{totalCount}</StyledTableCell>

                  {/* {
                    status != 'Active'
                      ? <StyledTableCell align="right" sx={{ backgroundColor: 'pink' }}>{status}</StyledTableCell>
                      : <StyledTableCell align="right">{status}</StyledTableCell>
                  } */}
                </StyledTableRow>
              );
            })}
            {emptyRows > 0 && (
              <tr style={{ height: 34 * emptyRows }}>
                <td colSpan={3} />
              </tr>
            )}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage={t("Rows-per-page")}
          rowsPerPageOptions={[10, 15, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={technicians.length}
          rowsPerPage={rowsPerPage}
          page={page}
          slotProps={{
            select: {
              "aria-label": t("Rows-per-page"),
            },
            actions: {
              showFirstButton: true,
              showLastButton: true,
            },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}

        />
      </TableContainer>
    </>
  );
};

export default MafiTableGroup;
