import { Navigate } from "react-router-dom";
const ProtectedRoute = ({ children }) => {
  const users = JSON.parse(localStorage.getItem("user"));
  if (!users) {
    return <Navigate to="/" replace={false} />;
  }

  return children;
};

export default ProtectedRoute;
