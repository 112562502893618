import styled from "@emotion/styled";
import { Alert, Box, Button, Divider, FormControl, FormHelperText, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next';
import { MuiTelInput } from 'mui-tel-input'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const API_URL = process.env.REACT_APP_API_URL

const OrganizationAddForm = (props) => {
    const [message, setMessage] = React.useState('');
    const [orgName, setOrgName] = React.useState('');
    const [orgStatus, setOrgStatus] = React.useState('Active');
    const [orgAddress, setOrgAddress] = React.useState('');
    const [orgPhone, setOrgPhone] = React.useState('');
    const [orgParent, setOrgParent] = React.useState('Saltex Group');
    const { t } = useTranslation();


    const handleSubmitTicket = async (e) => {
        e.preventDefault();
        const orgData = {
            name: orgName,
            status: orgStatus,
            address: orgAddress,
            phone: orgPhone,
            orgParent: orgParent
        }

        try {
            const response = await fetch(API_URL + 'organizations', {
                method: "Post",
                headers: {
                    'Content-Type': 'Application/json'
                },
                body: JSON.stringify(orgData)
            });

            const responseData = await response.json();

            setOrgAddress('');
            setOrgName('');
            setOrgParent('Saltex Group');
            setOrgPhone('');
            setOrgStatus('Active');
            props.addOganizationListHandler(orgData);
            setMessage(<Alert severity="success">{t('Organization-created successfully')}</Alert>);
            setTimeout(function () { setMessage(''); hiddeExpPopUp(); }, 5000);

        } catch (err) {
            setMessage(<Alert severity="error">{t('error-contact-support')}</Alert>);
            setTimeout(function () { setMessage(''); }, 5000);
            console.log('Something went wrong, please try again.');
        }
    }

    const hiddeExpPopUp = () => {
        props.onCalcelSaveExp();
    }

    const handleOrgName = (event) => {
        const orgName = event.target.value;
        /// console.log(userNameText);
        setOrgName(orgName);
    };

    const handleOrgStatus = (event) => {
        const orgStatus = event.target.value;
        /// console.log(userNameText);
        setOrgStatus(orgStatus);
    };

    const handleOrgAddress = (event) => {
        const orgAddress = event.target.value;
        /// console.log(userNameText);
        setOrgAddress(orgAddress);
    };

    const handleOrgParent = (event) => {
        const orgParent = event.target.value;
        /// console.log(userNameText);
        setOrgParent(orgParent);
    };

    const handleOrgPhone = (event) => {
        // const orgPhone = event.target.value;
        const orgPhone = event;
        /// console.log(userNameText);
        setOrgPhone(orgPhone);
    };

    return (
        <Box sx={{ width: '93%', m: 5 }}>
            {message}
            <Item>
                <form onSubmit={handleSubmitTicket}>
                    <Typography className="text-lg font-bold leading-none">{t('add-new-organization')}</Typography>
                    <div>
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" divider={<Divider orientation="vertical" flexItem />} >
                            <div className="flex sm:space-x-24 my-6" >
                                <TextField
                                    id="name"
                                    label={t('Name')}
                                    className="flex-auto"
                                    onChange={(e) => handleOrgName(e)}
                                    value={orgName}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    required
                                />
                            </div>
                            <div className="flex sm:space-x-24 pt-6" >
                                {/* <TextField
                                    id="Phone"
                                    label={t('Phone')}
                                    className="flex-auto"
                                    onChange={(e) => handleOrgPhone(e)}
                                    value={orgPhone}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    required
                                /> */}
                                <MuiTelInput
                                    value={orgPhone}
                                    id="Phone"
                                    label={t('Phone')}
                                    className="flex-auto"
                                    onChange={handleOrgPhone}
                                    defaultCountry="US"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    required
                                    inputProps={{ maxLength: 20 }}
                                />
                            </div>
                            <div className="flex sm:space-x-24 mb-6">
                                <FormControl >
                                    <FormHelperText>{t("org-parent")}:</FormHelperText>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={orgParent}
                                        label="Ticket Source"
                                        onChange={handleOrgParent}
                                    >
                                        {props.orgData.map((row) => (
                                            <MenuItem value={row.name}>{row.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="flex sm:space-x-24" >
                                <FormControl >
                                    <FormHelperText>Status:</FormHelperText>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={orgStatus}
                                        label="Ticket Source"
                                        onChange={handleOrgStatus}
                                    >
                                        <MenuItem value="Active">Active</MenuItem>
                                        <MenuItem value="Inactive">Inactive</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </Stack>
                        <div className="flex sm:space-x-24 my-6" >
                            <TextField
                                id="address"
                                label={t('Address')}
                                className="flex-auto"
                                onChange={(e) => handleOrgAddress(e)}
                                value={orgAddress}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </div>
                        <div className="flex sm:space-x-24 mb-16">
                            <Button
                                variant="outlined"
                                color="error"
                                className=" w-1/2 mt-16"
                                aria-label="Add"
                                type="button"
                                onClick={hiddeExpPopUp}
                                size="large"
                            >
                                {t('Cancel')}
                            </Button>
                            <Button
                                variant="outlined"
                                color="success"
                                className=" w-1/2 mt-16"
                                aria-label="Add"
                                type="submit"
                                size="large"
                            >
                                {/* {t('Add Organization')} */}
                                {t('add-new-organization')}
                            </Button>
                        </div>
                    </div>
                </form>
            </Item>
        </Box>
    );
};

export default OrganizationAddForm;