import styled from "@emotion/styled";
import { Alert, Box, Button, Divider, Paper, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next';
import PasswordIcon from '@mui/icons-material/Password';
import FaceIcon from '@mui/icons-material/Face';
import { MuiTelInput } from 'mui-tel-input'
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const API_URL = process.env.REACT_APP_API_URL

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const currentPage = window.location.href.split('/')[3];
if (currentPage == 'NewUser') {
    localStorage.removeItem('user');
    localStorage.removeItem('organizations');
}

const UserAddFormNew = (props) => {
    const navigate = useNavigate();
    const [message, setMessage] = React.useState('');
    const [userName, setUserName] = React.useState('');
    const [userEmail, setUserEmail] = React.useState('');
    const [userStatus, setuserStatus] = React.useState('Active');
    const [userPhone, setUserPhone] = React.useState('');
    const [userMPhone, setUserMPhone] = React.useState('');
    const [userOrganization, setUserOrganization] = React.useState('Saltex Group');
    const [userDepartment, setUserDepartment] = React.useState('Support');
    const [userRole, setUserRole] = React.useState('Admin');
    const [userPasswd1, setUserPasswd1] = React.useState('');
    const [userPasswd2, setUserPasswd2] = React.useState('');
    const [messagePasswd, setMessagePasswd] = React.useState('');
    const [addButtonDisabled, setaddButtonDisabled] = React.useState(false);
    const [userAddress, setUserAddress] = React.useState("");
    const [userCity, setUserCity] = React.useState("");
  const [userCountry, setUserCountry] = React.useState("");
    const { t } = useTranslation();

    const handleSubmitUser = async (e) => {
        e.preventDefault();
        let userAddedData;
        setaddButtonDisabled(true);
        if (userPasswd1 !== userPasswd2) {
            setMessagePasswd(<Alert severity="error">{t('Passwords should be the same')}</Alert>);
            setTimeout(function () { setMessagePasswd(''); }, 5000);
            setUserPasswd1('');
            setUserPasswd2('');
            setaddButtonDisabled(false);
        } else {
            const userData = {
                department: "Is Customer",
                organization: "",
                role: "Customer",
                name: userName,
                passwd: userPasswd1,
                email: userEmail.toLowerCase(),
                address: userAddress,
                phone: userPhone,
                mobile: userMPhone,
                city: userCity,
                country: userCountry,
                status: "InActive",
                timezone: timezone
            }
            try {
                fetch(API_URL + 'users/signup', {
                    method: "Post",
                    headers: {
                        'Content-Type': 'Application/json'
                    },
                    body: JSON.stringify(userData)
                }).then((responseSignUp) => {
                    if (responseSignUp.ok) {
                        return responseSignUp.json();
                    } else {
                        setUserName('');
                        setUserPasswd1('');
                        setUserPasswd2('');
                        setUserEmail('');
                        setUserMPhone('');
                        setUserPhone('');
                        setUserAddress("");
                        setUserCity("");
                        setUserCountry("");
                        setMessage(<Alert severity="error">{t('Email-is-already-in-use')}</Alert>);
                        setTimeout(function () { setMessage(''); window.location = 'https://saltexpartnerportal.vieratech.ai/NewUser'; }, 3000);
                    }
                }).then((responseData) => {

                    // console.log(JSON.stringify({ userData }));

                    const dataEmail = {
                        userEmail: responseData.user.email,
                        userId: responseData.user.id
                    }

                    fetch(API_URL + 'emails/activate', {
                        method: "Post",
                        headers: {
                            'Content-Type': 'Application/json'
                        },
                        body: JSON.stringify(dataEmail)
                    }).then((emailResponse) => {
                        setUserName('');
                        setUserPasswd1('');
                        setUserPasswd2('');
                        setUserEmail('');
                        setUserMPhone('');
                        setUserPhone('');
                        setUserAddress("");
                        setUserCity("");
                        setUserCountry("");
                        setMessage(<Alert severity="success">{t('User created successfully, Please review your email to active your account')}</Alert>);
                        setTimeout(function () { setMessage(''); window.location = 'http://www.saltexgroup.com'; }, 7000);
                    });
                });

            } catch (err) {
                setMessage(<Alert severity="error">{t('error-contact-support')}</Alert>);
                setTimeout(function () { setMessage(''); }, 7000);
            }
        }
    }

    const handleBackButton = () => {
        navigate(-1);
    };
    const handleUserName = (event) => {
        const userName = event.target.value;
        /// console.log(userNameText);
        setUserName(userName);
    };

    const handleUserEmail = (event) => {
        const userEmail = event.target.value;
        /// console.log(userNameText);
        setUserEmail(userEmail);
    };

    const handleUserPhone = (event) => {
        const userPhone = event;
        setUserPhone(userPhone);
    };

    const handleUserMPhone = (event) => {
        setUserMPhone(event);
    };

    const handleUserPasswd1 = (event) => {
        const userPasswd1 = event.target.value;
        // console.log(groupNameText);
        setUserPasswd1(userPasswd1);
    };

    const handleUserPasswd2 = (event) => {
        const userPasswd2 = event.target.value;
        // console.log(groupNameText);
        setUserPasswd2(userPasswd2);
    };

    const handleUserAddress = (event) => {
        setUserAddress(event.target.value);
    };

    const handleUserCountry = (event) => {
        setUserCountry(event.target.value);
      };
    
      const handleUserCity = (event) => {
        setUserCity(event.target.value);
      }


    return (
        <Box sx={{ width: '93%', m: 5, opacity: [0.8, 0.8, 0.8], borderRadius: '10px' }}>
            {message} {messagePasswd}
            <Item>
                <form onSubmit={handleSubmitUser}>
                    <Typography className="text-slate-700 text-xl">{t('New-User')}</Typography>
                    <div>
                        <div className="flex sm:space-x-24 my-6" >
                            <FaceIcon />
                            <TextField
                                id="name"
                                label={t('Name')}
                                className="flex-auto"
                                onChange={(e) => handleUserName(e)}
                                value={userName}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </div>
                        <div className="flex sm:space-x-24 pt-4 mb-4">
                            <PasswordIcon />
                            <TextField
                                id="userPasswd1"
                                label={t('Password')}
                                className="flex-auto"
                                type='password'
                                onChange={(e) => handleUserPasswd1(e)}
                                value={userPasswd1}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                required
                                fullWidth
                                inputProps={{ minLength: 8 }}
                            />
                            <PasswordIcon />
                            <TextField
                                id="userPasswd2"
                                label={t('Confirm-Password')}
                                className="flex-auto"
                                type='password'
                                onChange={(e) => handleUserPasswd2(e)}
                                value={userPasswd2}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                required
                                fullWidth
                                inputProps={{ minLength: 8 }}
                            />
                        </div>
                        
                            <div className="flex sm:space-x-24 pt-6" >
                                <TextField
                                    id="Address"
                                    label={t("address")}
                                    className="flex-auto"
                                    onChange={(e) => handleUserAddress(e)}
                                    value={userAddress}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    required
                                />
                                <TextField
                                    id="City"
                                    label={t("City")}
                                    className="flex-auto"
                                    onChange={(e) => handleUserCity(e)}
                                    value={userCity}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    variant="outlined"
                                    required
                                />

                                <TextField
                                    id="Country"
                                    label={t("Country")}
                                    className="flex-auto"
                                    onChange={(e) => handleUserCountry(e)}
                                    value={userCountry}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    variant="outlined"
                                    required
                                />
                            </div>
                            <div className="flex sm:space-x-24 pt-6" >
                                <TextField
                                    id="Email"
                                    type="email"
                                    required
                                    label={t('Email')}
                                    className="flex-auto"
                                    onChange={(e) => handleUserEmail(e)}
                                    value={userEmail}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                                <Divider orientation="vertical" flexItem />
                                <MuiTelInput
                                    value={userPhone}
                                    id="Phone"
                                    label={t('Phone')}
                                    className="flex-auto"
                                    onChange={handleUserPhone}
                                    defaultCountry="US"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    required
                                    inputProps={{ maxLength: 20 }}
                                    fullWidth
                                />
                                <Divider orientation="vertical" flexItem />
                                <MuiTelInput
                                    id="mobilePhone"
                                    label={t('Mobile-Phone')}
                                    className="flex-auto"
                                    onChange={handleUserMPhone}
                                    defaultCountry="US"
                                    value={userMPhone}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    required
                                    inputProps={{ maxLength: 20 }}
                                    fullWidth
                                />
                            </div>
                        <div className="flex sm:space-x-24 mt-8 mb-8">
                            <Button
                                variant="outlined"
                                color="warning"
                                className=" w-1/2 mt-16"
                                aria-label="Back"
                                onClick={handleBackButton}
                                sx={{ width: '100%' }}
                            >
                                {t('Back')}
                            </Button>
                            <Button
                                variant="outlined"
                                color="success"
                                className=" w-1/2 mt-16"
                                aria-label="Add"
                                type="submit"
                                sx={{ width: '100%' }}
                                disabled={addButtonDisabled}
                            >
                                {t('Add')} {t('User')}
                            </Button>
                        </div>
                    </div>
                </form>
            </Item>
        </Box>
    );
};

export default UserAddFormNew;