import { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import React from "react";
import DashboardHeader from "./DashboardHeader";
import DashTicketsByDay from "./widgets/DashTicketsByDay";
import DashOpenTickets from "./widgets/DashOpenTickets";
import DashOpenTasks from "./widgets/DashOpenTasks";
import DashOverdueTickets from "./widgets/DashOverdueTickets";
import DashStatistics from "./widgets/DashStatistics";
import DashTaskDistribution from "./widgets/DashTaskDistribution";
import DashTicketStatistics from "./widgets/DashTicketStatistics";
import DashTicketStatisticsCustomer from "./widgets/DashTicketStatisticsCustomer";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const Dashboard = () => {
  const [ticketsByDay, setTicketsByDay] = useState([]);
  const [ticketsOverdue, setTicketsOverdue] = useState([]);
  const [ticketsOpen, setTicketsOpen] = useState([]);
  const [totalOverdue, setTotalOverdue] = useState(0);
  const [totalOpen, setTotalOpen] = useState(0);
  const [totalClosed, setTotalClosed] = useState(0);
  const [totalOpenTasks, setTotalOpenTasks] = useState(0);
  const [totalClosedTasks, setTotalClosedTasks] = useState(0);
  const [tasksByDay, setTasksByDay] = useState([]);
  const [tasksOpenHistorical, setTasksOpenHistorical] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));

  let daysNumber = 30;
  let today = new Date();
  let last30Days = new Date(new Date().setDate(today.getDate() - daysNumber));

  useEffect(() => {
    const getTickets = async () => {
      const response = await axios.get(
        `${API_URL}tickets/historical/${last30Days}/${today}`,
        {
          params: {
            role: user.role,
            id: user._id,
          },
        }
      );


      setTicketsByDay(response.data.ticket);
      setTicketsOverdue(response.data.overdue);
      setTicketsOpen(response.data.open);

      const sumOverdue = response.data.overdue.reduce(
        (total, register) => register.count + total,
        0
      );
      const sumOpen = response.data.open.reduce(
        (total, register) => register.count + total,
        0
      );

      const sumClosed = response.data.closed.reduce(
        (total, register) => register.count + total,
        0
      );
      setTotalOverdue(sumOverdue);
      setTotalOpen(sumOpen);
      setTotalClosed(sumClosed);
    };

    getTickets();
  }, [user._id, user.role]);

  useEffect(() => {
    const getTaks = async () => {
      const response = await axios.get(
        `${API_URL}tasks/historical/${last30Days}/${today}`,
        {
          params: {
            role: user.role,
            id: user._id,
          },
        }
      );


      if (user.role === "Admin") {
        setTasksByDay(response.data.tasks);
      }

      setTotalOpenTasks(response.data.open[0].count);
      setTasksOpenHistorical(response.data.historical);
      setTotalClosedTasks(response.data.closed[0].count);
    };

    if (user.role === "Admin" || user.role === "Technician") {
      getTaks();
    }
  }, []);
  return (
    <Box
      paddingTop={2}
      sx={{
        flex: { sx: 4, sm: 4, md: 4, lg: 5 },
        backgroundColor: "white",
        p: { sx: 1, sm: 1, md: 1, lg: 2 },
      }}
    >
      <DashboardHeader />
      <DashTicketsByDay ticketsByDay={ticketsByDay} />
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        marginTop={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <DashOpenTickets open={ticketsOpen} />

        <DashOverdueTickets overdue={ticketsOverdue} />

        {user.role != "Customer" && (
          <DashOpenTasks
            totalOpenTasks={totalOpenTasks}
            historical={tasksOpenHistorical}
          />
        )}
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="center">
        {user.role != "Customer" && (
          <>
            <DashTaskDistribution
              tasksByDay={tasksByDay}
              completedTasks={totalClosedTasks}
              newTasks={totalOpenTasks}
            />

            <DashTicketStatistics
              totalOverdue={totalOverdue}
              totalOpen={totalOpen}
              totalClosed={totalClosed}
            />
          </>
        )}
        {user.role === "Customer" && (
          <DashTicketStatisticsCustomer
            totalOverdue={totalOverdue}
            totalOpen={totalOpen}
            totalClosed={totalClosed}
          />
        )}
      </Stack>
    </Box>
  );
};

export default Dashboard;
