import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PasswordIcon from "@mui/icons-material/Password";
import FaceIcon from "@mui/icons-material/Face";
import MafiProfileImage from "../../@mafi/MafiProfileImage";
import axios from "axios";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const API_URL = process.env.REACT_APP_API_URL;
let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

let user = JSON.parse(localStorage.getItem("user"));

const ProfileMain = (props) => {
  const [message, setMessage] = React.useState("");
  const [userData, setUserData] = React.useState([]);
  const [userName, setUserName] = React.useState("");
  const [userID, setUserID] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");
  const [userStatus, setuserStatus] = React.useState("");
  const [userPhone, setUserPhone] = React.useState("");
  const [userMPhone, setUserMPhone] = React.useState("");
  const [orgData, setOrgData] = React.useState([]);
  const [userOrganization, setUserOrganization] = React.useState("");
  const [userDepartment, setUserDepartment] = React.useState("Support");
  const [userRole, setUserRole] = React.useState("Admin");
  const [userPasswd1, setUserPasswd1] = React.useState("");
  const [userPasswd2, setUserPasswd2] = React.useState("");
  const [messagePasswd, setMessagePasswd] = React.useState("");
  const [depDisabled, setDepDisabled] = React.useState(true);
  const [orgDisabled, setOrgDisabled] = React.useState(true);
  const [roleDisabled, setRoleDisabled] = React.useState(true);
  const [userAddress, setUserAddress] = React.useState("");
  const [file, setFile] = React.useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const getOrganization = async () => {
      const response = await axios.get(`${API_URL}organizations`);
      setOrgData(response.data.organizations);
      const user = JSON.parse(localStorage.getItem("user"));
      setUserData(user);
      setUserName(user.name);
      setUserEmail(user.email);
      setuserStatus(user.status);
      setUserPhone(user.phone);
      setUserMPhone(user.mobile);
      setUserRole(user.role);
      setUserAddress(user.address);
      setUserOrganization(user.organization);
      setUserDepartment(user.department);
      setUserID(user._id);
      if (user.organization == "Saltex Group") {
        setOrgDisabled(false);
        setDepDisabled(false);
        setRoleDisabled(false);
      }
    };
    getOrganization();
  }, []);

  const handleSubmitUser = async (e) => {
    e.preventDefault();
    if (userPasswd1 == "" && userPasswd2 == "") {
      const userData = {
        department: userDepartment,
        organization: userOrganization,
        role: userRole,
        name: userName,
        email: userEmail,
        address: userAddress,
        phone: userPhone,
        mobile: userMPhone,
        status: userStatus,
        id: userID,
        passwd: "no_change",
      };
      try {
        const response = await axios.patch(`${API_URL}users/edit`, userData);
        setUserPasswd1("");
        setUserPasswd2("");
        delete response.data.user.passwd;
        localStorage.setItem("user", JSON.stringify(response.data.user));
        setMessage(
          <Alert severity="success">{t("user-updated-successfully")}</Alert>
        );

        setTimeout(function () {
          setMessage("");
        }, 5000);
      } catch (err) {
        setMessage(
          <Alert severity="error">
            {t("error-contact-support")}
          </Alert>
        );
        setTimeout(function () {
          setMessage("");
        }, 5000);
      }
    } else if (userPasswd1 !== userPasswd2) {
      setMessagePasswd(
        <Alert severity="error">{t("Passwords should be the same")}</Alert>
      );
      setTimeout(function () {
        setMessagePasswd("");
      }, 5000);
      setUserPasswd1("");
      setUserPasswd2("");
    } else {
      const userData = {
        department: userDepartment,
        organization: userOrganization,
        role: userRole,
        name: userName,
        passwd: userPasswd1,
        email: userEmail,
        address: userAddress,
        phone: userPhone,
        mobile: userMPhone,
        status: userStatus,
        id: userID,
      };
      // console.log(JSON.stringify({ userData }));
      try {
        const response = await axios.patch(`${API_URL}users/edit`, userData);
        setUserPasswd1("");
        setUserPasswd2("");
        delete response.data.user.passwd;
        localStorage.setItem("user", JSON.stringify(response.data.user));
        setMessage(
          <Alert severity="success">{t("user-updated-successfully")}</Alert>
        );

        setTimeout(function () {
          setMessage("");
        }, 5000);
      } catch (err) {
        setMessage(
          <Alert severity="error">
            {t("error-contact-support")}
          </Alert>
        );
        setTimeout(function () {
          setMessage("");
        }, 5000);
      }
    }
  };

  const hiddeExpPopUp = () => {
    props.onCalcelSaveExp();
  };

  const handleUserName = (event) => {
    const userName = event.target.value;
    /// console.log(userNameText);
    setUserName(userName);
  };

  const handleUserEmail = (event) => {
    const userEmail = event.target.value;
    /// console.log(userNameText);
    setUserEmail(userEmail);
  };

  const handleUserPhone = (event) => {
    const userPhone = event.target.value;
    /// console.log(userNameText);
    setUserPhone(userPhone);
  };

  const handleUserMPhone = (event) => {
    const userMPhone = event.target.value;
    /// console.log(userNameText);
    setUserMPhone(userMPhone);
  };

  const handleUserOrganization = (event) => {
    const userOrganization = event.target.value;
    if (userOrganization !== "Saltex Group") {
      setUserRole("Customer");
      setUserDepartment("Is Customer");
      setDepDisabled(true);
      setRoleDisabled(true);
    } else {
      setUserRole("Technician");
      setUserDepartment("Support");
      setDepDisabled(false);
      setRoleDisabled(false);
    }
    /// console.log(userNameText);
    setUserOrganization(userOrganization);
  };

  const handleUserDepartment = (event) => {
    const userDepartment = event.target.value;
    if (userDepartment === "Is Customer") {
      setUserRole("Customer");
    } else {
      setUserRole("Technician");
    }
    /// console.log(userNameText);
    setUserDepartment(userDepartment);
  };

  const handleUserRole = (event) => {
    const userRole = event.target.value;
    if (userRole === "Customer") {
      setUserDepartment("Is Customer");
    } else {
      setUserDepartment("Support");
    }
    /// console.log(userNameText);
    setUserRole(userRole);
  };

  const handleUserStatus = (event) => {
    const userStatus = event.target.value;
    /// console.log(userNameText);
    setuserStatus(userStatus);
  };

  const handleUserPasswd1 = (event) => {
    const userPasswd1 = event.target.value;
    // console.log(groupNameText);
    setUserPasswd1(userPasswd1);
  };

  const handleUserPasswd2 = (event) => {
    const userPasswd2 = event.target.value;
    // console.log(groupNameText);
    setUserPasswd2(userPasswd2);
  };

  const handleUserAddress = (event) => {
    setUserAddress(event.target.value);
  };

  const handleFiles = async (data) => {
    setFile(data[0]);
  };

  const handleSaveImage = async () => {
    let formdata = new FormData();
    formdata.append("userId", userID);
    formdata.append("imageProfile", file);

    try {
      const response = await axios.post(
        `${API_URL}users/change-photo`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const user = JSON.parse(localStorage.getItem("user"));

      user.profileImage = response.data.data.profileImage;

      localStorage.setItem("user", JSON.stringify(user));

      navigate("/profile-update");
    } catch (error) {
      setMessage(
        <Alert severity="error">
          {t("Some error occurred, Contact Tech Support")}
        </Alert>
      );
      setTimeout(function () {
        setMessage("");
      }, 5000);
    }

    // const response = await fetch(`${API_URL}users/change-photo`, {
    //   method: "POST",
    //   body: formdata,
    // });

    // console.log(response);
  };

  return (
    <Box sx={{ width: "93%", m: 5 }}>
      {message} {messagePasswd}
      <Item>
        <form onSubmit={handleSubmitUser}>
          <Typography className="text-lg font-bold leading-none">
            {t("Edit-User")}
          </Typography>
          <div>
            <div className="flex sm:space-x-24 my-6">
              <FaceIcon />
              <div>
                <MafiProfileImage onDropdown={handleFiles} />
                {file && (
                  <Button
                    variant="outlined"
                    color="success"
                    type="button"
                    onClick={handleSaveImage}
                  >
                    Save
                  </Button>
                )}
              </div>

              <TextField
                id="name"
                label={t("Name")}
                className="flex-auto"
                onChange={(e) => handleUserName(e)}
                value={userName}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
                fullWidth
              />
            </div>
            <div className="flex sm:space-x-24 pt-4 mb-4">
              <PasswordIcon />
              <TextField
                id="userPasswd1"
                label={t("Password")}
                className="flex-auto"
                type="password"
                onChange={(e) => handleUserPasswd1(e)}
                value={userPasswd1}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ minLength: 8 }}
                variant="outlined"
                fullWidth
              />
              <PasswordIcon />
              <TextField
                id="userPasswd2"
                label={t("Confirm-Password")}
                className="flex-auto"
                type="password"
                onChange={(e) => handleUserPasswd2(e)}
                value={userPasswd2}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ minLength: 8 }}
                variant="outlined"
                fullWidth
              />
            </div>
            <div className="flex sm:space-x-24 pt-4 mb-4">
              <TextField
                id="Address"
                label={t("Address")}
                className="flex-auto"
                onChange={(e) => handleUserAddress(e)}
                value={userAddress}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
              />

              <TextField
                id="Email"
                type="email"
                label={t("Email")}
                className="flex-auto"
                onChange={(e) => handleUserEmail(e)}
                value={userEmail}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
              />
            </div>

            <div className="flex sm:space-x-24 pt-6 mb-6">
              <TextField
                id="Phone"
                label={t("Phone")}
                className="flex-auto"
                onChange={(e) => handleUserPhone(e)}
                value={userPhone}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
              />

              <TextField
                id="mobilePhone"
                label={t("Mobile-Phone")}
                className="flex-auto"
                onChange={(e) => handleUserMPhone(e)}
                value={userMPhone}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
              />

              {/* <FormControl sx={{ mt: -3 }}>
                <FormHelperText>Organization:</FormHelperText>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userOrganization}
                  label="Ticket Source"
                  onChange={handleUserOrganization}
                  disabled={orgDisabled}
                  sx={{
                    width: 280,
                  }}
                >
                  {orgData.map((row) => (
                    <MenuItem value={row.name}>{row.name}</MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </div>

            <div className="flex sm:space-x-24 mb-6">
              {/* <FormControl>
                <FormHelperText>Department:</FormHelperText>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userDepartment}
                  label="Department"
                  onChange={handleUserDepartment}
                  disabled={depDisabled}
                  sx={{
                    width: 280,
                  }}
                >
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="Support">Support</MenuItem>
                    <MenuItem value="Is Customer">Is Custumer</MenuItem>
                </Select>
              </FormControl> */}
              {/* <FormControl>
                <FormHelperText>Role:</FormHelperText>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userRole}
                  label="Role"
                  onChange={handleUserRole}
                  disabled={roleDisabled}
                  sx={{
                    width: 280,
                  }}
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Technician">Technician</MenuItem>
                  <MenuItem value="Customer">Custumer</MenuItem>
                </Select>
              </FormControl> */}

              {/* <FormControl>
                <FormHelperText>Status:</FormHelperText>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userStatus}
                  label="Ticket Source"
                  onChange={handleUserStatus}
                  sx={{
                    width: 280,
                  }}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl> */}
            </div>
            <div className="flex sm:space-x-24 mb-16">
              <Button
                variant="outlined"
                color="success"
                className=" w-1/2 mt-16"
                aria-label="Add"
                type="submit"
                fullWidth
              >
                {t("Edit-User")}
              </Button>
            </div>
          </div>
        </form>
      </Item>
    </Box>
  );
};

export default ProfileMain;
