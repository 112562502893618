import { Box, Typography, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";

let lat = "";
let long = "";
const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

const AnyReactComponent = ({ text }) => (
  <div
    style={{
      color: "white",
      background: "grey",
      padding: "15px 10px",
      display: "inline-flex",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      transform: "translate(-50%, -50%)",
    }}
  >
    {text}
  </div>
);


// const componentDidMount = () => {
//     if(navigator.geolocation){
//         navigator.geolocation.watchPosition(function(position){
//             lat = position.coords.latitude;
//             long = position.coords.longitude;
//         });
//     }
// }

const componentDidMount = () => {
  if (navigator.geolocation) {
    navigator.geolocation.watchPosition(function (position) {
      lat = position.coords.latitude;
      long = position.coords.longitude;
    });
  }
};

componentDidMount();

const TaskCheckIn = (props) => {
  const { t } = useTranslation();

  const defaultProps = {
    center: {
      lat: lat,
      lng: long,
    },
    zoom: 15,
  };


  const handleCheckIn = () => {
    props.onCheckIn(lat, long)
  }

  return (
    <Box class="mt-4">
      <Typography>{t("Show-Location-On-Map")}</Typography>
      <Typography>
        {t(
          'Tap the "Get My Location" button above and this map will automatically update with your new location.'
        )}
      </Typography>
      <div style={{ height: "50vh", width: "100%", paddingTop: "2vh" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent lat={lat} lng={long} text="My Current Position" />
        </GoogleMapReact>
      </div>
      <Button
        variant="contained"
        onClick={handleCheckIn}
        sx={{ mt: 1, mr: 1 }}
      >
        Check In
      </Button>
    </Box>
  );
};

export default TaskCheckIn;
