import { Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import classes from "../Dashboard.module.css";

const DashOpenTickets = ({ open }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const amount = 0;
  const user = JSON.parse(localStorage.getItem("user"));

  let labels = [];
  let data = [];
  let totalTickets = 0;


  open.forEach((register) => {
    labels.push(register._id);
    data.push(register.count);
    totalTickets += register.count;
  });

  const chartOptions = {
    chart: {
      animations: {
        speed: 400,
        animateGradually: {
          enabled: false,
        },
      },
      fontFamily: "inherit",
      foreColor: "inherit",
      height: "100%",
      width: "100%",
      type: "area",
      sparkline: {
        enabled: true,
      },
    },
    colors: [theme.palette.secondary.main],
    fill: {
      colors: [theme.palette.secondary.light],
      opacity: 0.5,
    },
    stroke: {
      curve: "smooth",
    },
    tooltip: {
      followCursor: true,
      theme: "dark",
    },
    xaxis: {
      type: "category",
      // categories: [],
      categories: labels,
    },
    grid: {
      show: true,
      borderColor: theme.palette.divider,
      padding: {
        top: 10,
        bottom: 15,
        left: 0,
        right: 0,
      },
      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    series: [
      {
        name: "# Tickets",
        // data: [0,0,0]
        data: data,
      },
    ],
  };

  return (
    <Paper
      className={classes.totalTicketsChart}
      sx={{
        width: {
          xs: "85%",
          sm: "85%",
          md: user.role === "Customer" ? "1/2" : "45%",
          lg: user.role === "Customer" ? "1/2" : "31%",
        },
      }}
    >
      <div>
        <Typography className={classes.totalTicketsTitle}>
          {t("open-tickets")}
        </Typography>
      </div>
      <div>
        <Typography className={classes.totalTicketsAmountDiv}>
          {/* {amount.toLocaleString('en-US')} */}
          {totalTickets}
        </Typography>
      </div>
      <div>
        <ReactApexChart
          options={chartOptions}
          series={chartOptions.series}
          type={chartOptions.chart.type}
          height={chartOptions.chart.height}
        />
      </div>
    </Paper>
  );
};

export default DashOpenTickets;
