import classes from "../../dashboard/Dashboard.module.css";
import { useTranslation } from "react-i18next";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  Typography,
  Toolbar,
  Slide,
  Stack,
  Fab,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState, useEffect, forwardRef } from "react";
import MafiTableReports from "../../../@mafi/MafiTableReports";
import MafiTableGroup from "../../../@mafi/MafiTableGroup";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useQuery, QueryClient, QueryClientProvider } from "react-query";
import { PDFViewer, Font } from "@react-pdf/renderer";
import DocumentGroup from "../../../main/reports/Downloads/PDF/DocumentGroup";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import FileDownload from "@mui/icons-material/FileDownload";
import {
  getTechnicianTickets,
  getCustomerTickets,
} from "../../../utils/fetchEvents";
import axios from "axios";
import { CSVLink } from "react-csv";


const queryClient = new QueryClient();

const API_URL = process.env.REACT_APP_API_URL;

Font.registerHyphenationCallback((word) => {
  // Return entire word as unique part
  return [word];
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ReportsMain = () => {
  const [startDate, setStartDate] = useState(dayjs(new Date()).subtract(7, "days"));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [ticketsList, setTicketsList] = useState([]);
  const [OpenTickets, setOpenTickets] = useState([]);
  const [closedTickets, setClosedTickets] = useState([]);
  const [open, setOpen] = useState(false);

  let { data, status, isLoading } = useQuery({
    queryKey: [
      "groupsTechnician",
      {
        startDate: startDate,
        endDate: endDate,
      },
    ],
    queryFn: ({ signal }) =>
      getTechnicianTickets({
        signal,
        startDate: startDate,
        endDate: endDate,
      }),
    initialData: [],
  });

  let { data: dataCustomer } = useQuery({
    queryKey: [
      "groupsCustomers",
      {
        startDate: startDate,
        endDate: endDate,
      },
    ],
    queryFn: ({ signal }) =>
      getCustomerTickets({
        signal,
        startDate: startDate,
        endDate: endDate,
      }),
    initialData: [],
  });

  let dataCsvCustomers = [];
  const headerCsvCustomers = [
    { label: "Customer", key: "customer" },
    { label: "Open Tickets", key: "open" },
    { label: "Closed Tickets", key: "closed" },
    { label: "Total", key: "total" },
  ];

  dataCustomer.forEach((customer) => {
    let open = 0;
    let closed = 0;
    customer.status.forEach((status) => {
      if (status.description === "Open") {
        open = status.count;
      }
      if (status.description === "Closed") {
        closed = status.count;
      }
    });
    dataCsvCustomers.push({
      customer: customer._id?.name,
      open: open,
      closed: closed,
      total: customer.totalCount,
    });
  });

  let dataCsvTechnicians = [];
  const headerCsvTechnicians = [
    { label: "Technician", key: "technician" },
    { label: "Open Tickets", key: "open" },
    { label: "Closed Tickets", key: "closed" },
    { label: "Total", key: "total" },
  ];

  data.forEach((technician) => {
    let open = 0;
    let closed = 0;
    technician.status.forEach((status) => {
      if (status.description === "Open") {
        open = status.count;
      }
      if (status.description === "Closed") {
        closed = status.count;
      }
    });
    dataCsvTechnicians.push({
      technician: technician._id.name,
      open: open,
      closed: closed,
      total: technician.totalCount,
    });
  });


  const customersHeaders = [
    {
      id: 1,
      name: "Customer",
      align: "",
    },
    {
      id: 2,
      name: "Open Tickets",
      align: "right",
    },
    {
      id: 3,
      name: "Closed Tickets",
      align: "right",
    },
    {
      id: 4,
      name: "Total",
      align: "right",
    },
  ];

  const technicianHeaders = [
    {
      id: 1,
      name: "Technician",
      align: "",
    },
    {
      id: 2,
      name: "Open Tickets",
      align: "right",
    },
    {
      id: 3,
      name: "Closed Tickets",
      align: "right",
    },
    {
      id: 4,
      name: "Total",
      align: "right",
    },
  ];

  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    let ticketsSave = [];
    const getTickets = async () => {
      const response = await axios.get(
        `${API_URL}tickets/user/${userData._id}`,
        {
          params: {
            role: userData.role,
          },
        }
      );

      if (response.data.tickets) {
        response.data.tickets.forEach((ticket) => {
          const {
            _id: id,
            number,
            status,
            department,
            creator,
            dueDate,
            closed,
            updated,
            created,
            pics,
            issue,
            title,
            description,
            staffId: { _id: staffId },
            staffId: { name: staffName },
            source,
            priority,
          } = ticket;

          const customerId = ticket.customerId ? ticket.customerId._id : "";
          const customerName = ticket.customerId ? ticket.customerId.name : "";

          ticketsSave.push({
            id,
            number,
            status,
            department,
            creator,
            customerId,
            customerName,
            dueDate,
            closed,
            updated,
            created,
            pics,
            issue,
            title,
            description,
            staffId,
            staffName,
            source,
            priority,
          });
        });
      }

      const tickesOpen = ticketsSave.filter((ticket) => {
        return ticket.status === "Open";
      });

      const tickesClosed = ticketsSave.filter((ticket) => {
        return ticket.status === "Closed";
      });

      setOpenTickets(tickesOpen);

      setClosedTickets(tickesClosed);

      setTicketsList(ticketsSave);
    };

    getTickets();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.statisticsBox}>
      <Box sx={{ width: "100%" }} className="mt20">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={t("General")}
              className="saltexColor"
              {...a11yProps(0)}
            />
            <Tab
              label={t("Status-Ticket-Group-By-Technician")}
              className="saltexColor"
              {...a11yProps(1)}
            />
            <Tab
              label={t("Status-Tickets-Group-By-Customer")}
              className="saltexColor"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>

        <QueryClientProvider client={queryClient}>
          <TabPanel value={value} index={0}>
            <MafiTableReports ticketData={OpenTickets} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div style={{ paddingBottom: "10px" }}>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ marginRight: "10px" }}
                      label={t("Start-date")}
                      value={startDate}
                      onChange={(startDate) => setStartDate(startDate)}
                      format="LL"
                    />

                    <DatePicker
                      label={t("End-date")}
                      value={endDate}
                      onChange={(endDate) => setEndDate(endDate)}
                      minDate={dayjs(startDate)}
                      format="LL"
                    />
                  </LocalizationProvider>
                </Box>
                <Box>
                  <Stack direction="row" spacing={2}>
                    <Fab
                      color="error"
                      title={t("download-pdf")}
                      onClick={handleOpen}
                    >
                      <PictureAsPdf />
                    </Fab>
                    <Fab color="success" title={t("download-csv")}>
                      <CSVLink
                        data={dataCsvTechnicians}
                        headers={headerCsvTechnicians}
                        filename={"tickets-status-technicians.csv"}
                      >
                        <FileDownload />
                      </CSVLink>
                    </Fab>
                  </Stack>
                </Box>
              </Stack>
              <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
              >
                <AppBar
                  sx={{ position: "relative", backgroundColor: "#0e4089" }}
                >
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography
                      sx={{ ml: 2, flex: 1 }}
                      variant="h6"
                      component="div"
                    >
                      Preview
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleClose}>
                      Close
                    </Button>
                  </Toolbar>
                </AppBar>

                <Box sx={{ width: "100%" }}>
                  <PDFViewer
                    showToolbar={true}
                    style={{
                      position: "absolute",
                      border: 0,
                      height: "100%",
                      width: "100%",
                    }}
                    height="600"
                  >
                    <DocumentGroup
                      rows={data}
                      startDate={startDate}
                      endDate={endDate}
                      tableHeader={technicianHeaders}
                      title="TICKETS GROUP BY STATUS - TECHNICIAN"
                    />
                  </PDFViewer>
                </Box>
              </Dialog>
            </div>
            <MafiTableGroup
              technicians={data}
              header={technicianHeaders}
              startDate={startDate}
              endDate={endDate}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div style={{ paddingBottom: "10px" }}>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ marginRight: "10px" }}
                      label="Start Date"
                      value={startDate}
                      onChange={(startDate) => setStartDate(startDate)}
                      format="LL"
                    />

                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(endDate) => setEndDate(endDate)}
                      minDate={dayjs(startDate)}
                      format="LL"
                    />
                  </LocalizationProvider>
                </Box>
                <Box>
                  <Stack direction="row" spacing={2}>
                    <Fab
                      color="error"
                      title={t("download-pdf")}
                      onClick={handleOpen}
                    >
                      <PictureAsPdf />
                    </Fab>
                    <Fab color="success" title={t("download-csv")}>
                      <CSVLink
                        data={dataCsvCustomers}
                        headers={headerCsvCustomers}
                        filename={"tickets-status-customers.csv"}
                      >
                        <FileDownload />
                      </CSVLink>
                    </Fab>
                  </Stack>
                </Box>
              </Stack>
              <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
              >
                <AppBar
                  sx={{ position: "relative", backgroundColor: "#0e4089" }}
                >
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography
                      sx={{ ml: 2, flex: 1 }}
                      variant="h6"
                      component="div"
                    >
                      Preview
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleClose}>
                      Close
                    </Button>
                  </Toolbar>
                </AppBar>

                <Box sx={{ width: "100%" }}>
                  <PDFViewer
                    showToolbar={true}
                    style={{
                      position: "absolute",
                      border: 0,
                      height: "100%",
                      width: "100%",
                    }}
                    height="600"
                  >
                    <DocumentGroup
                      rows={dataCustomer}
                      startDate={startDate}
                      endDate={endDate}
                      tableHeader={customersHeaders}
                      title="TICKETS GROUP BY STATUS - CUSTOMERS"
                    />
                  </PDFViewer>
                </Box>
              </Dialog>
            </div>
            <MafiTableGroup
              technicians={dataCustomer}
              header={customersHeaders}
              startDate={startDate}
              endDate={endDate}
            />
          </TabPanel>
        </QueryClientProvider>
      </Box>
    </div>
  );
};

export default ReportsMain;
