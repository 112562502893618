import { styled, useTheme } from "@mui/material/styles";
import { Notifications } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Divider,
  Drawer,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";

import React, { useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import PPNavList from "../ppNavigation/PPNavList";
import { useNavigate } from "react-router-dom";
import authService from "../services/auth.service";
import SearchScroll from "../search/Search";
import { t } from "i18next";

const drawerWidth = 240;

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const Search = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  padding: "0, 10",
  borderRadius: theme.shape.borderRadius,
  width: "40%",
}));

const Icons = styled("div")(({ theme }) => ({
  display: "none",
  gap: "20px",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const UserBox = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "10px",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const StyledAppBar = styled(AppBar)({
  backgroundColor: "#0e4089",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const HOST = process.env.REACT_APP_HOST;

const NavBar = ({ totalOpenTickets }) => {
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const [openD, setOpenD] = React.useState(false);
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));
  const userRole = user?.role;
  const appUserImageProfile = user?.profileImage;

  const handleDrawerOpen = () => {
    setOpenD(true);
  };

  const handleDrawerClose = () => {
    setOpenD(false);
  };

  const handleLogout = () => {
    authService.logout();
    navigate("/");
  };

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <Box>
      <StyledAppBar position="sticky">
        <StyledToolbar>
          <MenuIcon
            sx={{
              display: { xs: "block", sm: "block", md: "block", lg: "none" },
            }}
            onClick={handleDrawerOpen}
          />
          <Typography
            variant="h6"
            sx={{
              display: { xs: "none", sm: "none", md: "block", lg: "block" },
            }}
          >
            Saltex Group Partner Portal
          </Typography>
          {openDialog && <SearchScroll onCloseDialog={handleOpenDialog} />}
          {userRole !== "Customer" && (
            <Search onClick={handleOpenDialog}>
              <InputBase sx={{ ml: '10px' }} placeholder={t("Search")} />
            </Search>
          )}
          <Icons>
            <IconButton onClick={() => navigate("/Tasks")} color={totalOpenTickets?.length === 0 ? 'default' : 'inherit'}>
              <Badge badgeContent={totalOpenTickets} color="error">
                <Notifications></Notifications>
              </Badge>
            </IconButton>
            <Avatar
              sx={{ width: 30, height: 30 }}
              src={appUserImageProfile
                ? `${HOST}${appUserImageProfile}?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`
                : "https://images.pexels.com/photos/3862607/pexels-photo-3862607.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}
              onClick={(e) => setOpen(true)}
            />
          </Icons>
          <UserBox onClick={(e) => setOpen(true)}>
            <Avatar
              sx={{ width: 30, height: 30 }}
              src="https://images.pexels.com/photos/3862607/pexels-photo-3862607.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            />
            {/* <Typography variant="span">Marlon</Typography> */}
          </UserBox>
        </StyledToolbar>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          open={open}
          onClose={(e) => setOpen(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {/* <MenuItem
            onClick={() => {
              navigate("/profile");
            }}
          >
            Profile
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              navigate("/profile");
            }}
          >
            My account
          </MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </StyledAppBar>
      <Drawer
        width={drawerWidth}
        flexshrink={0}
        variant="persistent"
        anchor="left"
        open={openD}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <PPNavList />
      </Drawer>
    </Box>
  );
};

export default NavBar;
