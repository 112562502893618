import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { InputBase } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import AssignmentRounded from "@mui/icons-material/AssignmentRounded";
import PlaylistAddCheckRounded from "@mui/icons-material/PlaylistAddCheckRounded";
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import axios from "axios";
import { useTranslation } from "react-i18next";


const API_URL = process.env.REACT_APP_API_URL;

const Search = ({ onCloseDialog }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const [searchData, setSearchData] = useState([]);
  const [dataTasks, setDataTasks] = useState([]);
  const [dataUsers, setDataUsers] = useState([]);
  //   const [scroll, setScroll] = React.useState("paper");
  const navigate = useNavigate();

  //   const handleClickOpen = (scrollType) => () => {
  //     setOpen(true);
  //     setScroll(scrollType);
  //   };

  const handleClose = () => {
    setOpen(false);
    onCloseDialog();
  };

  const descriptionElementRef = React.useRef(null);

  const handleSearch = (e) => {
    // setSearchCriterion(e.target.value);
    const getData = async () => {
      setSearchData([]);
      setDataTasks([]);
      setDataUsers([]);
      const response = await axios.get(`${API_URL}reports/searchs`, {
        params: { criterion: e.target.value },
      });

      setSearchData(response.data.ticket);
      setDataTasks(response.data.tasks);
      setDataUsers(response.data.users);
    };
    if (e.target.value.length > 2) {
      getData();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          sx: {
            minHeight: "80vh",
            maxHeight: "80vh",
            width: "100%",
            maxWidth: "3/4",
          },
        }}
      >
        <DialogTitle id="scroll-dialog-title">{t("Search")}</DialogTitle>
        <InputBase
          placeholder={`${t("Search")}...`}
          onInput={handleSearch}
          style={{ marginLeft: "10px", marginRight: "10px" }}
        />
        <DialogContent dividers={"paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            
            {(searchData.length > 0 || dataTasks.length > 0 || dataUsers.length > 0) && (
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  color: "#000000",
                  maxHeight: 300,
                  "& ul": { padding: 0 },
                }}
              >
                <Typography>Tickets</Typography>
                {searchData.map((row) => {
                  return (
                    <>
                      <ListItem
                        alignItems="flex-start"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/ticketsDetails/?" + row.id);
                          onCloseDialog();
                        }}
                      >
                        <ListItemAvatar>
                          <AssignmentRounded style={{ color: "#0e4089" }} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={row.title}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.secondary"
                              >
                                {" "}
                                {row.number}
                              </Typography>
                              {` - ${row.description}`}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </>
                  );
                })}

                <Typography>Tasks</Typography>
                {dataTasks.map((row) => {
                  return (
                    <>
                      <ListItem
                        alignItems="flex-start"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (row.status !== "Closed") {
                            navigate("/tasksDetails/?" + row.id);
                            onCloseDialog();
                          } else {
                            navigate(`/closedTask/${row.id}`);
                            onCloseDialog();
                          }
                        }}
                      >
                        <ListItemAvatar>
                          <PlaylistAddCheckRounded
                            style={{ color: "#0e4089" }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={row.title}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.secondary"
                              >
                                {" "}
                                { }
                              </Typography>
                              {` - ${row.objetives}`}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </>
                  );
                })}

                <Typography>Users</Typography>
                {dataUsers.map((row) => {
                  return (
                    <>
                      <ListItem
                        alignItems="flex-start"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(`/user/edit`, {
                            state: {
                              id: row.id,
                              name: row.name,
                              department: row.department,
                              organization: row.organization,
                              address: row.address,
                              city: row.city,
                              country: row.country,
                              role: row.role,
                              email: row.email,
                              status: row.status,
                              phone: row.phone,
                              mobile: row.mobile,
                            },
                          });
                            onCloseDialog();
                        }}
                      >
                        <ListItemAvatar>
                          <AccessibilityIcon
                            style={{ color: "#0e4089" }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={row.name}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline", fontWeight: "bold" }}
                                component="span"
                                variant="body2"
                                color="text.secondary"
                              >
                                {" "}
                                {"Organization"}
                              </Typography>
                                {` - ${row.organization}`}
                              
                              <Typography
                                sx={{ display: "inline", fontWeight: "bold" }}
                                component="span"
                                variant="body2"
                                color="text.secondary"
                              >
                                {" "}
                                {"Email"}
                              </Typography>
                              {` - ${row.email}`}
                              <Typography
                                sx={{ display: "inline", fontWeight: "bold" }}
                                component="span"
                                variant="body2"
                                color="text.secondary"
                              >
                                {" "}
                                {"Mobile"}
                              </Typography>
                              {` - ${row.mobile}`}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </>
                  );
                })}
              </List>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Search;
