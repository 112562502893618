import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

const ReportsHeader = () => {
    const { t } = useTranslation();
    return(
        <Stack sx={{width:'99%'}} direction="row" spacing={2} justifyContent="space-between">
            <Box>
                <Typography class="text-slate-500 text-xl">{t('Reports')}</Typography>
                <Typography class="text-slate-500 text-sm" sx={{display:{xs:"none", sm:"none", md:"none", lg:"block"}}}>{t('subtitle-reports')}</Typography>
            </Box>

            <Box>
                <img width='200px' src="/assets/logos/logo_company_dark.png" alt="logo" />
            </Box>
            
        </Stack>
    );
}

export default ReportsHeader;