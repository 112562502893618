import { useState } from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import OrganizationAddForm from "./OrganizationAddForm";

const OrganizationNew = (props) => {
  const [newOrganizationPopUp, setNewOrganizationPopUp] = useState(null);
  const { t } = useTranslation();
  const hiddeNewOrganizationPopUp = () => {
    setNewOrganizationPopUp(addOrganizationButton);
  };

  const showAddOrganizationPopUp = () => {
    setNewOrganizationPopUp(
      <Box className="text-center">
        <OrganizationAddForm
          orgData={props.orgData}
          onCalcelSaveExp={hiddeNewOrganizationPopUp}
          addOganizationListHandler={addOganizationListHandler}
        />
      </Box>
    );
  };

  const addOganizationListHandler = (newOrganizationData) => {
    props.addOganizationListHandler(newOrganizationData);
  };

  const addOrganizationButton = (
    <Button
      variant="outlined"
      color="success"
      onClick={showAddOrganizationPopUp}
    >
      {t("add-new-organization")}
    </Button>
  );
  // const [newOrganizationPopUp, setNewOrganizationPopUp] = useState(addOrganizationButton);

  return (
    <Box>
      {!newOrganizationPopUp && <Box>{addOrganizationButton}</Box>}
      {newOrganizationPopUp && <Box>{newOrganizationPopUp}</Box>}
    </Box>
  );
};

export default OrganizationNew;
