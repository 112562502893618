
import { useState } from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from 'react-i18next';
import TicketsAddForm from "./TicketsAddForm";


const TicketsNew = (props) => {
    const { t } = useTranslation();
    const hiddeNewTicketPopUp = () => {
        setNewTicketPopUp(addTicketButton);
    }

    const showAddTicketPopUp = () => {
        setNewTicketPopUp(
            <Box className='text-center'>
                <TicketsAddForm groups={props.groups} displayCancel={'block'} onCalcelSaveExp={hiddeNewTicketPopUp} addUserListHandler={addUserListHandler} />
            </Box>
        );
    };

    const addUserListHandler = (newUserData) => {
        props.addUserListHandler(newUserData);
    }

    const addTicketButton = <Button variant="outlined" color="success" onClick={showAddTicketPopUp}>{t('New-Tickets')}</Button>;
    const [newTicketPopUp, setNewTicketPopUp] = useState(addTicketButton);

    return (
        <Box>
            {newTicketPopUp}
        </Box>
    );
}

export default TicketsNew;