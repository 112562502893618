/* eslint-disable no-extend-native */
import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import {
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MafiDragAndDrop from "../../../@mafi/MafiDragAndDrop";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const TicketReplays = ({ ticketId, ticketNumber, onCreateReply, onErrorReply }) => {
  const { t } = useTranslation();

  const [replyType, setReplyType] = React.useState("1");
  const [replyTitle, setReplyTitle] = React.useState("");
  const [replyContent, setReplyContent] = React.useState("");
  const userDataStorage = JSON.parse(localStorage.getItem("user"));


  const handleSubmitTicket = async (e) => {
    e.preventDefault();

    const userData = {
      title: replyTitle,
      content: replyContent,
      author: userDataStorage.name,
      ticketId: ticketId,
      ticketNumber: ticketNumber,
      replyType: replyType,
    };

    try {
      const response = await axios.post(`${API_URL}tickets/reply`, userData);

      onCreateReply();

    } catch (error) {
      console.log(error.response.data.message);
      onErrorReply(error.response.data.message)
    }
  };

  const handleReplyContent = (event) => {
    const replyContent = event.target.value;
    /// console.log(userNameText);
    setReplyContent(replyContent);
  };

  const handleReplyTitle = (event) => {
    const replyTitle = event.target.value;
    /// console.log(userNameText);
    setReplyTitle(replyTitle);
  };

  const handleReplyType = (event) => {
    setReplyType(event.target.value);
  };

  const handleFiles = (event) => { };

  return (
    <Box width={500} sx={{ m: 5 }}>
      <Item>
        <form onSubmit={handleSubmitTicket}>
          <Typography variant="h5" class="text-xl pb-4 text-blue-900 font-bold">
            {t("New-Reply")}
          </Typography>
          <div>
            <Stack
              spacing={4}
              direction="row"
              justifyContent="space-between"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <div className="flex sm:space-x-24">
                <FormControl>
                  <FormHelperText>Reply Type: *</FormHelperText>
                  <Select
                    width="max-content"
                    minWidth="100%"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={replyType}
                    label="Select an Agent"
                    onChange={handleReplyType}
                    required
                  >
                    <MenuItem value="1">Reply</MenuItem>
                    <MenuItem value="2">Internal Note</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Stack>

            <div className="flex sm:space-x-24 my-6">
              <TextField
                id="tTaskTitle"
                label={t("Reply-Title")}
                className="flex-auto"
                onChange={(e) => handleReplyTitle(e)}
                value={replyTitle}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
                fullWidth
              />
            </div>
            <div className="flex sm:space-x-24 my-6">
              <TextField
                id="outlined-multiline-static"
                label={t("Reply-Content")}
                value={replyContent}
                multiline
                rows={4}
                defaultValue=" "
                fullWidth
                required
                onChange={(e) => handleReplyContent(e)}
              />
            </div>
            {/* <div className="flex sm:space-x-24 my-6">
              <MafiDragAndDrop onDropdown={handleFiles} />
            </div> */}

            <div className="flex sm:space-x-24 mb-2 ">
              <Button
                variant="outlined"
                color="success"
                className=" w-1/2 mt-16"
                aria-label="Add"
                type="submit"
                size="large"
                fullWidth
              >
                {t("Add-Reply")}
              </Button>
            </div>
          </div>
        </form>
      </Item>
    </Box>
  );
};

export default TicketReplays;
