import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { lighten, useTheme } from "@mui/material/styles";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const DashTicketStatisticsCustomer = ({ totalOverdue, totalOpen, totalClosed }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const series = [totalOpen, totalOverdue, totalClosed];
  const chartOptions = {
    chart: {
      fontFamily: "inherit",
      foreColor: "inherit",
      height: "100%",
      type: "polarArea",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    labels: ["New Tickets", "Overdue", "Closed"],
    legend: {
      position: "bottom",
    },
    plotOptions: {
      polarArea: {
        spokes: {
          connectorColors: theme.palette.divider,
        },
        rings: {
          strokeColor: theme.palette.divider,
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.75,
        },
      },
    },
    stroke: {
      width: 2,
    },

    tooltip: {
      followCursor: true,
      theme: "dark",
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };

  return (
    <>
      <Paper className="flex flex-col flex-auto p-2 shadow rounded-2xl overflow-hidden">
        <div className="flex flex-col sm:flex-row items-start justify-between">
          <Typography className="text-lg font-medium tracking-tight leading-6 truncate">
            {t("Ticket-Distribution")}
          </Typography>
        </div>
        <div className="flex flex-col flex-auto mt-6">
          <ReactApexChart
            className="flex-auto w-full"
            options={chartOptions}
            series={series}
            type={chartOptions.chart.type}
          />
        </div>
      </Paper>

      <Paper className="flex flex-col flex-auto p-12 shadow rounded-2xl overflow-hidden h-full">


        <Box
          sx={{
            backgroundColor: (_theme) =>
              _theme.palette.mode === "light"
                ? lighten(theme.palette.background.default, 0.4)
                : lighten(theme.palette.background.default, 0.02),
          }}
          className="flex flex-row flex-auto mt-6"
        >
          <div className="flex flex-col items-center justify-center p-28 sm:p-32">
            <div className="text-3xl font-semibold leading-none tracking-tighter">
              {totalOpen}
            </div>
            <Typography className="mt-4 text-center text-secondary">
              {t('New-tasks')}
            </Typography>
          </div>

          <div className="flex flex-col items-center justify-center  sm:p-8">
            <div className="text-3xl font-semibold leading-none tracking-tighter">
              {totalOverdue}
            </div>
            <Typography className="mt-4 text-center text-secondary">
              {t('Overdue')}
            </Typography>
          </div>
        </Box>
      </Paper>

    </>
  );
};

export default DashTicketStatisticsCustomer;
