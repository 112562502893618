import styled from "@emotion/styled";
import { Alert, Box, Button, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next';
import PasswordIcon from '@mui/icons-material/Password';

let userId = window.location.search.replace("?", "").slice(0, -7).slice(5);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const API_URL = process.env.REACT_APP_API_URL
const APP_URL = 'http://saltexpartnerportal.vieratech.ai/';


const UserResetPassForm = (props) => {
  const [userPasswd1, setUserPasswd1] = React.useState('');
  const [userPasswd2, setUserPasswd2] = React.useState('');
  const [messagePasswd, setMessagePasswd] = React.useState('');
  const [resetButtonDisabled, setResetButtonDisabled] = React.useState(false);
  const { t } = useTranslation();

  const handleSubmitUser = async (e) => {
    e.preventDefault();
    setResetButtonDisabled(true);
    if (userPasswd1 !== userPasswd2) {
      setMessagePasswd(<Alert severity="error">{t('Passwords should be the same')}</Alert>);
      setTimeout(function () { setMessagePasswd(''); }, 5000);
      setUserPasswd1('');
      setUserPasswd2('');
      setResetButtonDisabled(false);
    } else {
      const userData = {
        passwd: userPasswd1,
      }

      try {
        const response = await fetch(API_URL + "users/resetpasswd/" + userId, {
          method: "PATCH",
          headers: {
            "Content-Type": "Application/json",
          },
          body: JSON.stringify(userData),
        });
        setMessagePasswd(<Alert severity="error">{t('Password has been changed. Redirecting...')}</Alert>);
        setTimeout(function () { setMessagePasswd(''); window.location = APP_URL }, 3000);
      } catch (err) {
        console.log(err);
      }
    }
  }


  const handleUserPasswd1 = (event) => {
    const userPasswd1 = event.target.value;
    // console.log(groupNameText);
    setUserPasswd1(userPasswd1);
  };

  const handleUserPasswd2 = (event) => {
    const userPasswd2 = event.target.value;
    // console.log(groupNameText);
    setUserPasswd2(userPasswd2);
  };


  return (
    <Box sx={{ width: '93%', m: 5, opacity: [0.8, 0.8, 0.8], borderRadius: '10px' }}>
      {messagePasswd}
      <Item>
        <form onSubmit={handleSubmitUser}>
          <Typography className="text-slate-700 text-xl">{t('Reset-Password')}</Typography>
          <div>
            <div className="flex sm:space-x-24 pt-4 mb-4 mt-4">
              <PasswordIcon />
              <TextField
                id="userPasswd1"
                label={t('Password')}
                className="flex-auto"
                type='password'
                onChange={(e) => handleUserPasswd1(e)}
                value={userPasswd1}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
                fullWidth
              />
              <PasswordIcon />
              <TextField
                id="userPasswd2"
                label={t('Confirm-Password')}
                className="flex-auto"
                type='password'
                onChange={(e) => handleUserPasswd2(e)}
                value={userPasswd2}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
                fullWidth
              />
            </div>
            <div className="flex sm:space-x-24 mt-8 mb-8">
              <Button
                variant="outlined"
                color="success"
                className=" w-1/2 mt-16"
                aria-label="Add"
                type="submit"
                sx={{ width: '100%' }}
                disabled={resetButtonDisabled}
              >
                {t('Reset-Password')}
              </Button>
            </div>
          </div>
        </form>
      </Item>
    </Box>
  );
};

export default UserResetPassForm;