import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  text: {
    display: "flex",
    alignSelf: "center",
    flexWrap: "wrap",
    textAlign: "justify",
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    lineHeight: 1.6,
  },
  textListItem: {
    // display: "flex",
    // flexWrap: "wrap",
    textAlign: "justify",
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    lineHeight: 1.5,
  },

  pageNumbers: {
    position: "absolute",
    bottom: 40,
    left: 0,
    right: 0,
    textAlign: "center",
  },

  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },

  table: {
    // display: "table",
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginLeft: 20,
    marginRight: 20,
  },

  tableCol: {
    width: "14.29%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableColMin: {
    width: "12%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    // borderTopWidth: 0,
  },

  tableColMax: {
    width: "16.58%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableColInt: {
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    // margin: "auto",
    marginTop: 5,
    fontSize: 12,
    textAlign: "center",
  },
});
const HeaderTicket = ({ header }) => (
  <View fixed>
    <View style={styles.tableRow}>
      {header.map((item, index) => {
        return (
          <View
            key={item.id}
            style={{
              width: item.width,
              borderStyle: "solid",
              borderWidth: 1,
              borderLeftWidth: 0,
              // borderTopWidth: 0,
            }}
          >
            <Text style={styles.tableCell}>{item.name}</Text>
          </View>
        );
      })}
    </View>
  </View>
);

export default HeaderTicket;
