import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import {
  Container,
  Typography,
  InputLabel,
  FormControl,
  Input,
  InputAdornment,
  Button,
  Grow,
  Box,
  Alert,
  Stack,
  Paper,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";

import PPMain from "./PPMain";
import styled from "@emotion/styled";

// import sendgrid from "@sendgrid/mail";

const API_URL = process.env.REACT_APP_API_URL;
// const SENDGRID_API_KEY =
//   "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";

// sendgrid.setApiKey(SENDGRID_API_KEY);

function makeId(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

const ForgotPasswd = () => {
  const { t } = useTranslation();
  const [forgotEmail, setForgotEmail] = useState("");
  const [checked, setChecked] = React.useState(true);
  const [addButtonDisabled, setaddButtonDisabled] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const passwdHandler = async (e) => {
    e.preventDefault();
    setaddButtonDisabled(true);

    try {
      //   const response = await fetch(
      //     API_URL + "users/getUserDataFromMail/" + forgotEmail,
      //     {
      //       method: "GET",
      //       headers: {
      //         "Content-Type": "Application/json",
      //       },
      //     }
      //   );

      const response = await axios.post(`${API_URL}users/forgotpassword`, {
        email: forgotEmail,
      });

      //   const responseData = await response.json();
      

      if (response.status != 200) {
        setMessage(<Alert severity="error">{t(`The email account does not exist. Please verify if the input email is right`)}</Alert>);
        setForgotEmail("");
        setTimeout(function () {
          setMessage("");
          setaddButtonDisabled(false);
        }, 5153);
      } else {
        setMessage(
          <Alert severity="info">
            {t(
              `A password reset message was sent to your email address. Please click the link in that message to reset your password.
              If you do not receive the password reset message within a few moments, please check your spam folder.`
            )}
          </Alert>
        );
        setTimeout(function () {
          setMessage("");
        }, 10000);
      }
    } catch (err) {
      setMessage(
        <Alert severity="error">
          {t("error-contact-support")}
        </Alert>
      );
      setTimeout(function () {
        setMessage("");
      }, 7000);
    }
  };

  const handleForgotEmail = (event) => {
    setForgotEmail(event.target.value);
  };

  const showLoginPasswd = () => {
    setNewForgotPopUp(false);
  };

  const SaltexLogo = styled("img")(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
    p: 20,
  }));

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const forgotPasswdDivContent = (
    <Box
      width={500}
      //   height={500}
      bgcolor="white"
      p={3}
      borderRadius={5}
      display={"row"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box>{message}</Box>
      <Box justifyContent={"center"} alignItems={"center"}>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          padding={2}
          className="mb-4"
        >
          <Item>
            <img src="/assets/logos/logo_company_dark.png" alt="logo" />
          </Item>
        </Stack>
        <p className="font-sans text-center" margin={2} left={20}>
          {t("signIn-Welcome")}
        </p>
        <p className="my10 font-sans text-center" margin={2} left={20}>
          {t("forgot-passwd-label")}
        </p>
        <form onSubmit={passwdHandler}>
          <FormControl sx={{ m: 2, width: "85%", left: 20 }} variant="standard">
            <InputLabel htmlFor="forgotEmail">Email</InputLabel>
            <Input
              id="forgotEmail"
              autoFocus
              type="email"
              // error={!!forgotErrors.forgotEmail}
              required
              value={forgotEmail}
              onChange={handleForgotEmail}
              startAdornment={
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl
            sx={{ width: "85%", left: 15, margin: 2 }}
            variant="standard"
          >
            <Button
              variant="outlined"
              className=" w-full mt-16"
              aria-label="Login"
              type="submit"
              size="large"
              disabled={addButtonDisabled}
            >
              {t("Reset-Password")}
            </Button>
          </FormControl>

          <FormControl
            sx={{ width: "85%", left: 15, margin: 2 }}
            variant="standard"
            className="pt20"
          >
            <Button
              variant="outlined"
              className=" w-full mt-16"
              aria-label="Login"
              type="submit"
              size="large"
              onClick={showLoginPasswd}
            >
              {t("back-to-login")}
            </Button>
          </FormControl>
        </form>
      </Box>
    </Box>
  );

  const [newForgotPopUp, setNewForgotPopUp] = useState(true);

  return (
    <div>
      <Grow
        in={checked}
        style={{ transformOrigin: "0 0 0" }}
        {...(checked ? { timeout: 1000 } : {})}
      >
        {newForgotPopUp ? (
          forgotPasswdDivContent
        ) : (
          <div>
            <PPMain />
          </div>
        )}
      </Grow>
    </div>
  );
};

export default ForgotPasswd;
