import { Box, Stack, ThemeProvider, createTheme } from "@mui/material";
import React, { useState } from "react";
import NavBar from "../main/navbar/Navbar";
import RightBar from "../main/rightbar/RightBar";
import AddTooltip from "../main/addTooltip/AddTooltip";
import PPNavigation from "../main/ppNavigation/PPNavigation";
import TaskDetails from "../main/tasks/TaskDetails";

const SaltexTaskDetails = ({ openTickets }) => {
  const [mode, setMode] = useState("light");
  const darkTheme = createTheme({
    palette: {
      mode: mode
    }
  });
  return (
    <ThemeProvider theme={darkTheme}>
      <Box class="bg-slate-200">
        <NavBar totalOpenTickets={openTickets} />
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <PPNavigation />
          <TaskDetails />
          <RightBar />
        </Stack>
        <AddTooltip />
      </Box>
    </ThemeProvider>
  );
}

export default SaltexTaskDetails;