import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  // marginBottom: 8,
  // marginRight: 8,
  // margin: 8,
  marginTop: 8,
  marginRight: 8,
  marginLeft: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function MafiDragAndDrop(props) {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*,pdf": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            visible: true,
          })
        )
      );
      acceptedFiles.map((file) =>
        props.onSetFiles.push(
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            visible: true,
          })
        )
      );
    },
  });
  const removeFile = (file) => () => {
    props.onSetFiles[props.onSetFiles.indexOf(file)].visible = false;
    props.onRemove([...props.onSetFiles], file);

  };

  const thumbs = props.onSetFiles.map((file) => {
    return (
      file.visible && (
        <>
          <div style={{ border: "1px solid #eaeaea", marginRight:3, marginLeft:3 }}>
            <div style={thumb} key={file.name}>
              <div style={thumbInner}>
                <img
                  src={file.preview}
                  style={img}
                  // Revoke data uri after image is loaded
                  onLoad={() => {
                    URL.revokeObjectURL(file.preview);
                  }}
                />
              </div>
            </div>
            {/* <button style={{ display: "block" }} onClick={removeFile(file)}>
              X
            </button> */}
            <IconButton style={{ display: "block", fontSize:12 }} onClick={removeFile(file)} aria-label="delete">
              <DeleteIcon /> Remove
            </IconButton>
          </div>
        </>
      )
    );
  });

  useEffect(() => {
    props.onDropdown(props.onSetFiles);
  }, [props.onSetFiles.length]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      props.onSetFiles.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p className="flex-1 flex flex-col items-center p-8 border-2 rounded-lg border-gray-300 border-dashed bg-gray-100 text-gray-500">
          {t("Drag and drop some images here, or click to select images")}
        </p>
      </div>
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
}

export default MafiDragAndDrop;
