import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import UsersHeader from "./UsersHeader";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PasswordIcon from "@mui/icons-material/Password";

const API_URL = process.env.REACT_APP_API_URL;

const UserEdit = ({ state }) => {
  const navigate = useNavigate();
  const [userStatus, setUserStatus] = useState(null);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userDepartment, setUserDepartment] = useState("");
  const [userOrganization, setUserOrganization] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userId, setUserId] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userPasswd1, setUserPasswd1] = useState("");
  const [userPasswd2, setUserPasswd2] = useState("");
  const [messagePasswd, setMessagePasswd] = useState("");
  const [alert, setAlert] = useState({
    message: "",
    severity: "",
    isActive: false,
  });
  const [organizations, setOrganizations] = useState([]);
  const { t } = useTranslation();

  // let organizations = JSON.parse(localStorage.getItem("organizations"));
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const setUser = () => {
      setUserId(state.id);
      setUserName(state.name);
      setUserEmail(state.email);
      setUserRole(state.role);
      setUserDepartment(state.department);
      setUserOrganization(state.organization);
      setUserStatus(state.status);
      setUserPhone(state.phone);
      setUserMobile(state.mobile);
      setUserAddress(state.address);
      setUserCity(state.city);
      setUserCountry(state.country);
    };

    setUser();
  }, [state]);

  useEffect(() => {
    const getOrganizations = async () => {
      const response = await axios.get(`${API_URL}organizations`);

      setOrganizations(response.data.organizations)
      // setOrgCount(response.data.organizations.length)
    };

    getOrganizations();
  }, []);

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleUserStatus = (e) => {
    setUserStatus(e.target.value);
  };

  const handleSubmit = async () => {
    if (userPasswd1 !== userPasswd2) {
      setMessagePasswd(
        <Alert severity="error">{t("Passwords should be the same")}</Alert>
      );
      setTimeout(function () {
        setMessagePasswd("");
      }, 5000);
      setUserPasswd1("");
      setUserPasswd2("");
    } else {
      const data = {
        id: userId,
        department: userDepartment,
        organization: userOrganization,
        role: userRole,
        name: userName,
        email: userEmail,
        phone: userPhone,
        mobile: userMobile,
        status: userStatus,
        address: userAddress,
        city: userCity,
        country: userCountry,
        passwd: userPasswd1,
        updatedBy: user._id,
      };
      try {
        const response = await axios.patch(`${API_URL}users/edit`, data);
  
        // delete response.data.user.passwd;
        // localStorage.setItem("user", JSON.stringify(response.data.user));
  
        setAlert({
          message: "User updated successfully",
          severity: "success",
          isActive: true,
        });
      } catch (error) {
        setAlert({
          message: error.response.data.message,
          severity: "error",
          isActive: true,
        });
      }
    }
  };

  const handleChangeName = (e) => {
    setUserName(e.target.value);
  };

  const handleChageEmail = (e) => {
    setUserEmail(e.target.value);
  };

  const handleChangeRole = (e) => {
    const role = e.target.value;
    if (role === "Customer") {
      setUserDepartment("Is Customer");
    } else if (role === "Admin") {
      setUserDepartment("Admin");
    } else {
      setUserDepartment("Support");
    }
    setUserRole(role);
  };

  const handleUserDepartment = (e) => {
    setUserDepartment(e.target.value);
  };

  const handleUserOrganization = (e) => {
    setUserOrganization(e.target.value);
  };

  const handleChangePhone = (e) => {
    setUserPhone(e.target.value);
  };

  const handleChangeMobile = (e) => {
    setUserMobile(e.target.value);
  };

  const handleUserAddress = (event) => {
    setUserAddress(event.target.value);
  };

  const handleUserCountry = (event) => {
    setUserCountry(event.target.value);
  };

  const handleUserCity = (event) => {
    setUserCity(event.target.value);
  };

  const handleUserPasswd1 = (event) => {
    const userPasswd1 = event.target.value;
    // console.log(groupNameText);
    setUserPasswd1(userPasswd1);
  };

  const handleUserPasswd2 = (event) => {
    const userPasswd2 = event.target.value;
    // console.log(groupNameText);
    setUserPasswd2(userPasswd2);
  };

  return (
    <Box
      paddingTop={2}
      sx={{
        flex: { sx: 4, sm: 4, md: 4, lg: 5 },
        backgroundColor: "white",
        p: { sx: 1, sm: 1, md: 1, lg: 2 },
      }}
    >
      <UsersHeader />
      {alert.isActive && (
        <Alert severity={alert.severity}>{t(alert.message)}</Alert>
      )}{messagePasswd}
      <Box className="bg-slate-50 p-2 mt-4 rounded-lg">
        <Typography
          variant="h5"
          className="py-2 text-blue-900"
          sx={{ fontSize: "1.25rem", fontWeight: "bold" }}
        >
          Edit User
        </Typography>
        <Paper
          sx={{
            p: 2,
            margin: "auto",
            display: "flex",
            flexWrap: "wrap",
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "#1A2027" : "#fff",
          }}
        >
          <Grid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
            <Grid item xs={6} paddingBottom={2}>
              <TextField
                fullWidth
                id="outlined-password-input"
                label="Name"
                type="text"
                value={userName}
                onChange={handleChangeName}
              />
            </Grid>
            <Grid item xs={6} paddingBottom={2}>
              <TextField
                fullWidth
                id="outlined-password-inpu"
                label="Email"
                type="email"
                value={userEmail}
                onChange={handleChageEmail}
              />
            </Grid>
            <Grid item xs={12} paddingBottom={2}>
            <div className="flex sm:space-x-24 pt-4 mb-4">
              <TextField
                id="Address"
                label={t("address")}
                className="flex-auto"
                onChange={(e) => handleUserAddress(e)}
                value={userAddress}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
              />
              <TextField
                id="City"
                label={t("City")}
                className="flex-auto"
                onChange={(e) => handleUserCity(e)}
                value={userCity}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
              />

              <TextField
                id="Country"
                label={t("Country")}
                className="flex-auto"
                onChange={(e) => handleUserCountry(e)}
                value={userCountry}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                required
              />
              </div>
            </Grid>
            <Grid item xs={6} paddingBottom={2}>
              <FormControl fullWidth>
                <FormHelperText>Role:</FormHelperText>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userRole}
                  label="Role"
                  onChange={handleChangeRole}
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Technician">Technician</MenuItem>
                  <MenuItem value="Customer">Custumer</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} paddingBottom={2}>
              <FormControl fullWidth>
                <FormHelperText>Department:</FormHelperText>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userDepartment}
                  label="Department"
                  onChange={handleUserDepartment}
                  // disabled={depDisabled}
                  disabled
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Support">Support</MenuItem>
                  <MenuItem value="Is Customer">Is Custumer</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} paddingBottom={2}>
              <PasswordIcon />
              <TextField
                id="userPasswd1"
                label={t("Password")}
                className="flex-auto"
                type="password"
                onChange={(e) => handleUserPasswd1(e)}
                value={userPasswd1}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                inputProps={{ minLength: 8 }}
                required
                fullWidth
              />
              <PasswordIcon />
              <TextField
                id="userPasswd2"
                label={t("Confirm-Password")}
                className="flex-auto"
                type="password"
                onChange={(e) => handleUserPasswd2(e)}
                value={userPasswd2}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                inputProps={{ minLength: 8 }}
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={6} paddingBottom={2}>
              <FormControl fullWidth>
                <FormHelperText>Organization:</FormHelperText>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userOrganization}
                  label="Ticket Source"
                  onChange={handleUserOrganization}
                >
                  {organizations.map((row, index) => (
                    <MenuItem key={index} value={row.name}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} paddingBottom={2}>
              <FormControl fullWidth>
                {/* <FormLabel id="active">Status</FormLabel> */}
                <FormHelperText>Status</FormHelperText>
                <RadioGroup
                  row
                  aria-labelledby="controlled-radio-buttons-status"
                  name="controlled-radio-buttons-group"
                  value={userStatus}
                  onChange={handleUserStatus}
                >
                  <FormControlLabel
                    value="Active"
                    control={<Radio />}
                    label="Active"
                  />
                  <FormControlLabel
                    value="Inactive"
                    control={<Radio />}
                    label="Inactive"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-password-input"
                label="Phone"
                type="text"
                value={userPhone}
                onChange={handleChangePhone}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-password-input"
                label="Mobile"
                type="text"
                value={userMobile}
                onChange={handleChangeMobile}
              />
            </Grid>
          </Grid>
        </Paper>
        <Box
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          paddingBottom={2}
          paddingTop={2}
        >
          <Button
            variant="outlined"
            color="warning"
            aria-label="Back"
            onClick={handleBackButton}
            style={{ width: "200px" }}
          >
            {t('Back')}
          </Button>
          <Button
            variant="outlined"
            color="success"
            style={{ width: "200px" }}
            onClick={handleSubmit}
          >
            {t("Edit")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UserEdit;
