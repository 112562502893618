import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { lighten, useTheme } from "@mui/material/styles";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";


const DashTicketStatistics = ({ totalOverdue, totalOpen, totalClosed }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const series = [totalOpen, totalOverdue, totalClosed];
  const chartOptions = {
    chart: {
      fontFamily: "inherit",
      foreColor: "inherit",
      height: "100%",
      type: "polarArea",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    labels: [t('New-Tickets'), t("Overdue"), t("Closed")],
    legend: {
      position: "bottom",
    },
    plotOptions: {
      polarArea: {
        spokes: {
          connectorColors: theme.palette.divider,
        },
        rings: {
          strokeColor: theme.palette.divider,
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.75,
        },
      },
    },
    stroke: {
      width: 2,
    },

    tooltip: {
      followCursor: true,
      theme: "dark",
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };

  return (
    <Paper className="flex flex-col flex-auto pt-2 pl-2 shadow rounded-2xl overflow-hidden h-full">
      <div className="flex flex-col sm:flex-row items-start justify-between">
        {/* <Typography className="text-lg font-medium tracking-tight leading-6 truncate">
          Ticket Distribution
        </Typography> */}
        <p className="text-xl font-medium tracking-tight leading-6 truncate" >{t("Ticket-Distribution")}</p>
      </div>
      <div className="flex flex-col flex-auto mt-6">
        <ReactApexChart
          className="flex-auto w-full"
          options={chartOptions}
          series={series}
          type={chartOptions.chart.type}
        />
      </div>

      <Box
        sx={{
          backgroundColor: (_theme) =>
            _theme.palette.mode === "light"
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.02),
        }}
        className="grid grid-cols-2 border-t divide-x mt-5 h-full"
      // className="grid grid-cols-2 border-t divide-x -m-24 mt-16"
      >
        <div className="flex flex-col items-center justify-center p-5">
          <div className="text-lg font-semibold leading-none tracking-tighter">
            {totalOpen}
          </div>
          <div className="text-center text-secondary">
            <Typography>
              {t("New-Tickets")}
            </Typography>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center">
          <div className="text-lg font-semibold leading-none tracking-tighter">
            {totalOverdue}
          </div>
          <div className="text-center text-secondary">
            <Typography>
              {t("Overdue")}
            </Typography>
          </div>
        </div>
      </Box>
    </Paper>
  );
};

export default DashTicketStatistics;
