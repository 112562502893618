import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as React from "react";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  Alert,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  FormControl,
  Stack,
  Paper,
} from "@mui/material";
import { AccountCircle, Visibility, VisibilityOff } from "@mui/icons-material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styled from "@emotion/styled";
import bgImage from "../../images/support_system_bg_mafi_technologies.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const StyledBox = styled("div")({
  height: "100vh",
  display: "flex",
  alignItems: "center",
  backgroundImage: `url(${bgImage})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  justifyContent: "center",
});

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const RecoveryPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [message, setMessage] = React.useState("");
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  // console.log(searchParams.get("token"));
  // console.log(searchParams.get("email"));

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log({
    //   confirmPassword: data.get("confirmPassword"),
    //   password: data.get("password"),
    //   token: searchParams.get("token"),
    //   id: searchParams.get("email"),
    // });

    try {
      const response = await axios.post(`${API_URL}users/recovery-password`, {
        password: data.get("password"),
        confirmedPassword: data.get("confirmPassword"),
        token: searchParams.get("token"),
        userId: searchParams.get("email"),
      });

      if (!response.data.user) {
        setMessage(
          <Alert severity="info">
            {t(
              `The link recovery was already used`
            )}
          </Alert>
        );
        setTimeout(function () {
          setMessage("");
          navigate("/")
        }, 7000);
        return
      }

      setMessage(
        <Alert severity="success">
          {t(
            `Password updated`
          )}
        </Alert>
      );
      setTimeout(function () {
        setMessage("");
        navigate("/")
      }, 4000);
    } catch (error) {
      setMessage(
        <Alert severity="error">{t(error.response.data.message)}</Alert>
      );
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm((showPasswordConfirm) => !showPasswordConfirm);
  };

  return (
    <StyledBox>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            padding={2}
          ></Stack>
          <Box
            width={500}
            bgcolor="white"
            p={3}
            borderRadius={5}
            display={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {message}
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar> */}
              <Item>
                <img src="/assets/logos/logo_company_dark.png" alt="logo" />
              </Item>
              <p
                className="text-center m-2 text-xl tracking-tight leading-tight"
                margin={2}
                left={20}
              >
                {t("change-password")}
              </p>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                {/* <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                /> */}
                <FormControl
                  sx={{ m: 1, width: "100%", right: 10 }}
                  variant="standard"
                >
                  <InputLabel htmlFor="password">{t("password")}</InputLabel>
                  <Input
                    id="password"
                    type={showPassword ? "text" : "password"}
                    // error={!!AuthErrors.password}
                    required
                    name="password"
                    // onChange={handlePassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {/* <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="confirmPassword2"
                  label="Confirm Password"
                  name="confirmPassword2"
                  type="password"
                  autoFocus
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                /> */}
                <FormControl
                  sx={{ m: 1, width: "100%", right: 10 }}
                  variant="standard"
                >
                  <InputLabel htmlFor="confirmPassword">
                    {t("Confirm-Password")}
                  </InputLabel>
                  <Input
                    id="confirmPassword"
                    type={showPasswordConfirm ? "text" : "password"}
                    // error={!!AuthErrors.password}
                    required
                    name="confirmPassword"
                    // onChange={handlePassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordConfirm}
                        >
                          {showPasswordConfirm ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Button
                  variant="outlined"
                  className=" w-full mt-16"
                  aria-label="Login"
                  type="submit"
                  size="large"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {t("Reset-Password")}
                </Button>
                <Grid container></Grid>
              </Box>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </StyledBox>
  );
};

export default RecoveryPassword;
