import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  BlobProvider,
} from "@react-pdf/renderer";
import ItemsTicket from "../Tables/Items/ItemsTicket";
import HeaderTicket from "../Tables/Header/HeaderTicket";
import { Box, Link, Typography } from "@mui/material";
const HOST = process.env.REACT_APP_HOST;
const APP_FRONTEND_HOST = process.env.REACT_APP_FRONTEND_HOST;

const DocumentTicket = ({ tasks, infoTicket }) => {
  const styles = StyleSheet.create({
    text: {
      flexWrap: "wrap",
      textAlign: "justify",
      marginLeft: 12,
      marginRight: 12,
      marginVertical: 5,
      //   lineHeight: 1.5,
      //   margin: 12,
      fontSize: 14,
      fontFamily: "Times-Roman",
    },
    textRight: {
      flexWrap: "wrap",
      marginLeft: 12,
      marginRight: 12,
      marginVertical: 5,
      //   lineHeight: 1.5,
      //   margin: 12,
      fontSize: 14,
      fontFamily: "Times-Roman",
    },
    textRightW: {
      flexWrap: "wrap",
      marginLeft: 12,
      marginRight: 12,
      marginVertical: 5,
      //   lineHeight: 1.5,
      //   margin: 12,
      fontSize: 10,
      fontFamily: "Times-Roman",
    },
    block: {
      marginBottom: 10,
    },
    textListItem: {
      textAlign: "justify",
      marginTop: 10,
      marginLeft: 12,
      marginRight: 12,
      lineHeight: 1.5,
    },

    pageNumbers: {
      position: "absolute",
      bottom: 40,
      left: 0,
      right: 0,
      textAlign: "center",
      fontSize: 12,
      color: "grey",
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderTopWidth: 0,
      marginLeft: 10,
      marginRight: 10,
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableCol: {
      width: "25%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColInt: {
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCell: {
      margin: "auto",
      marginTop: 5,
      fontSize: 10,
    },
    tableCellNested: {
      margin: "auto",
      fontSize: 10,
      width: "80%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCellNestedIndex: {
      margin: "auto",
      fontSize: 10,
      width: "20%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    image: {
      //   marginVertical: 10,
      //   marginHorizontal: 50,
      width: "30%",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
      //   fontFamily: "Oswald",
    },
  });

  const tableHeader = [
    { id: 1, name: "Visit Day", width: "13%" },
    { id: 2, name: "Check In", width: "13%" },
    { id: 3, name: "Check Out", width: "13%" },
    { id: 4, name: "Latitude", width: "13%" },
    { id: 5, name: "Longitude", width: "13%" },
    { id: 6, name: "Observation", width: "35%" },
  ];

  return (
    <Document>
      <Page
        style={{
          //   fontSize: 12,
          paddingTop: 35,
          paddingBottom: 65,
          paddingHorizontal: 35,
        }}
        wrap
        // orientation="landscape"
        // size="LEGAL"
      >
        <View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <Image
                style={styles.image}
                src="/assets/logos/logo_company_dark.png"
              />
            </View>
            <View style={{ flex: 1, textAlign: "right" }}>
              <Text style={{ fontSize: 14 }}>
                Date: {new Date().toISOString().split("T")[0]}
              </Text>
              {/* <Text style={{ fontSize: 14 }}>
                Created: {infoTicket.created.split("T")[0]}
              </Text> */}
            </View>
          </View>
        </View>
        <View
          wrap
          // style={{ flexDirection: "row", justifyContent: "space-between" }}
        >
          <Text
            style={{
              fontFamily: "Helvetica-Bold",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            TICKET # {infoTicket.number}
          </Text>
        </View>
        <View style={{ border: 1, borderRadius: 10 }}>
          <Text style={styles.text}>
            Created: {infoTicket.created.split("T")[0]}
          </Text>
          <Text style={styles.text}>Title: {infoTicket.title}</Text>
          <Text style={styles.text}>Technician: {infoTicket.technician}</Text>
          <Text style={styles.text}>Customer: {infoTicket.customer}</Text>
          <Text style={styles.text}>Status: {infoTicket.status}</Text>
        </View>
        <View>
          {tasks.map((task) => (
            <View style={{ marginBottom: "10px" }}>
              <Text style={styles.subtitle}>Task: {task.title}</Text>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.text}>Technician: {task.staffName}</Text>
                  <Text style={styles.text}>Department: {task.department}</Text>
                  <Text style={styles.text}>Status: {task.status}</Text>
                </View>
                <View style={{ flex: 1, textAlign: "right" }}>
                  <Text style={styles.textRight}>
                    Created: {task.created.split("T")[0]}
                  </Text>
                  <Text style={styles.textRight}>
                    Closed: {task.closed ? task.closed.split("T")[0] : ""}
                  </Text>
                </View>
              </View>
              {task.report.length > 0 && (
                <View wrap style={styles.table}>
                  <HeaderTicket header={tableHeader} />

                  {task.report.map((report) => {
                    const {
                      location,
                      createdAt,
                      observation,
                      checkIn,
                      checkOut,
                      sign,
                      images,
                      _id: id,
                    } = report;
                    let imgArr = [];
                    let pdfArr = [];

                    {images.map((file)=>{
                      let extFile = file.slice(-3);
                      if(extFile == "pdf"){
                        pdfArr.push({"url" : file, "name": file.slice(7)});
                      } else {
                        imgArr.push(file);
                      }
                    })}

                    return (
                      <>
                      <ItemsTicket
                        items={[
                          { value: createdAt.split("T")[0], width: "13%" },
                          // {
                          //   value: createdAt.split("T")[1].split(".")[0],
                          //   width: "15%",
                          // },
                          {
                            value: checkIn,
                            width: "13%",
                          },
                          {
                            value: checkOut,
                            width: "13%",
                          },
                          { value: location.lat, width: "13%" },
                          { value: location.long, width: "13%" },
                          { value: observation, width: "35%" },
                        ]}
                        key={id}
                      />
                      {imgArr.length > 0 && (
                        <>
                          <Text style={styles.textRight}>
                            Images: 
                          </Text>
                          {imgArr.map((img)=>{
                              return <Image
                                      style={styles.image}
                                      src={img}
                                    />
                          })}
                        </>
                      )}
                      {pdfArr.length > 0 && (
                        <>
                          <Text style={styles.textRight}>
                            PDF files: 
                          </Text>
                          {pdfArr.map((pdfF)=>{
                            let tempPdfUrl = encodeURI(APP_FRONTEND_HOST+pdfF.url);
                            return <>
                                    <Text style={styles.textRightW}>
                                      {tempPdfUrl}
                                    </Text>
                                  </>
                          })}
                        </>
                      )}
                       <Text style={styles.textRight}>
                        Signature: 
                      </Text>
                      <Image
                        style={styles.image}
                        src={{ uri: sign, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                      />
                      </>
                    );
                  })}
                </View>
              )}
            </View>
          ))}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default DocumentTicket;
