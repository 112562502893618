import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import PPNavList from "./PPNavList";

const HOST = process.env.REACT_APP_HOST;

const PPNavigation = () => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));
  const appUserName = user.name;
  const appUserOrg = user.organization;
  const appUserImageProfile = user?.profileImage;
  return (
    <Box
      flex={1}
      p={2}
      sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}
      className="h-full bg-slate-200"
    >
      <Avatar
        sx={{ width: 100, height: 100, margin: "auto" }}
        src={
          appUserImageProfile
            ? `${HOST}${appUserImageProfile}?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`
            : "https://images.pexels.com/photos/3862607/pexels-photo-3862607.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        }
      />
      <Box className="mb-4 mt-4">
        <h6 className="text-center text-slate-500 text-xl">{appUserName}</h6>
        <Typography className="text-center text-slate-500">
          {appUserOrg}
        </Typography>
      </Box>
      <PPNavList />
    </Box>
  );
};

export default PPNavigation;
