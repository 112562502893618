import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { useTranslation } from 'react-i18next';


const UsersHeaderResetPasswd = () => {
    const { t } = useTranslation();
    return (
        <Stack sx={{ width: '99%' }} direction="row" spacing={2} justifyContent="space-between">
            <Box>
                <Typography class="text-slate-700 text-xl">{t('WelcomeMsg')}</Typography>
                <Typography class="text-slate-700 text-xl mt-2">{t('Reset-Password')}</Typography>
                <Typography class="text-slate-700 text-md mt-2" sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>{t('subtitle-reset-passwd')}</Typography>
                <Typography class="text-slate-700 text-md mt-2" sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>{t('user-help')} <a href="http://www.saltexgroup.com">Saltex Group</a></Typography>
            </Box>

            <Box>
                <img width='200px' src="/assets/logos/logo_company_dark.png" alt="logo" />
            </Box>

        </Stack>
    );
}

export default UsersHeaderResetPasswd;