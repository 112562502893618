import { Box, Button, FormControl, FormHelperText, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import SignatureCanvas from "react-signature-canvas";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { eventWrapper } from "@testing-library/user-event/dist/utils";

const TaskCheckOut = ({ onExecuteBack, onCheckOut }) => {
  const { t } = useTranslation();
  const [sign, setSigh] = useState(null);
  const [signTech, setSighTech] = useState(null);
  const [checkOutButton, setCheckOutButton] = useState(true);
  const sigRef = useRef();
  const sigRefTech = useRef();
  const [taskType, setTaskType] = useState('no');
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');

  const handleSignatureEnd = () => {
    setSigh(sigRef.current.toDataURL());
    if(signTech){
      setCheckOutButton(false);
    }
  }

  const handleSignatureEndTech = () => {
    setSighTech(sigRefTech.current.toDataURL());
    if(sign){
      setCheckOutButton(false);
    }
  }

  const handleClearSignature = () => {
    sigRef.current.clear();
    setSigh(null);
    setCheckOutButton(true);
  };

  const handleClearSignatureTech = () => {
    sigRefTech.current.clear();
    setSighTech(null);
    setCheckOutButton(true);
  };

  const handleCheckOut = () => {
    onCheckOut(sign, signTech, taskType, checkIn, checkOut)
  };

  const handleTaskType = (event) =>{
    setTaskType(event.target.value);
  }

  const handleCheckInTime = (event) => {
    setCheckIn(event.$d);
  }

  const handleCheckOutTime = (event) => {
    setCheckOut(event.$d);
  }

  return (
    <>
      <Stack direction={"column"} className="mt-4" spacing={6}>
        <Stack direction={"row"} spacing={2}>
          <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']}>
              <TimePicker 
                label="Check In" 
                onChange={handleCheckInTime}  
              />
            </DemoContainer>
          </LocalizationProvider>  
          </Box>
          <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']}>
              <TimePicker 
                label="Check Out"
                onChange={handleCheckOutTime} 
              />
            </DemoContainer>
          </LocalizationProvider>  
          </Box>
          <Box className="pl-6 pt-6">
            <Typography sx={{fontWeight: 'bold', color: 'red'}}>Task Type:</Typography>
          </Box>
          <Box className="pt-2">
            <FormControl>
            <Select 
              value={taskType}
              onChange = {handleTaskType}
            >
              <MenuItem key={0} value={'no'}>Select..</MenuItem>
              <MenuItem key={1} value={'onsite'}>On Site</MenuItem>
              <MenuItem key={2} value={'Remote'}>Remote</MenuItem>              
            </Select>
            </FormControl>
          </Box>
        </Stack>
        <Box>
          <Typography sx={{fontWeight: 'bold'}}>Technician Signature</Typography>
          <SignatureCanvas
            penColor="green"
            canvasProps={{
              width: 500,
              height: 300,
              className: "rounded-lg bg-blue-200 mb-4",
            }}
            // ref={(data) => setSigh(data)}
            ref={sigRefTech}
            onEnd={handleSignatureEndTech}
          />
        <Button
          variant="outlined"
          color="success"
          className=" w-1/2 mt-16"
          aria-label="Add"
          type="submit"
          size="large"
          onClick={handleClearSignatureTech}
        >
          {t("Clear-Signature")}
        </Button>
        </Box>
        <Box>
          <Typography sx={{fontWeight: 'bold'}}>Customer Signature</Typography>
          <SignatureCanvas
            penColor="green"
            canvasProps={{
              width: 500,
              height: 300,
              className: "rounded-lg bg-gray-200 mb-4",
            }}
            // ref={(data) => setSigh(data)}
            ref={sigRef}
            onEnd={handleSignatureEnd}
          />
        <Button
          variant="outlined"
          color="success"
          className=" w-1/2 mt-20"
          aria-label="Add"
          type="submit"
          size="large"
          onClick={handleClearSignature}
        >
          {t("Clear-Signature")}
        </Button>
        </Box>
      </Stack>
      <div className="mt-4">
        <Button
          variant="contained"
          onClick={handleCheckOut}
          sx={{ mt: 1, mr: 1 }}
          disabled={checkOutButton}
        >
          Checkout
        </Button>
        <Button onClick={onExecuteBack} sx={{ mt: 1, mr: 1 }}>
          Back
        </Button>
      </div>
    </>
  );
};

export default TaskCheckOut;
