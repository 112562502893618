import { useState } from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import UserAddFormNew from "./UserAddFormNew";
import UserAddForm from "./UserAddForm";

const UserNew = (props) => {
  const { t } = useTranslation();
  const [isFormActive, setIsForActive] = useState(false);

  let user = null;

  if (localStorage.getItem("user") !== null) {
    user = JSON.parse(localStorage.getItem("user"));
  }

  const handleCancel = () => {
    setIsForActive(!isFormActive);
  };

  return (
    <Box>
      {!user && <UserAddFormNew />}
      {isFormActive && user && <UserAddForm onCalcelSaveExp={handleCancel} />}

      {!isFormActive && user && (
        <Button variant="outlined" color="success" onClick={handleCancel}>
          {t("New-User")}
        </Button>
      )}
    </Box>
  );
};

export default UserNew;
