import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import UsersHeader from "./UsersHeader";
import UserList from "./widgets/UserList";
import UserNew from "./widgets/UserNew";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
let dataUsers = [];
let usersRows = [];
let usersCountG = 0;

const Users = () => {
  const [userData, setUserData] = useState([]);
  const [orgCount, setOrgCount] = useState(0);

  useEffect(() => {
    const getUsers = async () => {
      const response = await axios.get(`${API_URL}users`);
      if (response.data.users) {
        setUserData(response.data.users);
        setOrgCount(response.data.users.length);
      }
    };

    getUsers();
  }, [orgCount]);

  const addUsersListHandler = (user) => {
    setUserData((prevUser) => {
      let contTemp = prevUser.length + 1;
      setOrgCount(contTemp);
      return [user, ...prevUser];
    });
  };

  return (
    <Box
      paddingTop={2}
      sx={{
        flex: { sx: 4, sm: 4, md: 4, lg: 5 },
        backgroundColor: "white",
        p: { sx: 1, sm: 1, md: 1, lg: 2 },
      }}
    >
      <UsersHeader />
        <UserNew
          addUsersListHandler={addUsersListHandler}
          userData={userData}
        />
      <UserList filteredUsers={userData} usersCount={userData.length} />
    </Box>
  );
};

export default Users;
