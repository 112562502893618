import styled from "@emotion/styled";
import React, { useState } from "react";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Grow,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import bgImage from "../../images/support_system_bg_mafi_technologies.jpg";
import classes from "./PPMain.module.css";
import ForgotPasswd from "./ForgotPasswd";
import { AccountCircle, Visibility, VisibilityOff } from "@mui/icons-material";
import authService from "../services/auth.service";
import { NavLink, useNavigate } from "react-router-dom/dist";
import { useTranslation } from "react-i18next";

const HOST = process.env.REACT_APP_HOST;
const FRONTEND_HOST = process.env.REACT_APP_FRONTEND_HOST

const StyledBox = styled("div")({
  height: "100vh",
  display: "flex",
  alignItems: "center",
  backgroundImage: `url(${bgImage})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  justifyContent: "center",
});

const SaltexLogo = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    width: "50%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "40%",
  },
  p: 20,
}));

const languages = [
  { value: "", text: "Options" },
  { value: "en", text: "English" },
  { value: "es", text: "Español" },
];

const SSMain = () => {
  authService.logout();
  const [newLoginPopUp, setNewLoginPopUp] = useState(true);
  const [checked, setChecked] = React.useState(true);
  const [message, setMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [lang, setLang] = useState("");
  const [authEmail, setAuthEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const languageHandler = (e) => {
    setLang(e.target.value);
    let loc = "http://saltexpartnerportal.vieratech.ai/";
    window.location.replace(loc + "?lng=" + e.target.value);
  };

  const authHandler = async (event) => {
    event.preventDefault();
    setOpen(true);
    // console.log(authEmail + ' p: ' + password);
    // navigate('/dashboard');
    try {
      await authService.login(authEmail, password).then(
        (resAuth) => {
          if (resAuth.validated) {
            if (resAuth.status != "Active") {
              setOpen(false);
              setMessage(
                <Alert severity="error">{t("user-inactive-error")}</Alert>
              );
              setTimeout(function () {
                setMessage("");
              }, 7000);
            } else if (resAuth.organization == "") {
              setOpen(false);
              setMessage(
                <Alert severity="error">{t("user-noorg-error")}</Alert>
              );
              setTimeout(function () {
                setMessage("");
              }, 7000);
            } else {
              // populateLocalService.readSalientCameras().then(()=>{
              setOpen(false);
              navigate("/dashboard");
              // })
            }
          } else {
            setOpen(false);
            setMessage(
              <Alert severity="error">{t("user-passwd-error")}</Alert>
            );
            setTimeout(function () {
              setMessage("");
            }, 5000);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleAuthEmail = (event) => {
    setAuthEmail(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    // console.log(showPassword);
    setShowPassword((showPassword) => !showPassword);
  };

  const showForgotPasswd = () => {
    setNewLoginPopUp(false);
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const loginDivContent = (
    <Box
      width={500}
      height={600}
      bgcolor="white"
      p={3}
      borderRadius={5}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          padding={2}
        >
          <Item elevation={0} >
            <img src="/assets/logos/logo_company_dark.png" alt="logo" />
          </Item>
        </Stack>

        <p
          className="text-center m-2 text-xl tracking-tight leading-tight"
          margin={2}
          left={20}
        >
          {t("signIn-Welcome")}
        </p>
        {message}
        <form onSubmit={authHandler} className={classes.signInForm}>
          <FormControl sx={{ m: 1, width: "85%", left: 20 }} variant="standard">
            <InputLabel htmlFor="authEmail">Email</InputLabel>
            <Input
              id="authEmail"
              autoFocus
              type="email"
              // error={!!AuthErrors.authEmail}
              required
              value={authEmail}
              onChange={handleAuthEmail}
              startAdornment={
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: "85%", left: 20 }} variant="standard">
            <InputLabel htmlFor="passwordLogin">{t("password")}</InputLabel>
            <Input
              id="passwordLogin"
              type={showPassword ? "text" : "password"}
              // error={!!AuthErrors.password}
              required
              onChange={handlePassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{ minLength: 8 }}
            />
          </FormControl>

          <FormControl
            sx={{ width: "85%", left: 20, margin: 2 }}
            variant="standard"
          >
            <Button
              variant="outlined"
              className=" w-full mt-16"
              aria-label="Login"
              type="submit"
              size="large"
            >
              {t("login")}
            </Button>
          </FormControl>
        </form>
        <Grid container spacing={2} className={classes.signInFooter}>
          <Grid item xs={8}>
            <FormControl
              sx={{ m: 1, width: "70%", left: 27 }}
              variant="standard"
            >
              <Button
                variant="outlined"
                className="h50"
                aria-label="Login"
                type="submit"
                size="large"
                onClick={showForgotPasswd}
              >
                {t("forgot-password")}
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl
              sx={{ m: -1, width: "100%", left: -30 }}
              variant="standard"
            >
              <InputLabel id="demo-simple-select-label">
                {t("language")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="font-size-12 h50"
                value={lang}
                label="language"
                onChange={languageHandler}
              >
                {languages.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box className="m-4" sx={{ justifyContent: "center" }}>
          <p className="font-sans text-center">
            {t("no-accout-msg")}{" "}
            <NavLink
              className=" font-sans text-xl text-blue-700 font-bold"
              to={FRONTEND_HOST + "NewUser"}
            >
              {t("here")}
            </NavLink>
          </p>
        </Box>
      </Box>
    </Box>
  );

  return (
    <StyledBox>
      <Grow
        in={checked}
        style={{ transformOrigin: "0 0 0" }}
        {...(checked ? { timeout: 1000 } : {})}
      >
        {newLoginPopUp ? loginDivContent : <ForgotPasswd />}
      </Grow>
    </StyledBox>
  );
};

export default SSMain;
