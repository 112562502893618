import { Text, View, StyleSheet} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  table: {
    display: "table",
    // display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginLeft: 20,
    marginRight: 20,
  },

  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },

  tableCol: {
    width: "14.29%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableColMin: {
    width: "12%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableColMax: {
    width: "16.58%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableCell: {
    // margin: "auto",
    textAlign: "right",
    // marginLeft: 5,
    marginHorizontal: 5,
    marginTop: 5,
    fontSize: 10,
  },
});

const Items = ({ items, tableCol  }) => {
  return (
    // <View wrap={false} style={styles.table}>
    <View wrap={false} style={styles.tableRow}>
      {items.map((item, index) => {
        return (
          <View
            key={index}
            style={
              tableCol
                ? tableCol
                : index === 0
                ? styles.tableColMin
                : index === 1
                ? styles.tableColMax
                : styles.tableCol
            }
          >
            <Text style={styles.tableCell}>{item}</Text>
          </View>
        );
      })}
    </View>
    // </View>
  );
};

export default Items;
