import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useQuery } from "react-query";
import { getUserByRole } from "../../../utils/fetchEvents";

const ReportFilterTask = ({ onFilter, onFilterTechnician }) => {
  const [personName, setPersonName] = React.useState([]);
  const [customers, setCustomers] = React.useState("");
  const [technician, setTechnician] = React.useState("");

  const { data: dataCustomer } = useQuery({
    queryKey: ["customers", { role: "Customer" }],
    queryFn: ({ signal }) => getUserByRole({ signal, role: "Customer" }),
    initialData: [],
  });

  const { data: dataTechnician } = useQuery({
    queryKey: ["technician", { role: "Technician" }],
    queryFn: ({ signal }) => getUserByRole({ signal, role: "Technician" }),
    initialData: [],
  });

  const handleChange = (event) => {
    setCustomers(event.target.value);
    onFilter(event.target.value);
  };

  const handleChangeTechnician = (event) => {
    setTechnician(event.target.value);
    onFilterTechnician(event.target.value);
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
        display={"flex"}
        flexDirection="row"
        px={1}
        mx={1}
        justifyContent={"flex-end"}
      >
        <Box px={1} mx={1}>
          {/* <FormControl style={{ minWidth: 120 }}>
          <InputLabel id="demo-simple-select-label">Customer</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={customers}
            label="Customer"
            onChange={handleChange}
          >
            {dataCustomer.map((customer) => {
              return (
                <MenuItem key={customer._id} value={customer._id}>
                  {customer.name}
                </MenuItem>
              );
            })}
           
          </Select>
        </FormControl> */}
        </Box>
        <FormControl style={{ minWidth: 120 }}>
          <InputLabel id="demo-simple-select-label">Technician</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={technician}
            label="Customer"
            onChange={handleChangeTechnician}
          >
            <MenuItem key={1} value="">
              All
            </MenuItem>
            {dataTechnician.map((technician) => {
              return (
                <MenuItem key={technician._id} value={technician._id}>
                  {technician.name}
                </MenuItem>
              );
            })}
            {/* <MenuItem value={10}>Ten</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem> */}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};
export default ReportFilterTask;
