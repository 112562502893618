const API_URL = process.env.REACT_APP_API_URL


const signup = (department, role, name, passwd, email, phone, mobile, status, timezone) => {
    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'api/users/signup',{
            method: "Post",
            Headers: {
                'Content-Type': 'Application/json'
            },
            body: JSON.stringify({
                department, role, name, passwd, email, phone, mobile, status, timezone
            })

        })
        .then((response)=>{
            if(!response.ok){
                throw new Error (resolve.message);
            }
            resolve(response.json());
        });
    });
}

const login = async (email, passwd) => {
    try{
        const response = await fetch(API_URL + 'users/login',{
            method: "Post",
            headers: {
                'Content-Type': 'Application/json'
            },
            body: JSON.stringify({
                email, passwd
            })
        });
    
        const responseData = await response.json();
        delete responseData.existingUser.passwd;
        localStorage.setItem("user", JSON.stringify(responseData.existingUser));
        responseData.existingUser.validated = true;
        return(responseData.existingUser);
    } catch (err) {
        console.log('Something went wrong, please try again.');
        return(false);
      }

};

const logout = () => {
    localStorage.removeItem("user");
}

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const authService = {
    signup,
    login,
    logout,
    getCurrentUser,
};

export default authService;