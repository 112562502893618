import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import Header from "../Tables/Header/Header";
import Items from "../Tables/Items/Items";

const Documents = ({ rows, startDate, endDate }) => {
  rows = rows ? rows : [];
  const styles = StyleSheet.create({
    text: {
      flexWrap: "wrap",
      textAlign: "justify",
      marginLeft: 12,
      marginRight: 12,
      lineHeight: 1.5,
      // fontFamily: "Times-Roman",
    },
    block: {
      marginBottom: 10,
    },
    textListItem: {
      textAlign: "justify",
      marginTop: 10,
      marginLeft: 12,
      marginRight: 12,
      lineHeight: 1.5,
    },

    pageNumbers: {
      position: "absolute",
      bottom: 40,
      left: 0,
      right: 0,
      textAlign: "center",
      fontSize: 12,
      color: "grey",
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderTopWidth: 0,
      marginLeft: 10,
      marginRight: 10,
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableCol: {
      width: "25%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColInt: {
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCell: {
      margin: "auto",
      marginTop: 5,
      fontSize: 10,
    },
    tableCellNested: {
      margin: "auto",
      fontSize: 10,
      width: "80%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCellNestedIndex: {
      margin: "auto",
      fontSize: 10,
      width: "20%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    image: {
    //   marginVertical: 10,
    //   marginHorizontal: 50,
      width: "30%",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  const tableHeaderAgreement = [
    {
      id: 1,
      name: "Ticket",
    },
    {
      id: 2,
      name: "Title",
    },
    {
      id: 3,
      name: "Created",
    },
    {
      id: 4,
      name: "Due Date",
    },
    {
      id: 5,
      name: "From",
    },
    {
      id: 6,
      name: "Assigned To",
    },
    {
      id: 7,
      name: "Status",
    },
  ];

  return (
    <Document>
      <Page
        style={{
          //   fontSize: 12,
          paddingTop: 35,
          paddingBottom: 65,
          paddingHorizontal: 35,
        }}
        wrap
        orientation="landscape"
        size="LEGAL"
      >
        <View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <Image
                style={styles.image}
                src="/assets/logos/logo_company_dark.png"
              />
            </View>
            <View style={{ flex: 1, textAlign: "right" }}>
              <Text style={{fontSize: 14}}>Date: {new Date().toISOString().split("T")[0]}</Text>
              <Text style={{fontSize: 14}}>
                Report from: {startDate.toISOString().split("T")[0]} to:{" "}
                {endDate.toISOString().split("T")[0]}
              </Text>
            </View>
          </View>
          {/* <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={{ flex: 1, textAlign: "right" }}>Left</Text>
            <Text style={{ flex: 1 }}>Right</Text>
          </View> */}
        </View>
        <View
          wrap
          // style={{ flexDirection: "row", justifyContent: "space-between" }}
        >
          <Text
            style={{
              fontFamily: "Helvetica-Bold",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            TICKETS REPORT
          </Text>
        </View>
        <View wrap style={styles.table}>
          <Header header={tableHeaderAgreement} />
          {rows.map((row) => {
            const {
              id,
              number,
              title,
              created,
              dueDate,
              // customerId: { name: customer },
              // staffId: { name: technician },
              status,
            } = row;

            return (
              <Items
                items={[
                  number,
                  title,
                  created.split("T")[0],
                  dueDate.split("T")[0],
                  row.customerId?.name,
                  row.staffId?.name,
                  status,
                ]}
                key={id}
              />
            );
          })}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default Documents;
